import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {
  CommonModule,
  DOCUMENT,
  Location,
  isPlatformBrowser
} from "@angular/common";
import {
  ActivatedRoute,
  Router,
  RouterModule
} from "@angular/router";
import {
  ApiService
} from '../core/services/api.service';
import {
  AuthService
} from '../core/services/auth.service';
import {
  AppComponent
} from '../app.component';
import {
  Services
} from "../core/models/services";
import { environment } from '../../environments/environment';
import { FabButtonComponent } from '../shared/fab-button/fab-button.component';
import { SideMarginComponent } from '../shared/side-margin/side-margin.component';
import { FsiCalculatorComponent } from '../shared/fsi-calculator/fsi-calculator.component';
import { ServiceRequestComponent } from '../shared/service-request/service-request.component';
import { Modal, initModals } from 'flowbite';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

 
import { SharedModule } from '../shared/shared.module';
@Component({
  selector: 'app-project',
  standalone: true,
  imports: [CommonModule, ProjectComponent, SharedModule, SideMarginComponent, RouterModule, FsiCalculatorComponent, ServiceRequestComponent,FormsModule],
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  newProposalsCount: number = 0;
  isHireOpen = false;
  createConv = false;
  isCalModalOpen = false;
  isCalOpen = false;
  isSideCalModalOpen = false;
  isSideCalOpen = false;

  printLoader = false;

  showRatings: boolean = false;
  showProposals=false;
  showRequirements: boolean = false;

  projectTitle: any = "";
  projectId: any = "";
  calculatorId: any = "";
  selectedItem: any = [];
  services: any = [];
  calculators: any = [];
  sidecalculators: any = [];
  proposals: any = [];
  hiredData: any = [];
  blogs: any = [];
  questions: any = [];

  user:any=[];
  userCode: any = "";
  baseUrl = environment.baseURL;
  selectedId: any = "about";
  badges:any = [];
  vendorProfile:any=[];

  targetCalculatorDeleteConfimationModal:any
  calculatorDeleteConfimationModal:any

  ratingModal:any;
  targetratingModal:any;

  profileModal:any;
  targetprofileModal:any;

 popupModal:any;
 targetpopupModal:any;

  baseURL = environment.baseURL;

  selectedCalculator:any = "";
  selectedPortfolio:any = "";

  rating:any =0;
  ratingHover:any = 0;
  comment="";
  hasRated=false;
  customerRating:any;
  readMore = false;
  defaultImage = 'https://www.placecage.com/1000/1000';


  constructor(private _location: Location, private route: ActivatedRoute,private elementRef: ElementRef, private router: Router, public api: ApiService, public auth: AuthService, public appC: AppComponent,@Inject(DOCUMENT) public document:Document, @Inject(PLATFORM_ID) private platformId: Object, public toastr:ToastrService,
  public titleS:Title) {
   }

   
  ngOnInit(): void {

    this.route.params.subscribe((data: any) => {
      this.projectId = data.projectId;
      this.getData();
      this.userCode = data.userCode;
      this._fetchData();
      this.services.forEach((service:any) => {
        this.api.getVendorServicesCount(service._id).subscribe((cdata:any)=>{
          service.expertCount=cdata.data.totalCount;
        })
      })
      this.route.queryParams.subscribe(async (res:any) => {
        if(Object.keys(res).length != 0){
          if(res.calculator == 'FSI'){
            let fsiData = await this.api.getFsi({_id:res.id},1,1,"").toPromise();
            if(fsiData.data.length > 0){
              this.isCalOpen = true;
              this.selectedItem = fsiData.data[0];
              this.selectedCalculator = "FSI";
            }
          }else{
            let sideData = await this.api.getSideMargin({_id:res.id},1,1,"").toPromise();
            if(sideData.data.length > 0){
              this.isCalOpen = true;
              this.selectedItem = sideData.data[0];
              this.selectedCalculator = "Side Margin";
            }
          }
        }
       
      })
    })

    if (isPlatformBrowser(this.platformId)) {
        const messagingElement = document.querySelector('.messaging');
        if (messagingElement) {
          messagingElement.classList.remove('hidden');
        }
      initModals();
      this.targetCalculatorDeleteConfimationModal = this.document.getElementById('calculator-delete-confimation-modal');
      this.calculatorDeleteConfimationModal = new Modal(this.targetCalculatorDeleteConfimationModal,{closable:false});
  
      this.targetratingModal =  document.getElementById('rating-Modal');
      this.ratingModal = new Modal(this.targetratingModal);
      this.targetprofileModal =  document.getElementById('profile-modal');
      this.profileModal = new Modal(this.targetprofileModal);
      this.targetpopupModal =  document.getElementById('popup-modal');
      this.popupModal = new Modal(this.targetpopupModal);
    }

   
  }

  getData() {
    this.api.getProject({ _id: this.projectId }, 1, 1, "").subscribe(data => {
      if (data.data.length > 0) {
        this.projectTitle = data.data[0].title;
        this.titleS.setTitle(this.projectTitle+ " - " + this.appC.title)
        data.data[0].serviceRequests.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        this.services = data.data[0].serviceRequests;
        
        this.route.queryParams.subscribe(async (sData:any) => {
          if(sData.service){
            let findData = await this.services.find((res:any) => {return res._id == sData.service});
            this.openHire(findData);
          }
        })
      }
    })

    this.api.getBlog({}, 1, 5, "").subscribe(data => {
      this.blogs = data.data;
    })

    this.api.getQuestion({}, 1, 5, "", {}).subscribe(data => {
      this.questions = data.data;
    })

    this.api.getFsi({
      project: this.projectId
    }, 1, 20, "").subscribe(data => {
      this.calculators = data.data;
    })

    this.api.getSideMargin({
      project: this.projectId
    }, 1, 20, "").subscribe(data => {
      this.sidecalculators = data.data;
    })
  }

  _fetchData(){
    this.api.getUser({userCode:parseInt(this.userCode)},1,1,"").subscribe(user => {
      this.user = user.data[0];
      
    })
  }

  scroll(id:any){
    this.selectedId = id;
    const element = this.elementRef.nativeElement.querySelector('#'+id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
  closeHireModal(){
    this.isHireOpen = false;
  }

  stopHireModal(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }


  stopCalOpenModal(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }


  stopSideCalOpenModal(event: MouseEvent) {
    event.stopPropagation(); // Prevents the click event from propagating to the backdrop div
  }

  stopCalModalOpenModal(event: MouseEvent){
    event.stopPropagation();
  }
  

  updateNewProposalsCount() {
    // Logic to count new proposals, for example:
    this.newProposalsCount += 1;
  }
  

  submitRating(){
    const data= JSON.stringify({
      "rating": this.rating,
      "comment":this.comment,
      "serviceId":this.selectedItem.serviceId,
      "partnerId":this.selectedItem.hireProposalPartner[0]._id,
      "userId":this.auth.currentUserValue.id,
    })
    this.api.createRating(data).subscribe((cdata:any)=>{
      
      this.closeratingModal();
      this.toastr.success("Rating Submitted")
      this.clear();

    })
    
  }

  getRating(){
    this.api.getServiceRequest({ }, 1, 25, "").subscribe((cdata:any)=>{
      
      this.customerRating=this.hiredData.hireProposals[0]?.hiredpartner?.customerRating;
      
    })
  }


  readmore(event: MouseEvent, index: number) {
    const target = event.target as HTMLElement;
    const textContainer = document.querySelector(`.textContainer${index}`) as HTMLElement;
  
    if (textContainer.classList.contains('truncate-two-lines')) {
      textContainer.classList.remove('truncate-two-lines');
      target.textContent = 'Read less';
    } else {
      textContainer.classList.add('truncate-two-lines');
      target.textContent = 'Read more';
    }
  }
  
  clear(){
    this.rating="";
    this.comment="";
    this.ratingHover="";
  }

  toggleRatings() {
    this.showRatings = !this.showRatings;
  }

  toggleRequirements() {
    this.showRequirements = !this.showRequirements;
  }

  toggleProposals(){
    this.showProposals= !this.showProposals;
  }
  
  getIcon(serviceId: any) {
    let s = Services.filter((res: any) => res.id == serviceId);
    return s[0]?.smallIcon;
  }

  openHire(item: any) {
    this.isHireOpen = true;
    this.selectedItem = item;
    this.api.updateServiceRequest(JSON.stringify({data:{newProposal: false}}),item._id).subscribe(data => {
      if(data){
        item.newProposal = false;
      }
    })
    this.api.getServiceRequest({
      _id: item._id
    }, 1, 25, "").subscribe(data => {
      if (data.data.length > 0) {
        this.hiredData = data.data[0];
        this.proposals = data.data[0].proposals;
        
        if(!this.hiredData?.isHired){
          this.showProposals = true;
        }
        
        if(this.hiredData?.hireProposals.length > 0){
          this.api.getRating({userId: this.auth.currentUserValue.id, partnerId: this.hiredData?.hireProposals[0].partner, serviceId: this.hiredData.serviceId},1,1,"").subscribe((cdata:any)=>{
            if(cdata.data.length > 0){
              this.hasRated = true;
            }
            else{
              this.hasRated=false;
            }
            })
        }
        }
        

    })

   
  }

  openBlog(item: any) {
    var convertedStr = item.title.replace(/\s/g, "-");

    this.router.navigate(['/blog/' + convertedStr]);
  }

  openQuestion(title: any) {
    var convertedStr = title.replace(/\s/g, "-");

    this.router.navigate(['/questions/' + convertedStr]);
  }

  openCalculator(item: any) {
    this.isCalOpen = true;
    this.selectedItem = item;
    this.selectedCalculator = "FSI";
  }

  openSideCalculator(item: any) {
    this.isSideCalOpen = true;
    this.selectedItem = item;
    this.selectedCalculator = "Side Margin";
  }

  openCalculatorModal() {
    this.isCalOpen = !this.isCalOpen;
    this.isCalModalOpen = !this.isCalModalOpen;
    this.calculatorId = this.selectedItem._id;
  }

  openSideCalculatorModal() {
    this.isSideCalOpen = !this.isSideCalOpen;
    this.isSideCalModalOpen = !this.isSideCalModalOpen;
    this.calculatorId = this.selectedItem._id;
  }

  createConversation(userId: any, service: any) {
    this.createConv = true;
    const data = JSON.stringify({
      "members": [userId, this.auth.currentUserValue.id],
      "serviceRequest": service._id,
      "group": false
    });
    this.api.createConversation(data).subscribe(res => {
      if (res.status) {
        this.api.getUser({
          _id: userId
        }, 1, 1, "").subscribe(data => {
          if (data.data.length > 0) {
            if (!res.exists) {
              this.appC.chatData.push({
                conversationId: res.data._id,
                name: data.data[0].name,
                mobile: data.data[0].mobile,
                status: data.data[0].presence,
                userId: data.data[0]._id,
                photo: data.data[0].photo,
                newMessage: false,
                lastMessageBy: undefined,
                service: service.displayServiceText,
                serviceStatus: service.status
              });
            }
            this.appC.chatUsername(data.data[0].name, data.data[0].photo, data.data[0].presence, res.data._id, data.data[0]._id, (this.appC.chatData.length - 1), service.displayServiceText, service.status);

          }
        })
        this.createConv = false;
      }
    });
  }

  hireProposal(item: any) {
    
    let proposalData = JSON.stringify({
      data: {
        hiredOn: new Date(),
        isHired: true,
        status: "Active"
      }
      
    });
   

    let serviceRequestData = JSON.stringify({
      data: {
        hireProposal: item._id,
        isHired: true,
        status: "In Progress"
      }
    });

    this.api.updateProposal(proposalData, item._id).subscribe(res => {
      this.api.updateServiceRequest(serviceRequestData, this.selectedItem._id).subscribe(res => {

        this.getData();
        this.isHireOpen = false

      }, error => {

      });
    }, error => {

    });
    this.closeprofileModal();

  }

  markCompleted(item: any) {
    let proposalData = JSON.stringify({
      data: {
        status: "Completed",
        isCompleted: true
      }

    });

    let serviceRequestData = JSON.stringify({
      data: {
        hireProposal: item._id,
        isHired: true,
        status: "Completed"
      }
    });

    this.api.updateProposal(proposalData, item._id).subscribe(res => {
      this.api.updateServiceRequest(serviceRequestData, this.selectedItem._id).subscribe(res => {
        this.getData();
        this.isHireOpen = false

      }, error => {

      });
    }, error => {

    });
  }

  back() {
    this._location.back();
  }

  download() {
    this.printLoader = true;
    this.api.downloadFSI(this.selectedItem._id).subscribe(data => {
      
      const link = document.createElement('a');
      link.href = this.baseURL + '/file/retrieve-pdf/' + data.data;
      link.target = '_blank';
      link.download = 'FSI Calculator.pdf'; // Set the desired filename
      link.click();
      this.printLoader = false;
    })
  }

  sideDownload() {
    this.printLoader = true;
    this.api.downloadSideMargin(this.selectedItem._id).subscribe(data => {
      
      const link = document.createElement('a');
      link.href = this.baseURL + '/file/retrieve-pdf/' + data.data;
      link.target = '_blank';
      link.download = 'Side Margin Calculator.pdf'; // Set the desired filename
      link.click();
      this.printLoader = false;
    })
  }

  openCalculatorDeleteConfirmation(){
    this.calculatorDeleteConfimationModal.show();
  }

  closeCalculatorDeleteConfirmation(){
    this.calculatorDeleteConfimationModal.hide();
  }

  deleteCalculator(){
    if(this.selectedCalculator == 'FSI'){
      this.api.deleteFsi(this.selectedItem._id).subscribe(data => {
        if(data){
          this.closeCalculatorDeleteConfirmation();
          this.getData();
          this.selectedCalculator = "";
        }
      },error => {
        this.toastr.error(error.message);
      })
    }else if(this.selectedCalculator == 'Side Margin'){
      this.api.DeleteSideMargin(this.selectedItem._id).subscribe(data => {
        if(data){
          this.closeCalculatorDeleteConfirmation();
          this.getData();
          this.selectedCalculator = "";
        }
      },error => {
        this.toastr.error(error.message);
      })
    }
  }

  openratingModal(){
    this.ratingModal.show();
  }

  closeratingModal(){
    this.ratingModal.hide();
  }

  openprofileModal(ele:any){
    this.vendorProfile= ele;

    this.profileModal.show();
  }

  closeprofileModal(){
    this.profileModal.hide();
  }

  openpopupModal(item:any){
    this.selectedPortfolio=item;
    this.popupModal.show();
  }

  closepopupModal(){
    this.popupModal.hide();
  }

}
