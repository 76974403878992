import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFilter',
  standalone: true
})
export class DateFilterPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {

    let curDate = new Date().getDay()+''+new Date().getMonth()+''+new Date().getFullYear();
    let yestDate = new Date().getDay()-1 +''+new Date().getMonth()+''+new Date().getFullYear();
    let filtDate = new Date(value).getDay()+''+new Date(value).getMonth()+''+new Date(value).getFullYear();

    if(curDate == filtDate){
      let time = new Date(value).getHours()+":"+("0"+new Date(value).getMinutes()).slice(-2);
      return time;
    } else if(yestDate == filtDate) {
      let time = new Date(value).getHours()+":"+("0"+new Date(value).getMinutes()).slice(-2);
      return "Yesterday " + time
    } else {
      return ("0"+new Date(value).getDay()).slice(-2) +' '+new Date(value).toLocaleString('default', { month: 'short' })+' '+new Date(value).getFullYear() + ' '+ ("0"+new Date(value).getHours()).slice(-2)+":"+("0"+new Date(value).getMinutes()).slice(-2);
    }

  }

}
