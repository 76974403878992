import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { RouterOutlet, RouterModule } from '@angular/router';
// import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DateFilterPipe } from './core/pipes/date-filter.pipe';
import { ServiceRequestComponent } from './shared/service-request/service-request.component';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceRequestComponent,
    FormsModule,
    RouterModule,
    // PickerModule, 
    DateFilterPipe,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
    }),
    NgIdleKeepaliveModule,
    ImageCropperModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LazyLoadImageModule,
    SharedModule,
    SocialLoginModule
  ],
  providers: [
    provideClientHydration(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('447565914846-09rc1pf2mr0bek8dvkfvkj1vg5g2fr91.apps.googleusercontent.com'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
