import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'time',
  standalone: true
})
export class TimePipe implements PipeTransform {

  constructor(private datePipe: DatePipe){

  }
  
  transform(value: any) {
    if(value == "" || value == undefined){
      return "-"
    }
    
    const date = new Date();
    const [hours, minutes, seconds] = value.split(':');
    date.setHours(Number(hours), Number(minutes), Number(seconds));
    
    return this.datePipe.transform(date, 'h:mm a');
  }

}
