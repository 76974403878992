<div class="bg-[#F5F5F5]">

  <section id="servicePost" #scrollContainer [@scrollAnimation]
    class="relative overflow-hidden h-[90vh] bg-gradient-to-b from-[#6B8FFA] to-[#ADC1FC]">
    <app-service-request [directSendProposal]="directSendProposal" [vendorId]="vendorId" [service]="service"
      [isModal]="false"></app-service-request>
  </section>


  <section class="container">
    <div class="mx-4 my-10 grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="col-span-2">
        @if(vendorData.length > 0){
          <div class="bg-white px-5 py-5 mb-2">
            <h3 class="text-lg font-bold tracking-wider">Top Recommended Partners</h3>
            <p class="text-sm font-light">as on {{todayDate | date:'MMMM dd, yyyy'}}</p>
          </div>
        }
        
        @for(item of vendorData;track item._id){
          <div class="bg-white px-5 py-5 border-b">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div class="col-span-2">
                <div class="flex items-center">
                  <div class="uk">
                    <img class="w-14 h-14 rounded-full" [src]="baseUrl+'/file/retrieve/'+item.photo"  onerror="this.src='assets/images/user.webp'" alt="">
                  </div>
                  <div class="ms-6">
                    <h3 class="text-lg font-semibold tracking-wider leading-6" style="text-transform: capitalize;">{{item.name}}</h3>
                    <p class="text-base font-light tracking-wider">Pune, Maharashtra 411033</p>
                    @if(item.hiredCount > 0){
                      <p class="text-sm font-semibold tracking-wider text-[#943E00]">{{(item.hiredCount > 50) ? '50+':item.hiredCount}} times Hired</p>
                    }
                    
                  </div>
                </div>
  
                <div class="mt-4">
                  <h3 class="tracking-wider"><b>Experts In:</b> 
                    <span class="font-light ms-3">
                      
                      <span *ngFor="let itemServices of item.services;let serviceIndex = index;">{{itemServices.displayText}}
                        <span *ngIf="item.services.length - 1 != serviceIndex">,</span> &nbsp;
                      </span>
                    </span>
                  </h3>
                </div>
  
  
              </div>
  
              <div class="flex flex-col">
                <button (click)="scrollToTop(item._id)"
                  class=" p-1.5 rounded my-1 border-[#D61919] hover:bg-[#bc2e2e] bg-[#EE4848] text-white text-center border">HIRE</button>
                <button [routerLink]="['/partner-profile/'+item.userCode]" routerLinkActive="router-link-active"
                  class=" p-1.5 mb-2 rounded my-1 border-primary hover:bg-primary hover:text-white text-primary text-center border">VIEW
                  PROFILE</button>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-2 gap-2">
              <!-- <div class="bg-blue-100 px-4 py-2">
                                  <h3 class="tracking-wider text-sm"><span class="font-light">Response Time: </span> <span class="font-semibold">Within 15 min</span></h3>
                              </div> -->
              <div class="bg-blue-100 px-4 py-2">
                <h3 class="tracking-wider text-sm text-center"><span class="font-light">Working Hours: </span> <span
                    class="font-semibold">08 AM to 9 PM</span></h3>
              </div>
              <div class="self-center">
                    <a class="me-2" *ngIf="item.website != '' && item.website != undefined" href="{{item.website}}"
                    target="_blank"><i
                      class="fa-solid fa-globe text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="item.youtube != '' && item.youtube != undefined" href="{{item.youtube}}"
                    target="_blank"><i
                      class="fa-brands fa-youtube text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="item.linkedin != '' && item.linkedin != undefined" href="{{item.linkedin}}"
                    target="_blank"><i
                      class="fa-brands fa-linkedin text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="item.instagram != '' && item.instagram != undefined" href="{{item.instagram}}"
                    target="_blank"><i
                      class="fa-brands fa-instagram text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a *ngIf="item.facebook != '' && item.facebook != undefined" href="{{item.facebook}}"
                    target="_blank"><i
                      class="fa-brands fa-facebook text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
              </div>
            </div>
            @if(item?.portfolio?.length > 0){
              <div class="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                @for( data of item?.portfolio;track data._id;let  i = $index){
                  @if(i < 2){
                    <a class="p-2 flex gap-2 flex-row items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                    >
                      <img (click)="openpopupModal(data)" class="rounded-t-lg h-20 w-20 md:rounded-none md:rounded-s-lg cursor-pointer" target="_blank"
                        src="{{baseUrl}}/file/retrieve/{{data.photo}}" onerror="this.src='assets/images/image-gallery.png'">
                      <div class="flex flex-col justify-between leading-normal">
                        <h5 class="mb-0 text-lg font-bold tracking-tight text-slate-900 dark:text-white">{{data.title}}</h5>
                        <p class="text-sm text-slate-500">For {{data.clientName}}</p>
                        <p
                          class="mb-2 font-normal text-sm text-gray-700 dark:text-gray-400 break-all truncate-two-lines textContainer{{i}}">
                          {{data.description}}</p>
                          <button (click)="readmore($event, i)" *ngIf="data.description.length > 20" class="read-more-button text-right text-xs me-2">Read more</button>
                  
                      </div>
                    </a>
                  }
                  
                }
              
              </div>
              
              
              }
          </div>
        }
        

        <div class="bg-white px-5 py-5 text-center" *ngIf="totalItems != vendorData.length">
          <button
            class=" p-1.5 px-10 rounded my-1 border-primary hover:bg-primary hover:text-white text-primary text-center border"
            (click)="viewMore()">View More</button>
        </div>

        <div class="bg-white mt-2 px-5 py-5">
          <!-- <h3 class="text-lg font-bold tracking-wider">Know More about {{serviceDetails.title}}?</h3> -->
          <p class="mt-2 tracking-wide service-details" [innerHTML]="serviceDetails.content"></p>
        </div>
      </div>

      <div class="bg-white overflow-auto">
        <div class="mx-5 my-5 cursor-pointer" *ngFor="let item of blogs" (click)="openBlog(item)">
          <div class="flex">
          <img class=" w-1/4 rounded me-2" src="{{baseUrl}}/file/retrieve/{{item.thumbnail}}"
            onerror="this.src='assets/images/img_not_found.jpg'">
          <div class="clear-none leading-5">
            <span class="font-semibold tracking-wide">{{item.title}}</span><br>
          </div>
        </div>
          <div class="text-sm font-light only-five-lines"><p [innerHTML]="item.content"></p></div>
        </div>
      </div>
    </div>
  </section>
</div>


<div class="relative z-10" *ngIf="isModalOpen" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">

      <div
        class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
        <div class="from-[#6B8FFA] to-[#ADC1FC]">
          <div>

            <div class="text-center">
              <section class="relative overflow-hidden bg-gradient-to-b ">
                <span class="text-white mt-2.5 me-2.5 float-right cursor-pointer"
                  (click)="isModalOpen = !isModalOpen">Close</span>
                <app-service-request [isModal]="true" [service]="serviceDetails.id"
                  (closeModal)="isModalOpen = !isModalOpen;">
                </app-service-request>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fab-button></app-fab-button>

<div id="popup-modal" tabindex="-1" aria-hidden="true"
class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="bg-gray-100 border border-black rounded-lg shadow">
            <img class="rounded-t-lg  md:rounded-none md:rounded-s-lg  cursor-pointer" target="_blank"
            src="{{baseUrl}}/file/retrieve/{{selectedPortfolio.photo}}" onerror="this.src='assets/images/image-gallery.png'">
          </div>
        </div>
    </div>
</div>
