<section id="servicePost" #scrollContainer *ngIf="type == '/fsi-calculator'"
  class="relative overflow-hidden h-[90vh] bg-gradient-to-b from-[#6B8FFA] to-[#ADC1FC]">
  <app-fsi-calculator [isModal]="false"></app-fsi-calculator>
</section>

<section id="servicePost" #scrollContainer *ngIf="type == '/side-margin-calculator'"
  class="relative overflow-hidden h-[90vh] bg-gradient-to-b from-[#6B8FFA] to-[#ADC1FC]">
  <app-side-margin [isModal]="false"></app-side-margin>
</section>

<main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">


<section aria-label="Blogs" class="container my-2">
  <div class="flex items-center pb-3 pt-1">
    <h1 class="text-xl font-semibold tracking-wide text-gray-600">Blogs</h1>
  </div>
  <div class="bg-white p-3 rounded-xl shadow-md">
    <div *ngFor="let item of blogs;let i = index" class="cursor-pointer" (click)="openBlog(item)">
      <div class="flex">
        <h3 class="flex-1 tracking-wider mb-0 font-semibold text-base text-gray-700">{{item.title}}</h3>
        <p class="text-red-500 text-sm font-bold font-mono">{{item.createdAt | date:'MMM dd, yyyy'}}</p>
      </div>

      <p class="text-sm mt-2 mb-2 tracking-wide text-gray-500" [innerHTML]="item.content | slice :0:200 "></p>
      <hr class="mb-1" *ngIf="i != (blogs.length -1)">
    </div>
  </div>
  <div class="flex justify-center">
    <button [routerLink]="['/blog']" routerLinkActive="router-link-active"
      class="flex auto text-base mt-4 py-2 font-semibold px-7 tracking-wider rounded hover:bg-blue-700 bg-primary text-white text-center border">View
      all Blogs</button>
  </div>
</section>

<section class="container" *ngIf="type == '/fsi-calculator'">
  <div class="mt-2 py-5">
    <h3 class="text-lg font-bold tracking-wider">Know More about FSI Calculator?</h3>
    <div class="text-base tracking-wider font-light">
      <h3 class="mt-2 font-light"><strong>What Is FSI (Floor Space Index)</strong></h3>
      <p class="text-sm">FSI is also called as far (floor are ratio)</p>
      <p class="text-sm">In general language FSI means permissible built up area on any plot. It is calculated by
        dividing Built up area
        by Plot Area.</p>
      <p class="text-sm">FSI = built up area/Plot area.</p>
      <p class="text-sm">E.g. – If permissible FSI for a plot of 1000 Sqft. is 1.10, then we can construct 1100 Sqft. of
        built up area.
        (Say 225 Sqft on 4 floors or 550 SqFt on 2 floors)</p>
      <p class="text-sm">The value of FSI is not same everywhere it is regulated according to the city zone, type of
        building and a lot
        of other amenities. It is possible to build up the construction only as imposed by the government.</p>
      <p class="text-sm mb-2">As explained above, there is known specific formula for FSI Depending upon planning
        authority by-laws.Balcony,
        terrace, staircase, lift, lift machine room are deducted from FSI on payment of premium. Ducts, Parking floor,
        basement, architectural treatment are not considered while calculating FSI.</p>
      <h3><strong>What is formula for FSI</strong></h3>
      <p class="text-sm">Areas like balcony, parking, terraces, staircase, lift etc. &nbsp;Are deducted from this
        calculation according
        to Pune FSI rules. For any region separate development control rule (DC rules) are published by government
        authorities.</p>
      <p class="text-sm">The formula for the FSI calculation goes this way,</p>
      <p class="text-sm mb-2">FSI = net plot area (after deducting road &amp; reservation x FSI index of that area)</p>
      <h2><strong>How to Calculate FSI in Pune</strong></h2>
      <div>
        <p class="text-sm">Steps to calculate FSI in Pune</p>
        <ol class="list-decimal ml-4">
          <li class="text-sm"><strong>Open <a href="#">Foot2feet FSI calculator </a></strong>
            <p>an automated calculator designed as per Pune DC rules</p>
          </li>
          <li class="text-sm"><strong>Select location of Plot</strong>
            <p>&nbsp;</p>
          </li>
          <li class="text-sm"><strong>Enter your plot area &amp; Front road width</strong>
            <p>&nbsp;</p>
          </li>
          <li class="text-sm"><strong>Get details of FSI for your Plot within seconds.</strong>
            <p>&nbsp;</p>
          </li>
        </ol>
      </div>
      <h2><strong>FSI for various type of building Use</strong></h2>
      <p class="text-sm">FSI in Pune Municipal Corporation is calculated as per development control and promotion
        regulations for Pune
        Municipal Corporation (<a href="https://pmc.gov.in/sites/default/files/DCR_PUNE_FINAL.pdf" target="_blank"
          rel="noopener">DCPR 2017</a>)</p>
      <h3><strong>As per dc rule 2017, FSI for any plot in Pune corporation is of three types as-</strong></h3>
      <ol class="list-decimal ml-4 mb-2">
        <li class="text-sm">Basic FSI – means Free FSI on any Plot. In Pune it is 1.10 for residential Building.&nbsp;
          (Basic FSI is
          excluding paid FSI or TDR).</li>
        <li class="text-sm">TDR (Transferable development right-this is to be purchased from other land owner whose land
          is surrendered
          for road or other reservations )</li>
        <li class="text-sm">Premium FSI–it is also called as fungible FSI or paid FSI this has to be purchased from Pune
          Municipal
          Corporation.</li>
      </ol>
      <p class="text-sm mb-2">In Pune corporation FSI changes as per front road width, land zone, use of the building
        etc., See variations is
        FSI as per type of building –</p>
      <h3><strong>FSI for Residential Building</strong></h3>
      <p class="text-sm mb-2">“Residential Buildings” means any building in which sleeping accommodation is provided for
        normal residential
        purposes with or without cooking or dining or both facilities. It includes one or two or multi-family dwellings,
        lodging or rooming houses, residential hotels, hostels, dormitories, dharmshalas, apartment houses, flats,
        service apartment, studio apartment and private garages incidental thereto,</p>
      <figure>
        <table class="w-full border ">
          <tbody class="border">
            <tr class="border">
              <td class="p-2 border-r">Road width</td>
              <td class="p-2 border-r">Basic FSI</td>
              <td class="p-2 border-r">Premium FSI</td>
              <td class="p-2 border-r">TDR FSI</td>
              <td class="p-2">Total FSI</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">Below 9 M</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 text-sm">1.10</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">9.00 to 11.99 Meter</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.30</td>
              <td class="p-2 border-r text-sm">0.40</td>
              <td class="p-2 text-sm">1.80</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">12.00 to 17.99 Meter</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">0.65</td>
              <td class="p-2 text-sm">2.25</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">18.00 to 23.99 Meter</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">0.90</td>
              <td class="p-2 text-sm">2.50</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">24.00 to 29.99 Meter</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">1.15</td>
              <td class="p-2 text-sm">2.75</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">30.00 M &amp; above</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">1.40</td>
              <td class="p-2 text-sm">3.00</td>
            </tr>
          </tbody>
        </table>
        <figcaption class="text-sm">Road-wise FSI for any plot in Pune</figcaption>
      </figure>
      <p class="text-sm">For illegally subdivide plot (after land subdivision act 1972), only 75% of total FSI is
        allowed. For NA plots
        and plots more than 2000 Square Meter, full 100% of FSI is applicable.</p>
      <p class="text-sm mb-2">Redevelopment FSI in Pune is same as regular FSI. Only difference is 0.20 (20%) paid FSI
        is given for society
        on roads less than 9.0 M.&nbsp;</p>
      <h3><strong>FSI for Commercial Building</strong></h3>
      <p class="text-sm">“Mercantile (Commercial) Buildings” means any building or part of a building, which is used as
        shops, stores,
        market, malls for display and sale of merchandise either wholesale or retail Office, storage and service
        facilities incidental to the sale of merchandise and located in the same building shall be included under this
        group;</p>
      <p class="text-sm mb-2">For commercial building total FSI will be as per above residential FSI table Basic FSI
        will be = 1.25&amp; TDR
        or PAID FSI will be reduced accordingly to achieved maximum FSI</p>
      <h3><strong>Industrial Building FSI</strong></h3>
      <p class="text-sm">Any building or part of a building or structure, in which products or materials of all kinds
        and properties are
        fabricated, assembled or processed like assembling plants, laboratories, power plants, smoke houses, refineries,
        gas plants, mills, dairies, factories etc.;</p>
      <p class="text-sm mb-2">Minimum road width should be 12.00 m for these categories of building &amp; FSI do not
        vary as per road width
      </p>
      <figure>
        <table class="w-full border ">
          <tbody class="border">
            <tr class="border">
              <td class="p-2 border-r">Plot Size in sq.m.</td>
              <td class="p-2 border-r">Basic FSI</td>
              <td class="p-2 border-r">Paid Fsi</td>
              <td class="p-2 border-r">TDR</td>
              <td class="p-2 border-r"></td>
              <td class="p-2"></td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">Upto 1000 SqM</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 border-r text-sm"></td>
              <td class="p-2 text-sm"></td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">1001 to 5000 SqM</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 border-r text-sm"></td>
              <td class="p-2 text-sm"></td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">Above 5000 SqM</td>
              <td class="p-2 border-r text-sm">1.10</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 border-r text-sm"></td>
              <td class="p-2 text-sm"></td>
            </tr>
          </tbody>
        </table>
        <figcaption class="text-sm">Industrial Building FSI</figcaption>
      </figure>
      <h3>FSI in <strong>Gaothan Area (Congested Area) </strong></h3>
      <p class="text-sm">A&nbsp;Gaothan&nbsp;is the portion of the land of the village which is ordinarily used for
        settlement. Whether
        a land parcel in a village or town or city is a&nbsp;Gaothan</p>
      <p class="text-sm mb-2">In gaothan area basic FSI change as per road width</p>
      <figure>
        <table class="w-full border ">
          <tbody class="border">
            <tr class="border">
              <td class="p-2 border-r">Road width in meter</td>
              <td class="p-2 border-r">Basic FSI</td>
              <td class="p-2 border-r">Paid FSI</td>
              <td class="p-2 border-r">TDR</td>
              <td class="p-2">Total</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">Below 9.0 M.</td>
              <td class="p-2 border-r text-sm">1.50</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 text-sm">1.50</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">9 m to 11.99 M</td>
              <td class="p-2 border-r text-sm">2.00</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 text-sm">2.00</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">12 M. to 17.99 M</td>
              <td class="p-2 border-r text-sm">2.00</td>
              <td class="p-2 border-r text-sm">0.25</td>
              <td class="p-2 border-r text-sm">0.00</td>
              <td class="p-2 text-sm">2.25</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">18 M to 23.99 M</td>
              <td class="p-2 border-r text-sm">2.00</td>
              <td class="p-2 border-r text-sm">0.25</td>
              <td class="p-2 border-r text-sm">0.25</td>
              <td class="p-2 text-sm">2.50</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">24&nbsp; to 29.99 M</td>
              <td class="p-2 border-r text-sm">2.00</td>
              <td class="p-2 border-r text-sm">0.25</td>
              <td class="p-2 border-r text-sm">0.50</td>
              <td class="p-2 text-sm">2.75</td>
            </tr>
            <tr class="border">
              <td class="p-2 border-r text-sm">30 M &amp; Above</td>
              <td class="p-2 border-r text-sm">2.00</td>
              <td class="p-2 border-r text-sm">0.25</td>
              <td class="p-2 border-r text-sm">0.75</td>
              <td class="p-2 text-sm">3.00</td>
            </tr>
          </tbody>
        </table>
      </figure>
      <h3><strong>Metro Zone – Transit Oriented Development (TOD)</strong></h3>
      <p class="text-sm">There is no metro benefit for plot having road below 9.00 meter in such case regular
        residential FSI rule /
        commercial FSI rules will be applicable</p>
      <p class="text-sm">Only plot above 4000 thousand Sqm &amp; access road more than 30 meter will have 4.0 FSI. Rest
        of the plot will
        have total maximum FSI as per below table</p>
      <p class="text-sm mb-2">TOD zone: – is the area 500 M. around the proposed Metro station boundary.</p>
      <figure class="mb-2">
        <table class="w-full border ">
          <tbody class="border">
            <tr class="border">
              <td class="p-2 border-r text-sm">Plot Area</td>
              <td class="p-2 border-r text-sm">9 to 12 m &nbsp;</td>
              <td class="p-2 border-r text-sm">12 to 18 m &nbsp;</td>
              <td class="p-2 border-r text-sm">18 to 24 m. &nbsp;</td>
              <td class="p-2 border-r text-sm">24 to 30 m. &nbsp;</td>
              <td class="p-2">above 30 m&nbsp;</td>
            </tr>
            <tr>
              <td class="p-2 border-r text-sm">Below 1000 SqM</td>
              <td class="p-2 border-r text-sm">2.0</td>
              <td class="p-2 border-r text-sm">2.25</td>
              <td class="p-2 border-r text-sm">2.50</td>
              <td class="p-2 border-r text-sm">2.75</td>
              <td class="p-2 text-sm">3.0</td>
            </tr>
            <tr>
              <td class="p-2 border-r text-sm">1000 to 2000</td>
              <td class="p-2 border-r text-sm">2.0</td>
              <td class="p-2 border-r text-sm">2.5</td>
              <td class="p-2 border-r text-sm">2.5</td>
              <td class="p-2 border-r text-sm">2.75</td>
              <td class="p-2 text-sm">3.0</td>
            </tr>
            <tr>
              <td class="p-2 border-r text-sm">2000 to 3000</td>
              <td class="p-2 border-r text-sm">2.0</td>
              <td class="p-2 border-r text-sm">2.5</td>
              <td class="p-2 border-r text-sm">3.0</td>
              <td class="p-2 border-r text-sm">3.0</td>
              <td class="p-2 text-sm">3.0</td>
            </tr>
            <tr>
              <td class="p-2 border-r text-sm">3000 to 4000</td>
              <td class="p-2 border-r text-sm">2.0</td>
              <td class="p-2 border-r text-sm">2.5</td>
              <td class="p-2 border-r text-sm">3.0</td>
              <td class="p-2 border-r text-sm">3.5</td>
              <td class="p-2 text-sm">3.5</td>
            </tr>
            <tr>
              <td class="p-2 border-r text-sm">4000 &amp; above</td>
              <td class="p-2 border-r text-sm">2.0</td>
              <td class="p-2 border-r text-sm">2.5</td>
              <td class="p-2 border-r text-sm">3.0</td>
              <td class="p-2 border-r text-sm">3.5</td>
              <td class="p-2 text-sm">4.0</td>
            </tr>
          </tbody>
        </table>
        <figcaption class="text-sm">TOD zone FSI</figcaption>
      </figure>
      <h2><strong>Question related to FSI in Pune</strong></h2>
      <div>
        <div>
          <p class="text-sm"><strong><strong>What is not included in FSI</strong></strong></p>
          <p class="text-sm">Ducts, Parking floor, basement, and architectural treatment are not included in FSI, Where
            as Depend upon
            planning authority by-laws, balcony, terrace, staircase, lift, lift machine room are deducted from FSI on
            payment of premium FSI</p>
        </div>
        <div>
          <p class="text-sm"><strong><strong>What is difference FSI and TDR</strong></strong></p>
          <p class="text-sm">FSI stands for Floor Space Index, In other words permissible built up area on any plot
            which is calculated
            by dividing Built up area by Plot Area. Whereas If any land is affected by reservation of amenity, road,
            garden or any other reservation then that land has to be surrendered to respective authority. After handover
            of the land area the authority gives development right certificate as compensation instead of money. These
            rights are called TDR which stands for Transferable Development Rights</p>
        </div>
        <div>
          <p class="text-sm"><strong><strong>Is Staircase included in carpet Area?</strong></strong></p>
          <p class="text-sm">Carpet area is the area which is actually covered by carpet. So staircase is only included
            in carpet area
            when it is inside the apartment</p>
        </div>
        <div>
          <p class="text-sm"><strong><strong>How many flats can be built 2400 square feet?</strong></strong></p>
          <p class="text-sm">You can built 6 to 10 apartments in 2400 Square feet plot as per depends on FSI you get for
            your plot.</p>
          <p class="text-sm"><span>For which area above FSI calculator works ?</span></p>
          <p class="text-sm"><span>Above
              calculator works for Pune corporation old limit which includes following areas</span></p>
          <p class="text-sm"><span>It is
              also applicable for 23 villages included in pune</span></p>
          <p class="text-sm"><span>Latest
              11 villages merged in pune in 2017 are also come under above rules. that means if you want to calculate
              FSI in&nbsp;Uruli Devachi, Phursungi, Bavdhan Budruk, Lohegaon, Hadapsar (Sadesatra Nali), Mundhwa
              (Keshavnagar area), Shivane, Dhayari , Undri, Ambegaon Khurda and Ambegaon Budruk </span></p>
          <p class="text-sm">FSI in yewalewadi is also as per above
            calculator as it was merged in pune in 2016</p>
        </div>
      </div>
    </div>
  </div>
</section>






<!-- //side margin -->
<section class="container" *ngIf="type == '/side-margin-calculator'">
  <div class="mt-2 py-5">
    <h3 class="text-lg font-bold tracking-wider">Know More about Side Margin?</h3>
    <br>
    <div class="font-light text-base tracking-wider">
      <h2 class="font-semibold">Regulations for Building Side Margin in Pune</h2>
      <h3 class="font-normal">BUILDING CODE FOR CONGESTED AREA</h3>
      <div></div>
      <h4 class="font-normal">Side Margin for Residential Buildings/ Residential Buildings with mix-use</h4>
      <div></div>
      <ol type="a">
        <li>Front Marginal distances -<strong> </strong>The minimum front setback
          from the existing or proposed road/roads shall be as under.</li>
      </ol>
      <br>
      <figure class=" rounded-md">
        <table class="w-full">
          <tbody>
            <tr>
              <td class="border border-gray-300 px-4 py-2"><strong>Sr. No.</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>Road width</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>For Residential building</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>For Residential Buildings with mix-use</strong></td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">(i)</td>
              <td class="border border-gray-300 px-4 py-2">For streets/lane less than<br>4.5 m. width</td>
              <td class="border border-gray-300 px-4 py-2">2.25 m from centre of the lane</td>
              <td class="border border-gray-300 px-4 py-2">2.25m. +1.50 mt. <br>from
                centre of lane</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">(ii)</td>
              <td class="border border-gray-300 px-4 py-2">For streets 4.5m. to less than 6.00 m. in width</td>
              <td class="border border-gray-300 px-4 py-2">NIL</td>
              <td class="border border-gray-300 px-4 py-2">1.50 m.</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">(iii)</td>
              <td class="border border-gray-300 px-4 py-2">For streets 6.00 m. to less than 12 m.
                in width</td>
              <td class="border border-gray-300 px-4 py-2">1.50 m.</td>
              <td class="border border-gray-300 px-4 py-2">2.00 m.</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">(iv)</td>
              <td class="border border-gray-300 px-4 py-2">For streets 12 m to less than 18 m. in width</td>
              <td class="border border-gray-300 px-4 py-2">1.50 m</td>
              <td class="border border-gray-300 px-4 py-2">2.25 m.</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">(v)</td>
              <td class="border border-gray-300 px-4 py-2">For streets 18
                m<br>above in width</td>
              <td class="border border-gray-300 px-4 py-2">1.50 m</td>
              <td class="border border-gray-300 px-4 py-2">2.25 m.</td>
            </tr>
          </tbody>
        </table>
      </figure>
      <br>
      <ul class="list-disc font-light  pl-6">
        <li>For the lanes having width less than 4.5m. abutting to any side of plot, a setback of 2.25m. from the centre
          of
          lane shall be provided to make such lane 4.5m. wide. No any projections shall be permissible on such widened
          lane
          width.</li>
      </ul>
      <ul class="list-disc font-light  pl-6">
        <li>All other regulations excepting FSI and its maximum
          loading limit, marginal distances as prescribed above, shall be applicable for
          development
          permission in congested area.</li>
      </ul>
      <ul class="list-disc font-light pl-6">
        <li>In R-2 zone, for buildings having shops /commercial user on any floor, 50% of front marginal distance shall
          be
          made available exclusively for the pedestrians.</li>
      </ul>

      <ul class="list-disc font-light pl-6">
        <li><strong>Height</strong>– Above set back and marginal distances shall be applicable for buildings less
          than
          15 m. in height. Marginal distances shall be increased by 1m. for buildings having height 15 m. and more but
          less
          than 24 m. For building having height 24 m. and more, marginal distances shall be as per regulations of
          non-congested area.</li>
      </ul>

      <br>
      <h4><strong> Set Back for Other buildingslike Public semi-public, Educational,
          Medical, Institutional, commercial, Mercantile, etc.</strong> </h4>
      <p>(a) Floor Space Index Maximum FSI permissible
        shall be as perthe tableunder regulation no.17.1.1 and subject to
        payment of premium as decided by Government from time to time.</p>
      <p>(b) Marginal Distance – For these buildings marginal distances shall be 3 m. on all sides including front
        margin.
      </p>
      <p>(c) For buildings having height more than 24 m., regulations of outside congested area shall apply for front,
        side
        and rear marginal distances.</p>
      <p>Provided also that, for buildings like cinema theatre,
        multiplex, assembly buildings, shopping malls, regulation of outside congested area, except FSI, shall
        apply.</p>
      <br>
      <h3><strong>REGUALTIONS FOR OUTSIDE CONGESTED AREA.</strong></h3>
      <br>
      <p style="font-size: 14px; font-style: normal; font-weight: 400;"></p>
      <p style="font-size: 14px; font-style: normal; font-weight: 400;"><span
          style="color: #3a3a3a; font-size: 18px; font-weight: semibold;">Marginal Distances for Residential Buildings
          Height
          15
          mt. and Below.</span></p>
      <br>
      <p>The provisions for minimum marginal distances as given in <strong>Table No.1 below </strong>shall apply for the
        Residential buildings, Residential with mix uses permissible in non-congested areas and ancillary,
        Residential buildings permissible in industrial zones having height upto15 m. and below.</p>
      <p>Table No.1</p>
      <figure>
        <div class="overflow-x-auto">
          <table class="table-auto w-full border-collapse border border-gray-300">
            <thead class="bg-gray-100">
              <tr>
                <th class=" border border-gray-300 px-4 py-2 font-medium">No.</th>
                <th class=" border border-gray-300  px-4 py-2 font-medium">Description of the road</th>
                <th class=" border border-gray-300 px-4 py-2 font-medium">Plot Size</th>
                <th class=" border border-gray-300  px-4 py-2 font-medium">Min plot width</th>
                <th class=" border border-gray-300  px-4 py-2 font-medium">Front Side</th>
                <th class=" border border-gray-300 px-4 py-2 font-medium">All other side</th>
              </tr>
            </thead>
            <tbody class="text-gray-700">
              <tr>
                <td class="borderborder-gray-300 px-4 py-2">1</td>
                <td class="borderborder-gray-300 px-4 py-2">NH/SH</td>
                <td class="border border-gray-300 px-4 py-2">450</td>
                <td class="border border-gray-300 px-4 py-2">15</td>
                <td class="border border-gray-300 px-4 py-2">as per highway</td>
                <td class="border border-gray-300 px-4 py-2">3</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">2</td>
                <td class="border border-gray-300 px-4 py-2">MDR/ODR</td>
                <td class="border border-gray-300 px-4 py-2">450</td>
                <td class="border border-gray-300 px-4 py-2">15</td>
                <td class="border border-gray-300 px-4 py-2">as per odr</td>
                <td class="border border-gray-300 px-4 py-2">3</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">3</td>
                <td class="border border-gray-300 px-4 py-2">Roads above 24 M</td>
                <td class="borderborder-gray-300 px-4 py-2">300</td>
                <td class="border border-gray-300 px-4 py-2">12</td>
                <td class="border border-gray-300 px-4 py-2">4.50 - residential<br>6.00 - residential with mix use</td>
                <td class="border border-gray-300 px-4 py-2">3</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">4</td>
                <td class="border border-gray-300 px-4 py-2">Roads below 24 m and upto 18 m.</td>
                <td class="border border-gray-300  px-4 py-2">250</td>
                <td class="border border-gray-300 px-4 py-2">10</td>
                <td class="border border-gray-300 px-4 py-2">4.50 m -residential<br>6.00 m. – mix use</td>
                <td class="border border-gray-300 px-4 py-2">3</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">5</td>
                <td class="border border-gray-300 px-4 py-2">Roads below 18 m. and upto 15 m.</td>
                <td class="border border-gray-300 px-4 py-2">250</td>
                <td class="border border-gray-300 px-4 py-2">10</td>
                <td class="border border-gray-300  px-4 py-2">3.00 mt for residential<br>4.50 m – mix use</td>
                <td class="border border-gray-300 px-4 py-2">2.25</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">6</td>
                <td class="border border-gray-300 px-4 py-2">Roads below 15 m. and above 9 m.</td>
                <td class="border border-gray-300 px-4 py-2">150</td>
                <td class="border border-gray-300 px-4 py-2">8</td>
                <td class="border border-gray-300 px-4 py-2">3.00 mt for residential<br>4.50 m. – mix use</td>
                <td class="border border-gray-300 px-4 py-2">1.50 or<br>2.25</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">7</td>
                <td class="border border-gray-300 px-4 py-2">Road upto 9 mt. width</td>
                <td class="border  border-gray-300 px-4 py-2">100</td>
                <td class="border border-gray-300 px-4 py-2">7</td>
                <td class="border border-gray-300 px-4 py-2">3</td>
                <td class="border border-gray-300 px-4 py-2">1.50 or<br>2.25</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2">8</td>
                <td class="border border-gray-300 px-4 py-2">Row Housing</td>
                <td class="border border-gray-300 px-4 py-2">30 to 125</td>
                <td class="border border-gray-300 px-4 py-2">3.5</td>
                <td class="border border-gray-300 px-4 py-2">2.25</td>
                <td class="border border-gray-300 px-4 py-2">1.50</td>
              </tr>
            </tbody>
          </table>
        </div>
      </figure>
      <br>
      <p><strong><u>Note</u></strong> –</p>
      <p>(1) Higher height may be permitted subject to marginal distance mentioned in Regulation
        No.17.2.3.</p>
      <p>(2) The minimum area of plots fronting on service roads along highways shall be with reference
        to
        the width of service road.</p>
      <p>(3) For semidetached buildings, side margin shall be on one side only.</p>
      <p>(4)Row-housing plots at the junction of two roads shall be larger to maintain the setback from
        both roads.</p>
      <p>Not more than 12and not less than 3 plots shall be allowed in each block of row housing. Each block shall be
        separated from the other by 6 m. road or 6 m. side margin distance of the plot or space including side marginal
        distance of the plot.</p>
      <p>(5) No garage shall be permitted in a building having stilt or basement provided for parking.
      </p>
      <p>(6) Construction of ottas, railings, barricades or
        supporting columns for canopy or porch shall not be allowed in
        front
        marginal distances. However, steps may be permitted within 1.2 m. from the building line. Also supporting
        columns
        for canopy or porch may be allowed within building line.</p>
      <p>(7) In no case ribbon development rules shall be relaxed without consent of the Highway
        Authority. (8) In case of special building, marginal distances shall be as per said regulations.
      </p>
      <p>(9) The plot width to depth ratioshall be
        1:1.5 to 1:2.5, as far as possible in case of
        plotted layout development permission.</p>
      <p>(10) In Public Housing Schemes for E.W.S. undertaken by government or semi-government organizations
        marginal
        distances shall be as per their respective schemes and rules.</p>
      <p>(11) The front setback set-out in already approved and partially developed layouts / schemes, may be retained
        as
        per said approval, so as to maintain the building line.</p>
      <p>(12) The pattern of development like semi-detached, row housing, etc. in already approved layout shall be as
        per
        said approved layout.</p>
      <p>(13) Where commencement certificate is granted prior to publication of draft development plan and the said plot
        is
        affected by new road proposed in the development plan, then front margin stand relaxed to that</p>
      <p>extent.</p>
      <p><strong> Other Buildings</strong>: The
        Provision as given in Table No.2. below shall apply for different categories of other buildings.</p>
      <figure>
        <table class="w-full border border-gray-300">
          <tbody>
            <tr>
              <td class="border border-gray-300 px-4 py-2"><strong>Sr. no.</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>Type of building</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>Required Road</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>Side margins</strong></td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"><strong>1</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>Medical buildings</strong></td>
              <td class="border border-gray-300 px-4 py-2">&nbsp;</td>
              <td class="border border-gray-300 px-4 py-2">&nbsp;</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"></td>
              <td class="border border-gray-300 px-4 py-2">a) Hospital, Maternity Homes, Health Club, Public Semi-public
                buildings being special building</td>
              <td class="border border-gray-300 px-4 py-2">12 m</td>
              <td class="border border-gray-300 px-4 py-2">6 m. on all sides</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"></td>
              <td class="border border-gray-300 px-4 py-2">b) Hospital, Maternity Homes, Health Club, Public
                Semi-public,
                buildings not being special buildings</td>
              <td class="border border-gray-300 px-4 py-2">12 m</td>
              <td class="border border-gray-300 px-4 py-2">Margins as per Table no 6.</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"><strong>2</strong></td>
              <td class="border border-gray-300 px-4 py-2"><strong>Educational buildings</strong></td>
              <td class="border border-gray-300 px-4 py-2"></td>
              <td class="border border-gray-300 px-4 py-2"></td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"></td>
              <td class="border border-gray-300 px-4 py-2">a) Pre-primary School</td>
              <td class="border border-gray-300 px-4 py-2">6 m.</td>
              <td class="border border-gray-300 px-4 py-2">Margins as per Table no 6.</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"></td>
              <td class="border border-gray-300 px-4 py-2">b) Primary School</td>
              <td class="border border-gray-300 px-4 py-2">9 m.</td>
              <td class="border border-gray-300 px-4 py-2">4.5m. on all sides</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2"></td>
              <td class="border border-gray-300 px-4 py-2">c) Other Educational Buildings</td>
              <td class="border border-gray-300 px-4 py-2">12 m</td>
              <td class="border border-gray-300 px-4 py-2">6.00 m. on all sides</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">3</td>
              <td class="border border-gray-300 px-4 py-2">Cinema Theatre/ Drama Theatre/ Assembly Hall/ Multiplex /
                Shopping Malls</td>
              <td class="border border-gray-300 px-4 py-2">15 m</td>
              <td class="border border-gray-300 px-4 py-2">Front -12 m (only one major road) <br>6 m. on all other sides
              </td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">4</td>
              <td class="border border-gray-300 px-4 py-2">Mangalkaryalaya like buildings</td>
              <td class="border border-gray-300 px-4 py-2">12 m</td>
              <td class="border border-gray-300 px-4 py-2">Front -12 m (only one major road) <br>6 m. on all other sides
              </td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">5</td>
              <td class="border border-gray-300 px-4 py-2">Petrol/Fuel Filling Stations with or without service bays
              </td>
              <td class="border border-gray-300 px-4 py-2">12 m</td>
              <td class="border border-gray-300 px-4 py-2">6.00 m. on all sides</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">6</td>
              <td class="border border-gray-300 px-4 py-2">Mercantile, Business, hotel Commercial mix use with
                residential
                buildings.</td>
              <td class="border border-gray-300 px-4 py-2">12 M</td>
              <td class="border border-gray-300 px-4 py-2">Front 6.00 m. <br>Side & rear 4.5 m.</td>
            </tr>
            <tr>
              <td class="border border-gray-300 px-4 py-2">7</td>
              <td class="border border-gray-300 px-4 py-2">Stadium / Pavilion</td>
              <td class="border border-gray-300 px-4 py-2">12 M</td>
              <td class="border border-gray-300 px-4 py-2">6 m. on all sides</td>
            </tr>
          </tbody>
        </table>
      </figure>

      <p><strong>NOTE:</strong></p>
      <p>i) In case of plots fronting on National Highway, State Highway and Major District Roads,
        the building line shall be as per Ribbon Development Rules as given in Table above, whichever is more.</p>
      <p>ii) Side and rear marginal distances mentioned in above Table shall be subject to Regulation
        No.17.2.3, whichever is more.</p>
      <p>iii) In case of special building, marginal distances shall be as per said regulations.</p>
      <p>iv) Commercial Shops</p>
      <p>1. Shops may also be allowed fronting side and
        rear
        margins.</p>
      <p class="font-medium">2.Minimum area
        of Shops shall be 6 Sq.mt with a minimum width of 2.00 m</p>
      <p class="font-normal">v) Fuel Station –</p>
      <p>1) Fuel filling station shall not be permitted
        within a distance of 90 meter from the nearest gate of school, hospital and theatre, place of assembly or
        stadium.
      </p>
      <p>2) In the case of kiosks and other buildings for
        sales office, snack bars etc. within the plot for fuel filling stations, the setbacks from the boundaries shall
        be
        4.50 m. Further the other clearances for the installations shall be as per the Petroleum Rules of 1937.</p>
      <p>vi)<span>Construction
          of Cinema Theatre/Multiplex shall confirm to the provisions of Maharashtra Cinema (Regulations)
          Rules,
          1966 and as amended from time to time.</span></p>
      <p style="font-size: 14px; font-style: normal; font-weight: 400;"></p>
      <h4> <strong>Marginal distances for Buildings of Higher Heights</strong></h4>
      <p><strong>(a) Front Margin – </strong>Front margin shall be as given
        below
        with reference to height of</p>
      <figure class="w-full">
        <div class="overflow-auto border border-gray-300 rounded-lg">
          <table class="w-full">
            <tbody>
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-justify">i)</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">Height above 18 m. upto 24 m.</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">4.5 m and 6.00 m. for residential with mix use
                </td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-justify">ii)</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">Height above 24 m. upto 36 m.</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">6.0 m.</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-justify">iii)</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">Height above 36 m. upto 50 m.</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">9.00 m.</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-justify">iv)</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">Height above 50 m. upto 70 m.</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">12.00 m.</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-justify">v)</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">Height above 70 m. upto 100 m.</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">14.00 m.</td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-justify">vi)</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">Height above 100 m.</td>
                <td class="border border-gray-300 px-4 py-2 text-justify">16.00 m.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </figure>
      <br>
      <p>Provided that, in case of group housing scheme where building abuts on internal road, the minimum
        3.0 m. set back from internal road or distance between two buildings whichever is more shall be provided.
        For Development plan road or classified road or through
        road, passing through Group Housing Scheme, the setback as prescribed in the regulations shall be
        provided.</p>
      <p><strong>(b)Side or rear marginal distance </strong>– Side or rear marginal distance <strong>in relation to the
          height </strong>of the building for light and ventilation shall be as below</p>
      <p>The marginal distance on all sides shall be as per <strong>Table No.6 </strong>for building height 15.0 m. and
        below. For height more than 15.0 m., the marginal distance on all sides except the front side of a building
        shall be minimum<strong>H/4</strong>(Where H = Height of
        the building above ground level) subject to a minimum of 3.0 m. for
        residential building, 4.5 m. for commercial building and 6.0 m. for special building mentioned in definition no.
        2.77.13.Provided that such marginal distance shall be subject to maximum of 16.0 m. If the land owner wishes to
        keep
        higher margins he may be allowed to do so.</p>
      <p>Provided further that the building height for the purposes of this regulation and for calculating the marginal
        distances shall be exclusive of height of parking floors upto 6.0 m.</p>
      <p>Provided also that, for building having shops / commercial user on any floor, 50% of front marginal distances
        shall
        be made available exclusively for pedestrians.</p>
      <p><strong>(c) Provision for Step Margin</strong>: – Step margins may be allowed to be provided on upper floor at
        height 24 m. and above to achieve required marginal distances as mentioned in these regulations subject to
        structural stability and fire protection norms as prescribed from time to time. If building height 24 m. and
        above
        minimum side margin at ground level shall be 7.5 m. and if building height</p>
      <p>50 m. and above the minimum side margin shall be 9.00 m. In congested area minimum side margin at ground level
        shall be 6.00 m.</p>
      <p> In case of layout of two or
        more buildings of any users:-</p>
      <p><strong>i) Distance between two buildings</strong>– The distance
        betwee two buildings shall be the side/rear marginal distance required for
        the
        taller building between the two adjoining buildings.</p>
      <p><strong>ii)</strong>Providedthat,thepath way / internal
        road may be allowed to be proposed in such marginal distance
        by keeping 3.0 m. distance on both side from such internal road.</p>
      <p>Provided also that, where rooms do not derive light and ventilation from the exterior open space, the
        distance between the two buildings may be reduced by 1
        m. subject to a minimum of 3m. (if necessary 6.0
        m. in case of special building) and maximum of 8.0
        m. No projections shall be permitted in this exterior space.</p>
      <p>In case of
        group housing scheme where building abuts on internal road, the minimum 3.0 m. set back from internal
        road or distance between two buildings whichever is more shall be provided. For Development plan road or
        classified
        road or through road, passing through Group Housing Scheme, the setback as prescribed in the regulations shall
        be
        provided.</p>
    </div>
  </div>
</section>

</main>