import { AllServicesComponent } from './all-services/all-services.component';
import { CalculatorsComponent } from './calculators/calculators.component';
import { AuthGuard } from './core/guards/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PartnerProfileComponent } from './partner-profile/partner-profile.component';
import { ProjectComponent } from './project/project.component';
import { SigninComponent } from './shared/signin/signin.component';
import { StandaloneCalculatorsComponent } from './standalone-calculators/standalone-calculators.component';
import { StandaloneServicesComponent } from './standalone-services/standalone-services.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "company",
    loadChildren: () => import("./extras/extras.module").then(m => m.ExtrasModule)
  },
  {
    path: "blog",
    loadChildren: () => import("./blogs/blogs.module").then(m => m.BlogsModule)
  },
  {
    path: "questions",
    loadChildren: () => import("./questions/questions.module").then(m => m.QuestionsModule)
  },
  {
    path: "sign-in",
    component: SigninComponent
  },
  {
    path: "all-services",
    component: AllServicesComponent
  },
  {
    path: 'all-calculator',
    component: CalculatorsComponent
  },
  {
    path: "fsi-calculator",
    component: StandaloneCalculatorsComponent
  },
  {
    path: "side-margin-calculator",
    component: StandaloneCalculatorsComponent
  },
  {
    path: "devolopement-charges-calculator",
    component: StandaloneCalculatorsComponent
  },
  {
    path: "water-requirement-calculator",
    component: StandaloneCalculatorsComponent
  },
  {
    path: "tdr-calculator",
    component: StandaloneCalculatorsComponent
  },
  {
    path: "parking-calculator",
    component: StandaloneCalculatorsComponent
  },
  {
    path: "partner-profile/:userCode",
    component: PartnerProfileComponent
  },
  {
    path: "my-dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "project/:projectId",
    component: ProjectComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ":category/:serviceTitle",
    component: StandaloneServicesComponent
  },
  {
    path: "blog/7-types-of-calculators-by-foot2feet-app",
    redirectTo: "blog/7-Types-of-Construction-Calculators-by-Foot2Feet",
    pathMatch: "full"
  },
  {
    path: "blog/Calculation-of-FSI-Pline-and-its-exemption-in-UDCPR-2020",
    redirectTo: "blog/Calculation-of-FSI-Pline-and-its-exemption-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "blog/different-types-of-nocs-for-construction-you-should-be-aware-of",
    redirectTo: "blog/different-types-of-nocs-for-construction-you-should-be-aware-of",
    pathMatch: "full"
  },
  {
    path: "blog/fsi-calculator-new-way-to-compute-fsi-foot2feet",
    redirectTo: "blog/Importance-of-FSI",
    pathMatch: "full"
  },
  {
    path: "blog/importance-of-fsi",
    redirectTo: "blog/Importance-of-FSI",
    pathMatch: "full"
  },
  {
    path: "blog/Industrial-Township-under-Aerospace-and-Defense-Manufacturing-Policy-in-UDCPR-2020",
    redirectTo: "blog/Industrial-Township-under-Aerospace-and-Defense-Manufacturing-Policy-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "blog/Integrated-Township-Project-(ITP)-in-UDCPR-2020",
    redirectTo: "blog/Integrated-Township-Project-%28ITP%29-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "blog/know-your-land-in-detail-with-a-feasibility-report",
    redirectTo: "blog/know-Your-Land-in-Detail-with-a-Feasibility-Report",
    pathMatch: "full"
  },
  {
    path: "blog/must-know-factor-of-your-land",
    redirectTo: "blog/Must-Know-Factor-of-Your-Land",
    pathMatch: "full"
  },
  {
    path: "blog/non-agricultural-land-has-more-valuation-than-other-lands",
    redirectTo: "blog/Non-Agricultural-land-has-more-valuation-than-other-lands",
    pathMatch: "full"
  },
  {
    path: "blog/pre-construction-tips-7-things-to-check",
    redirectTo: "blog/Pre-Construction-Tips-7-Things-to-Check",
    pathMatch: "full"
  },
  {
    path: "blog/Regulations-for-Permissible-FSI-in-Non-Congested-Area-In-Maharashtra-in-UDCPR-2020",
    redirectTo: "blog/Regulations-for-Permissible-FSI-in-Non-Congested-Area-In-Maharashtra-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "blog/Signs-and-Outdoor-Display-Structures-in-UDCPR-2020",
    redirectTo: "blog/Signs-and-Outdoor-Display-Structures-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "blog/terminology-used-in-building-byelaws",
    redirectTo: "blog/Terminology-used-in-Building-bye-laws",
    pathMatch: "full"
  },
  {
    path: "blog/terminology-used-in-construction-industry",
    redirectTo: "blog/Terminology-used-in-Building-Bylaws",
    pathMatch: "full"
  },
  {
    path: "blog/types-of-land-zone-its-benefits",
    redirectTo: "blog/Types-of-Land-zone-and-its-benefits",
    pathMatch: "full"
  },
  {
    path: "blog/when-authority-can-reject-building-proposal-in-india",
    redirectTo: "blog/When-authority-can-reject-building-Proposal-in-India%3F",
    pathMatch: "full"
  },
  {
    path: "construction-calculator",
    redirectTo: "blog/Permission-from-the-Planning-Authority-is-Mandatory-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmc",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmc/development-charges-for-construction",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmc/fsi-in-pune",
    redirectTo: "fsi-calculator",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmc/parking-requirement-calculator",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmc/side-margin-calculator",
    redirectTo: "side-margin-calculator",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmrda",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmrda/fsi-for-agricultural-land",
    redirectTo: "blog/Fsi-Calculator-New-Way-to-Compute-FSI",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmrda/fsi-for-industrial-building",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmrda/fsi-in-pmrda",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmrda/pmrda-building-setbacks",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "construction-calculator/pmrda/pmrda-parking-rules-for-new-building",
    redirectTo: "blog/Off-Street-Parking-Requirement-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "construction-material",
    redirectTo: "blog/Alternatives-Materials,-Methods-of-Design-&-Construction-and-Tests-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "construction-services/architect-in-pune",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "construction-services/architect-in-pune/farmhouse-architect",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "construction-services/architect-in-pune/hospital-architecture-in-pune",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "construction-services/architect-in-pune/hotel-architect",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "construction-services/architect-in-pune/liasoning-architect-for-building-permission",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "construction-services/architect-in-pune/restaurant-interior-designer",
    redirectTo: "Consultants/interior-designer",
    pathMatch: "full"
  },
  {
    path: "construction-services/civil-contractor-in-pune",
    redirectTo: "Contractors/civil-contractor",
    pathMatch: "full"
  },
  {
    path: "construction-services/home-interior-design",
    redirectTo: "Consultants/interior-designer",
    pathMatch: "full"
  },
  {
    path: "construction-services/home-renovation-contractor-in-pune",
    redirectTo: "Contractors/renovation-contractor",
    pathMatch: "full"
  },
  {
    path: "construction-services/house_contractor_construction_in_pune",
    redirectTo: "Contractors/house-contractor",
    pathMatch: "full"
  },
  {
    path: "construction-services/labour-contractor-in-pune",
    redirectTo: "Contractors/labour-contractor",
    pathMatch: "full"
  },
  {
    path: "construction-services/legal-services/conveyance-deed",
    redirectTo: "Other%20Services/conveyance-deed-and-deemed-conveyance",
    pathMatch: "full"
  },
  {
    path: "construction-services/legal-services/title-search-survey",
    redirectTo: "Legal%20Services/private-land-survey",
    pathMatch: "full"
  },
  {
    path: "construction-services/mep-consultants-in-pune",
    redirectTo: "Consultants/mep-consultant",
    pathMatch: "full"
  },
  {
    path: "construction-services/project-management-consultants-in-pune",
    redirectTo: "Consultants/pmc",
    pathMatch: "full"
  },
  {
    path: "construction-services/property-valuation",
    redirectTo: "Consultants/valuation",
    pathMatch: "full"
  },
  {
    path: "demarcation",
    redirectTo: "Contractors/demolition-contractor",
    pathMatch: "full"
  },
  {
    path: "drawing-services",
    redirectTo: "Legal%20Services/architectural-drawing-service",
    pathMatch: "full"
  },
  {
    path: "drawing-services/pre-dcr",
    redirectTo: "Legal%20Services/architectural-drawing-service",
    pathMatch: "full"
  },
  {
    path: "gunthewari-act",
    redirectTo: "blog/Gunthewari-Yojana",
    pathMatch: "full"
  },
  {
    path: "industrial-architect",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services",
    redirectTo: "Consultants/how-to-select-good-builder-for-redevelopment-project",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/architect-for-redevelopment",
    redirectTo: "Consultants/architect",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/feasibility-report",
    redirectTo: "Legal%20Services/redevelopment-feasibility-report",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/get-builder-quotation",
    redirectTo: "Consultants/how-to-select-good-builder-for-redevelopment-project",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/guidelines-for-successful-redevelopment",
    redirectTo: "Consultants/how-to-select-good-builder-for-redevelopment-project",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/important-documents-for-redevelopment",
    redirectTo: "blog/Step-by-Step-Society-Redevelopment-Process",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/project-managment-consultant",
    redirectTo: "Consultants/what-is-project-management-consultant-%28pmc%29-in-redevelopment%3F",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/redevelopment-related-questions",
    redirectTo: "Consultants/what-is-project-management-consultant-%28pmc%29-in-redevelopment%3F",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/step-by-step-society-redevelopment-process",
    redirectTo: "blog/Step-by-Step-Society-Redevelopment-Process",
    pathMatch: "full"
  },
  {
    path: "redevelopment-services/tips-for-selection-of-builder",
    redirectTo: "Consultants/how-to-select-good-builder-for-redevelopment-project",
    pathMatch: "full"
  },
  {
    path: "site/aviation_noc",
    redirectTo: "Legal%20Services/aviation",
    pathMatch: "full"
  },
  {
    path: "site/drainage_noc",
    redirectTo: "Legal%20Services/drainage-noc",
    pathMatch: "full"
  },
  {
    path: "site/fire_noc",
    redirectTo: "Legal%20Services/fire-noc",
    pathMatch: "full"
  },
  {
    path: "site/garden_noc",
    redirectTo: "Legal%20Services/garden-noc",
    pathMatch: "full"
  },
  {
    path: "site/land_survey",
    redirectTo: "Legal%20Services/private-land-survey",
    pathMatch: "full"
  },
  {
    path: "site/na_order_land_conversion",
    redirectTo: "blog/Must-Know-Factor-of-Your-Land",
    pathMatch: "full"
  },
  {
    path: "site/property_tax_noc",
    redirectTo: "Legal%20Services/property-tax-noc",
    pathMatch: "full"
  },
  {
    path: "site/rera",
    redirectTo: "Legal%20Services/rera",
    pathMatch: "full"
  },
  {
    path: "site/rera/rera_registration_consultant",
    redirectTo: "Legal%20Services/rera",
    pathMatch: "full"
  },
  {
    path: "site/rera/rera-registration-for-agents",
    redirectTo: "Legal%20Services/rera",
    pathMatch: "full"
  },
  {
    path: "site/solar-noc",
    redirectTo: "Other%20Services/solar-panel",
    pathMatch: "full"
  },
  {
    path: "site/tdr_transferable_development_rights",
    redirectTo: "blog/Regulations-for-Grant-of-Transferable-Development-Rights-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "site/water_noc",
    redirectTo: "Legal%20Services/water-noc",
    pathMatch: "full"
  },
  {
    path: "site/zoning_certification",
    redirectTo: "blog/Zone-Certificate",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state",
    redirectTo: "blog/Rule-No.-7.0-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/agricultural-zone",
    redirectTo: "blog/Uses-Permissible-in-Agricultural-Zone-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/areas-covered-exempted-from-rules",
    redirectTo: "blog/CIDCO-Area-Excluding-Naina-Area-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/commercial-zone",
    redirectTo: "blog/Uses-Permissible-in-Commercial-Zone-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/convert-old-rules-in-new-udcpr",
    redirectTo: "blog/Building-Services-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/land-use-classification-land-zones",
    redirectTo: "blog/Types-of-Land-zone-and-its-benefits",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/residential-zone-r1-r2-in-udcpr",
    redirectTo: "blog/Uses-Permissible-in-Residential-Zones-R1-in-UDCPR-2020",
    pathMatch: "full"
  },
  {
    path: "udcpr-building-rules-for-maharashtra-state/terminology",
    redirectTo: "blog/Terminology-used-in-Building-Byelaws",
    pathMatch: "full"
  },
  {
    path: "zone-conversion",
    redirectTo: "blog/Commercial-Buildings-in-CBD,-Commercial,-Residential-Zone-in-Planning-Authorities-Areas-in-UDCPR-2020"
  },
  {
    path: "404",
    component: NotFoundComponent
  },
  {
    path: "**",
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
