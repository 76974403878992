<div class="bg-white">
  <div>
    <main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex items-baseline justify-between pb-6 pt-3">
        <h1 class="text-3xl font-bold tracking-tight text-gray-700">All Services</h1>
      </div>

      <div
        class="bg-gray-100 mx-auto my-3 max-w-2xl rounded ring-1 ring-gray-200 sm:mt-1 lg:mx-0 lg:max-w-none lg:hidden">
        <div class="lg:m-3 block w-full">
          <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm"><i class="fa-solid fa-magnifying-glass"></i></span>
            </div>
            <input type="text" [(ngModel)]="searchTerms" name="price" focuse="true" id="price"
              class="block w-full rounded-md border-0 py-2 pl-8 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Search...">
          </div>
        </div>

      </div>
      <div class="pb-6 pt-3" *ngFor="let categoryGroup of groupedServices | filter:searchTerms">

        <h3 class="text-xl md:text-2xl font-semibold tracking-wider text-gray-700">{{ categoryGroup.key }}</h3>

        <div>
          <div
            class=" grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 md:gap-6 lg:gap-6 xl:gap-6 mx-1 md:mx-6 lg:mx-0 pt-2 md:pt-5">

            <div class=" relative mb-5 rounded-lg w-100 cursor-pointer"
              *ngFor="let service of categoryGroup.value | filter:searchTerms"
              [routerLink]="['/',categoryGroup.key,service.url]">
              <img src="{{baseURL}}/file/retrieve/{{service.largeIcon}}" [alt]="service.displayText"
                class="h-36 md:h-32 lg:h-36 object-center rounded-lg border-b " width="100%">
              <div class="px-1 py-1">
                <div class="min-h-min">
                  <h4 class="text-base font-normal">{{service.title}}</h4>
                  <p class="text-sm font-light leading-normal text-slate-700">{{service.expertCount}} Professional</p>
                  <!-- <p class="font-normal text-sm tracking-wide leading-4 text-gray-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                </div>

              </div>
              <div class="mt-auto">
                <button routerLinkActive="router-link-active" 
                  class="w-full p-1.5 rounded my-1 border-primary hover:bg-primary hover:text-white font-normal text-sm text-primary text-center border">
                Get Quotes</button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </main>
  </div>
</div>

<app-fab-button></app-fab-button>