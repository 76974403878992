import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FabButtonComponent } from '../shared/fab-button/fab-button.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [CommonModule, NotFoundComponent, SharedModule, RouterModule],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

}
