let UseOfBuilding = [
    { type: 'Row House', specialBldValue: 1, roadMargin: 2.25 },
    { type: 'Bungalow', specialBldValue: 1, roadMargin: 3 },
    { type: 'Bungalow- Semi Detached', specialBldValue: 1, roadMargin: 3 },
    { type: 'Residential Building (upto G+2 or P+3)', specialBldValue: 1, roadMargin: 3 },
    { type: 'Residential with Shops / office on Ground', specialBldValue: 1, roadMargin: 3 },
    { type: 'Any Residential Building', specialBldValue: 2, roadMargin: 3 },
    { type: 'Hostel', specialBldValue: 2, roadMargin: 3 },
    { type: 'Residential with Shops / Mix Use', specialBldValue: 2.5, roadMargin: 3 },
    { type: 'Residential with offices / Mix Use', specialBldValue: 2.5, roadMargin: 3 },
    { type: 'Any Commercial Building', specialBldValue: 3, roadMargin: 3 },
    { type: 'Office Building with shop', specialBldValue: 3, roadMargin: 3 },
    { type: 'Office Building without shop', specialBldValue: 3, roadMargin: 3 },
    { type: 'Hotel', specialBldValue: 3, roadMargin: 3 },
    { type: 'Restaurant', specialBldValue: 3, roadMargin: 3 },
    { type: 'Resort', specialBldValue: 3, roadMargin: 3 },
    { type: 'Public Building', specialBldValue: 3, roadMargin: 3 },
    { type: 'Semi Public Building', specialBldValue: 3, roadMargin: 3 },
    { type: 'Institutional', specialBldValue: 3, roadMargin: 3 },
    { type: 'Preprimary School', specialBldValue: 3, roadMargin: 3 },
    { type: 'Primary School', specialBldValue: 3, roadMargin: 3 },
    { type: 'Secondary School', specialBldValue: 3, roadMargin: 3 },
    { type: 'College Building', specialBldValue: 3, roadMargin: 3 },
    { type: 'Any other Educational building', specialBldValue: 3, roadMargin: 3 },
    { type: 'Health Club or clinic', specialBldValue: 3, roadMargin: 3 },
    { type: 'Maternatiy homes', specialBldValue: 3, roadMargin: 3 },
    { type: 'Hospital', specialBldValue: 3, roadMargin: 3 },
    { type: 'Any other Medical building', specialBldValue: 3, roadMargin: 3 },
    { type: 'Mangal Karyalaya', specialBldValue: 3, roadMargin: 3 },
    { type: 'Assembly Hall', specialBldValue: 3, roadMargin: 12 },
    { type: 'Shopping mall', specialBldValue: 3, roadMargin: 12 },
    { type: 'Multiplex', specialBldValue: 3, roadMargin: 12 },
    { type: 'Cinema Theatre', specialBldValue: 3, roadMargin: 12 },
    { type: 'Industrial Building', specialBldValue: 4, roadMargin: 0 },
];

const SideMarginByRoadWidth = [
    {
        start: 0,
        end: 17.99,
        value: 1.5
    },
    {
        start: 18,
        end: 29.99,
        value: 2
    },
    {
        start: 30,
        end: 100000,
        value: 3
    },
]

const IndustrialSideMarginByPlotArea = [
    {
        start: 0,
        end: 299.99,
        value: 2.25
    },
    {
        start: 300,
        end: 999.99,
        value: 3
    },
    {
        start: 1000,
        end: 1000000,
        value: 4.5
    },
]

const RoadMarginByRoadWidth = [
    {
        start: 3,
        end: 17.99,
        value: 3,
        type: "Mahanagar Palika"
    },
    {
        start: 18,
        end: 29.99,
        value: 4.5,
        type: "Mahanagar Palika"
    },
    {
        start: 30,
        end: 100000,
        value: 6,
        type: "Mahanagar Palika"
    },
    {
        start: 3,
        end: 17.99,
        value: 3,
        type: "Non-Mahanagar Palika"
    },
    {
        start: 18,
        end: 29.99,
        value: 4.5,
        type: "Non-Mahanagar Palika"
    },
    {
        start: 30,
        end: 100000,
        value: 4.5,
        type: "Non-Mahanagar Palika"
    }
]

const CongestedRoadMargin = [
    {
        start: 0,
        end: 4.50,
        value: 2.25,
        type: "Residential Building"
    },
    {
        start: 4.51,
        end: 5.99,
        value: 0,
        type: "Residential Building"
    },
    {
        start: 6,
        end: 11.99,
        value: 1,
        type: "Residential Building"
    },
    {
        start: 12,
        end: 100000,
        value: 2,
        type: "Residential Building"
    },
    {
        start: 0,
        end: 4.50,
        value: 3.75,
        type: "Residential Mix Use"
    },
    {
        start: 4.51,
        end: 5.99,
        value: 1.5,
        type: "Residential Mix Use"
    },
    {
        start: 6,
        end: 11.99,
        value: 2,
        type: "Residential Mix Use"
    },
    {
        start: 12,
        end: 100000,
        value: 2.5,
        type: "Residential Mix Use"
    },
]

export {
    UseOfBuilding,
    SideMarginByRoadWidth,
    IndustrialSideMarginByPlotArea,
    RoadMarginByRoadWidth,
    CongestedRoadMargin
};