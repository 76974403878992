import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { AuthService } from '../core/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FabButtonComponent } from '../shared/fab-button/fab-button.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Modal } from 'flowbite';
import { Title } from '@angular/platform-browser';
 
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, DashboardComponent, SharedModule, FormsModule, RouterModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{

  targetaddprojectModal:any;
  addprojectModal:any;
  
  data:any = [];

  searchValue = "";

  title = "";

  limit = 25;
  page = 1;
  totalPages = 1;
  isModalOpen =false;
  edit = false;
  item:any;

  isLoading = false;


  constructor(public apiS:ApiService, public authS:AuthService, public toastr:ToastrService, public appC : AppComponent,
    @Inject(PLATFORM_ID) private platformId: Object,@Inject(DOCUMENT) public document: Document, public titleS: Title){
      titleS.setTitle("All Projects - " + appC.title)
      
  }
  ngOnInit(){
    if (isPlatformBrowser(this.platformId)) {
      const messagingElement = this.document.querySelector('.messaging');
      if (messagingElement) {
        messagingElement.classList.remove('hidden');
      }
      this.targetaddprojectModal =  this.document.getElementById('addprojectModal');
      this.addprojectModal = new Modal(this.targetaddprojectModal);
    }
    this.getData();
  }

  getData(){
    if(this.authS.currentUserValue){
      this.apiS.getProject({user:this.authS.currentUserValue.id},this.page,this.limit,this.searchValue).subscribe(data=>{
        this.data = data.data;
        
      })
    } 
  }

  rename(item:any){
    item.open = false
    this.edit = true;
    this.openaddprojectModal();
    this.item = item;
    this.title = item.title;
  }

  create(){
    this.isLoading = true;
    if(!this.edit){
      const data = JSON.stringify({
        title : this.title,
        user:this.authS.currentUserValue.id
      });
      this.apiS.createProject(data).subscribe(res=>{
        if(res.status){
          this.isLoading = false;
          this.isModalOpen = !this.isModalOpen;
          this.getData();
          this.title = "";
          this.toastr.success("Created Successfully");
        }else{
          this.isLoading = false;
          this.toastr.error(res.message);
        }
      },error => {
        this.isLoading = false;
        this.toastr.error(error.message);
      })
    }else{
      const data = JSON.stringify({
        title : this.title
      });
      this.apiS.updateProject(data,this.item._id).subscribe(res=>{
        if(res.status){
          this.isLoading = false;
          this.isModalOpen = !this.isModalOpen;
          this.item.title = this.title;
          this.getData();
          this.title = "";
          this.edit = false;
          this.closeaddprojectModal();
          // this.toastr.success("Updated Successfully");
        }else{
          this.isLoading = false;
          this.toastr.error(res.message);
        }
      },error => {
        this.isLoading = false;
        this.toastr.error(error.message);
      })
    }
    
  }


  openaddprojectModal(){
    this.addprojectModal.show();
  }

  closeaddprojectModal(){
    this.addprojectModal.hide();
    this.edit = false;
    this.title = "";
  }
}
