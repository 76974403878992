<div class="">
  <div class="flex flex-col {{isModal?'mt-10 ':'pt-10'}}">
    <div class="flex-auto flex justify-center mb-2">
      <h3 class="flex-none text-xl tracking-wider text-white font-semibold">
        {{(!isResult)?'Side Margin Calculator':'Side Margin Calculator Result'}}</h3><br>

    </div>
    <!-- <div class="flex-auto flex justify-center mb-4">
        <p class="flex items-center text-xs tracking-wider text-white font-semibold leading-4">
          {{serviceDetails.category}}
          <i class="fa-solid fa-chevron-right mx-1"></i>
          {{serviceDetails.displayText}}
        </p>
    
      </div> -->

  </div>
  <div class=" flex-auto flex justify-center {{isModal?'m-10 mt-5':'h-screen'}}" *ngIf="!isResult">
    <div
      class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-6/12 h-4/6'}} overflow-auto rounded-3xl">
      <div class="m-5 h-4/5 overflow-auto">


        <div [class]="(currentStep !== 1)?'hidden':''">
          <div>
            <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">What is your plot area? <span
                    class="text-red-600">*</span></p>
                <div class="flex border rounded"
                  [class]="(isNext && plotArea == '')?'border-red-500':'border-gray-300'">
                  <div class="flex-1">
                    <input [(ngModel)]="plotArea" type="number" name="price" id="price"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="plotAreaUnit"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Sq. M.">Sq. M.</option>
                      <option value="Sq. Ft.">Sq. Ft.</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Locality</p>
                <div class="border border-gray-300 rounded">
                  <select [(ngModel)]="locality"
                    class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                    <option value="Congested">Congested</option>
                    <option value="Non congested">Non congested</option>
                  </select>
                </div>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Planning Authority</p>
                <div class="border border-gray-300 rounded">
                  <select [(ngModel)]="planningAuthority"
                    class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                    <option value="Mahanagar Palika">Mahanagar Palika</option>
                    <option value="Nagar Palika">Nagar Palika</option>
                    <option value="Nagar Panchayat">Nagar Panchayat</option>
                    <option value="Any Other Area">Any Other Area</option>
                  </select>
                </div>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Use of Building <span
                    class="text-red-600">*</span></p>
                <div class="border rounded"
                  [class]="(isNext && useOfBuilding == '')?'border-red-500':'border-gray-300'">
                  <select [(ngModel)]="useOfBuilding"
                    class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                    <option value="{{item.type}}" *ngFor="let item of useOfBuildings">{{item.type}}</option>
                  </select>
                </div>
              </div>
            </div>
            <hr class="my-3">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Height from Ground <span
                    class="text-red-600">*</span></p>
                <div class="flex border rounded"
                  [class]="(isNext && heightFromGround == '')?'border-red-500':'border-gray-300'">
                  <div class="flex-1">
                    <input [(ngModel)]="heightFromGround" type="number" name="price" id="price"
                      (keyup)="changeHeightFromGround($event)"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="heightFromGroundUnit" (change)="changeHeightFromGroundUnit($event)"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Meter">Meter</option>
                      <option value="Feet">Feet</option>
                    </select>
                  </div>
                </div>
                <p class="text-xs mt-0.5 text-yellow-400">Note: Including Parking Floors</p>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Height of Parking floors</p>
                <div class="flex border rounded">
                  <div class="flex-1">
                    <input [(ngModel)]="heightExclusiveParking" type="number" name="price" id="price"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                      (keyup)="changeHeightExclusive($event)">
                  </div>
                  <div>
                    <select [(ngModel)]="heightExclusiveParkingUnit" (change)="changeHeightExclusiveUnit($event)"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Meter">Meter</option>
                      <option value="Feet">Feet</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Height Excluding Parking Floors <span
                    class="text-red-600">*</span></p>

                <div class="flex border rounded"
                  [class]="(isNext && heightExcludingParking == '')?'border-red-500':'border-gray-300'">
                  <div class="flex-1">
                    <input [(ngModel)]="heightExcludingParking" type="number" name="price" id="price" disabled=""
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="heightExcludingParkingUnit" disabled=""
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Meter">Meter</option>
                      <option value="Feet">Feet</option>
                    </select>
                  </div>
                </div>
                <p class="text-xs mt-0.5 text-yellow-400">Note: Height from ground - Height of parking floors</p>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Maximum area of single Floor</p>
                <div class="flex border rounded">
                  <div class="flex-1">
                    <input [(ngModel)]="maxAreaSingleFloor" type="number" name="price" id="price"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="maxAreaSingleFloorUnit"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Sq. M.">Sq. M.</option>
                      <option value="Sq. Ft.">Sq. Ft.</option>
                    </select>
                  </div>
                </div>
                <p class="text-xs mt-0.5 text-yellow-400">Note: Must specify if floor area is more than 500 Sq.M. ( 5382
                  Sq.ft. ) on single floor.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div> -->
        <div [class]="(currentStep !== 2)?'hidden':''">
          <legend class="text-base mb-2 font-bold tracking-wide text-slate-700">Select all side plot boundaries</legend>
          <div>
            <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
              <div class="overflow-x-auto">
                <table class="min-w-full indent-0 border-inherit border-collapse ">
                  <tbody>
                    <tr class="">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Side 1 <span class="text-red-600">*</span></th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded"
                          [class]="(isNext && side1.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side1.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side1.adjPlot == 'Road' || side1.adjPlot == 'Reservation Land'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Sub Type</th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side1.adjPlot == 'Road'"
                          [class]="(isNext && side1.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side1.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side1.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side1.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side1.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                            <option value="Lake/Dam">Lake/Dam</option>
                            <option value="Ancient Monument">Ancient Monument</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side1.adjPlot == 'Road'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Road Width
                      </th>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side1.adjPlot == 'Road'"
                          [class]="(isNext && side1.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side1.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr>
                    <!-- <tr class="border-b-gray-300 border-b">
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        Side 2</td>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        <div class="border rounded"
                          [class]="(isNext && side2.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side2.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        <div class="border rounded" *ngIf="side2.adjPlot == 'Road'"
                          [class]="(isNext && side2.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side2.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side2.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side2.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side2.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                          </select>
                        </div>
                      </td>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side2.adjPlot == 'Road'"
                          [class]="(isNext && side2.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side2.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr>
                    <tr class="border-b-gray-300 border-b">
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        Side 3</td>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        <div class="border rounded"
                          [class]="(isNext && side3.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side3.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        <div class="border rounded" *ngIf="side3.adjPlot == 'Road'"
                          [class]="(isNext && side3.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side3.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side3.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side3.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side3.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                          </select>
                        </div>
                      </td>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side3.adjPlot == 'Road'"
                          [class]="(isNext && side3.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side3.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        Side 4</td>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        <div class="border rounded"
                          [class]="(isNext && side4.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side4.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                      <td
                        class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                        <div class="border rounded" *ngIf="side4.adjPlot == 'Road'"
                          [class]="(isNext && side4.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side4.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side4.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side4.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side4.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                          </select>
                        </div>
                      </td>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side4.adjPlot == 'Road'"
                          [class]="(isNext && side4.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side4.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
              <div class="overflow-x-auto">
                <table class="min-w-full indent-0 border-inherit border-collapse ">
                  <tbody>
                    <tr class="">
                      <th
                        class="py-2.5  w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Side 2 <span class="text-red-600">*</span></th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded"
                          [class]="(isNext && side2.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side2.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side2.adjPlot == 'Road' || side2.adjPlot == 'Reservation Land'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Sub Type</th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side2.adjPlot == 'Road'"
                          [class]="(isNext && side2.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side2.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side2.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side2.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side2.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                            <option value="Lake/Dam">Lake/Dam</option>
                            <option value="Ancient Monument">Ancient Monument</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side2.adjPlot == 'Road'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Road Width
                      </th>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side2.adjPlot == 'Road'"
                          [class]="(isNext && side2.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side2.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
              <div class="overflow-x-auto">
                <table class="min-w-full indent-0 border-inherit border-collapse ">
                  <tbody>
                    <tr class="">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Side 3 <span class="text-red-600">*</span></th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded"
                          [class]="(isNext && side3.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side3.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side3.adjPlot == 'Road' || side3.adjPlot == 'Reservation Land'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Sub Type</th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side3.adjPlot == 'Road'"
                          [class]="(isNext && side3.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side3.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side3.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side3.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side3.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                            <option value="Lake/Dam">Lake/Dam</option>
                            <option value="Ancient Monument">Ancient Monument</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side3.adjPlot == 'Road'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Road Width
                      </th>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side3.adjPlot == 'Road'"
                          [class]="(isNext && side3.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side3.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
              <div class="overflow-x-auto">
                <table class="min-w-full indent-0 border-inherit border-collapse ">
                  <tbody>
                    <tr class="">
                      <th
                        class="py-2.5 w-24  sm:w-40 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Side 4 <span class="text-red-600">*</span></th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded"
                          [class]="(isNext && side4.adjPlot == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side4.adjPlot"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Road">Road</option>
                            <option value="Other Property">Other Property</option>
                            <option value="Layout Open Space">Layout Open Space</option>
                            <option value="Reservation Land">Reservation Land</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side4.adjPlot == 'Road' || side4.adjPlot == 'Reservation Land'">
                      <th
                        class="py-2.5 w-24  sm:w-40  px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Sub Type</th>
                      <td colspan="2" class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side4.adjPlot == 'Road'"
                          [class]="(isNext && side4.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side4.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="DP Road">DP Road</option>
                            <option value="Other General Road">Other General Road</option>
                            <option value="Express Way">Express Way</option>
                            <option value="National Highway - NH">National Highway - NH</option>
                            <option value="State Highway - SH">State Highway - SH</option>
                            <option value="Major District Road - MDR">Major District Road - MDR</option>
                            <option value="Other District Road">Other District Road</option>
                            <option value="15M Village Road">15M Village Road</option>
                          </select>
                        </div>
                        <div class="border rounded" *ngIf="side4.adjPlot == 'Reservation Land'"
                          [class]="(isNext && side4.subType == '')?'border-red-500':'border-gray-300'">
                          <select [(ngModel)]="side4.subType"
                            class="block w-full h-[40px] rounded border-0 py-2 pl-2 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                            <option value="Airport Vicinity">Airport Vicinity</option>
                            <option value="Railway Boundary">Railway Boundary</option>
                            <option value="Forest Land">Forest Land</option>
                            <option value="Defense Land">Defense Land</option>
                            <option value="Bio Divercity Park -BDP">Bio Divercity Park -BDP</option>
                            <option value="Nallah">Nallah</option>
                            <option value="River Green Belt">River Green Belt</option>
                            <option value="River">River</option>
                            <option value="Prison Premises - Jail">Prison Premises - Jail</option>
                            <option value="High Tension Electric Lines">High Tension Electric Lines</option>
                            <option value="Lake/Dam">Lake/Dam</option>
                            <option value="Ancient Monument">Ancient Monument</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="side4.adjPlot == 'Road'">
                      <th
                        class="py-2.5 px-2 w-24  sm:w-40  text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        Road Width
                      </th>
                      <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                        <div class="border rounded" *ngIf="side4.adjPlot == 'Road'"
                          [class]="(isNext && side4.roadWidth == '')?'border-red-500':'border-gray-300'">
                          <input [(ngModel)]="side4.roadWidth" type="number" name="price" id="price"
                            class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep == 3 && !isProject && !isGoogleAuth">
          <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free quotes from
            partners</legend>
          <div class="w-full mt-3">
            <div class="grid w-full">
              <div class="relative rounded-md  mb-3">
                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheck($event)"
                  (input)="onMobileInput($event)" maxlength="10" pattern="[0-9]{10}" [(ngModel)]="mobile" name="mobile"
                  focuse="true" id="mobile"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
                <p class="text-slate-500 text-xs flex items-center mt-1">
                  <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                  <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy"
                      target="_blank">Privacy Policy</a></span>
                </p>

              </div>

              <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                <p class="text-sm text-left tracking-wide text-slate-700">Name</p>
                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="name" name="name" focuse="true" id="name"
                  [class]="(isNext && name == '')?'ring-red-500':''"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
              </div>

              <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                <p class="text-sm text-left tracking-wide text-slate-700">Email</p>
                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="email" name="name" focuse="true" id="name"
                  [class]="(isNext && email == '')?'ring-red-500':''"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
              </div>

              <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTP">
                <legend class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">Enter OTP
                </legend>
                <p class="text-slate-500 text-xs text-center mt-0">
                  <span class="ml-1">OTP sent on text sms</span>
                </p>
                <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}">
                </ng-otp-input>
              </div>
            </div>
            <div aria-label="Toast" class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
              *ngIf="error">
              <div class="p-4 rounded-md">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                      class="nu rw ayv">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="ms-3">
                    <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with
                      your submission</h3>
                    <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessage}}</p>
                  </div>
                </div>
              </div>
              <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearError()"
                *ngIf="errorCancelAble"><i class="fa-solid fa-xmark text-orange-600"></i></div>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep == 3 && !isProject && isGoogleAuth">
          <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free quotes from
            partners</legend>
          <div class="w-full mt-3">
            <div class="grid w-full">
              <div class="relative rounded-md  mb-3">
                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheckModal($event)"
                  (input)="onMobileInputModal($event)" maxlength="10" pattern="[0-9]{10}" [(ngModel)]="mobile"
                  name="mobile" focuse="true" id="mobile"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
                <p class="text-slate-500 text-xs flex items-center mt-1">
                  <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                  <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy"
                      target="_blank">Privacy Policy</a></span>
                </p>

              </div>

              <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTPModal">
                <legend class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">Enter OTP
                </legend>
                <p class="text-slate-500 text-xs text-center mt-0">
                  <span class="ml-1">OTP sent on text sms</span>
                </p>
                <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}">
                </ng-otp-input>
              </div>
            </div>
            <div aria-label="Toast" class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
              *ngIf="errorModal">
              <div class="p-4 rounded-md">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                      class="nu rw ayv">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="ms-3">
                    <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with
                      your submission</h3>
                    <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessageModal}}</p>
                  </div>
                </div>
              </div>
              <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearErrorModal()"
                *ngIf="errorCancelAbleModal"><i class="fa-solid fa-xmark text-orange-600"></i></div>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep == 3  && isProject">
          <legend class="text-sm text-left font-bold tracking-wide text-slate-700">Select Project</legend>
          <div class="mt-2 space-y-6 mb-2">
            <ng-container>
              <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-3">
                @if(projects.length > 0){
                <div *ngFor="let option of projects; let i = index" class="bg-[#F5F5F5] border rounded"
                  [class]="(isNext && !projectId)? 'border-red-500':'border-[#aaa]'"
                  [ngClass]="{'py-1 px-2': (option.editProjects), 'p-2': (!option.editProjects) }">
                  <div class="flex items-center gap-x-3" *ngIf="!option.editProjects">
                    <input [id]="i" type="radio" [checked]="option.isDefault" (change)="getProjectName($event,false)"
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      [(ngModel)]="projectId" [value]="option._id" [name]="'question' + i">

                    <label [for]="i" class="cursor-pointer block w-full text-sm font-medium leading-6 text-gray-900">{{
                      option.title }}</label>
                    <i class="fa-regular fa-pen-to-square" (click)="editProject(option,i)"></i>
                  </div>
                  <div class="flex" *ngIf="option.editProjects">
                    <input type="text" id="input{{i}}"
                      class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      [(ngModel)]="option.title" placeholder="Enter Project Name">
                    <span (click)="editProjectName(option)"
                      class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <i class="fa-solid fa-check" style="margin: auto; color: #4972ef; font-size: larger;"></i>
                    </span>
                  </div>
                </div>
                }
                <div>
                  <div class="flex items-center gap-x-3" *ngIf="!Projects">
                    <label
                      class="cursor-pointer block  text-sm font-medium leading-6 text-gray-900 bg-[#F5F5F5] rounded p-2 w-full text-center"
                      style="border: 1px solid blue;" (click)="AddProject()"><i class="fa-solid fa-plus me-2"></i>Add
                      Project</label>
                  </div>

                  <div class="grid grid-row-2 items-center gap-x-3 bg-[#F5F5F5] border rounded-lg"
                    [ngClass]="{'py-1 px-2': (Projects), 'p-2': (!Projects) }" *ngIf="Projects"
                    style="border: 1px solid gray;">
                    <div class="flex" *ngIf="Projects">
                      <input type="text"
                        class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        [(ngModel)]="newProjectName" placeholder="Enter Project Name">
                      <span (click)="createdNewProject()" *ngIf="!newProjectStatus"
                        class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <i class="fa-solid fa-check" style="margin: auto; color: #4972ef; font-size: larger;"></i>
                      </span>
                      <span *ngIf="newProjectStatus"
                        class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <div role="status">
                          <svg aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor" />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <p class="text-red-600 text-xs">You can also rename of projects from “All projects” tab in Menu bar</p>
          </div>
        </div>

      </div>


      <div class="absolute inset-x-0 bottom-0 bg-white">
        <div class="flex gap-x-2 mx-5 my-2">
          <button [disabled]="isLoading" *ngIf="currentStep != 1" (click)="backStep()"
            class="flex-none  w-1/6 p-1.5 rounded border-slate-400 bg-slate-200 hover:bg-slate-300 text-slate-700 text-center border"><i
              class="fa-solid fa-chevron-left"></i></button>
          <ng-container *ngIf="projectId">
            <button [disabled]="isLoading"
              (click)="currentStep == 1 ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3) ? sendOTP() : onSubmit()"
              [class]="(currentStep==0) ? 'w-full':'w-full'"
              class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
              <!-- <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
                <ng-container *ngIf="!isOTP && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP</ng-container>
                  <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP))">Submit</ng-container> -->

              <ng-container *ngIf="currentStep == 1">Next</ng-container>
              <ng-container *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3">Send
                OTP</ng-container>
              <ng-container
                *ngIf="!isOTP && !isOTPModal && userLoggedIn && !isProject && currentStep == 3">Submit</ng-container>
              <ng-container *ngIf="currentStep == 2">{{(!edit)?'Submit':'Update'}}</ng-container>
              <ng-container
                *ngIf="(isOTP || isOTPModal) && userLoggedIn && isProject && currentStep == 3">Submit</ng-container>
              <ng-container
                *ngIf="!isOTP && !isOTPModal && userLoggedIn && isProject&&  currentStep == 3">Submit</ng-container>
            </button>
          </ng-container>
          <ng-container *ngIf="!projectId">
            <button [disabled]="isLoading"
              (click)="currentStep == 1 ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3) ? sendOTP() : ((isOTP || isOTPModal || userLoggedIn) && !isProject && currentStep == 3) ? nextSlide() :onSubmit()"
              [class]="(currentStep==0) ? 'w-full':'w-full'"
              class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
              <!-- <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
                <ng-container *ngIf="!isOTP && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP</ng-container>
                  <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP) && isProject)">Submit</ng-container>
                <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP) && !isProject)">Next</ng-container> -->

              <ng-container *ngIf="currentStep == 1">Next</ng-container>
              <ng-container *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3">Send
                OTP</ng-container>
              <ng-container *ngIf="currentStep == 2 && ((userLoggedIn || isOTP || isOTPModal) && isProject)">
                {{(!edit)?'Submit':'Update'}}</ng-container>
              <ng-container
                *ngIf="currentStep == 2 && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next</ng-container>
              <ng-container
                *ngIf="currentStep == 2 && ((!userLoggedIn && !isOTP && !isOTPModal) && !isProject)">Next</ng-container>
              <ng-container
                *ngIf="currentStep == 3 && ((userLoggedIn || isOTP || isOTPModal) && isProject)">Submit</ng-container>
              <ng-container
                *ngIf="currentStep == 3 && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next</ng-container>
            </button>
          </ng-container>
          <h3 class="text-center my-3" *ngIf="!userLoggedIn && currentStep == 3 && !isGoogleAuth">OR</h3>
          <asl-google-signin-button type="standard" *ngIf="!userLoggedIn && currentStep == 3 && !isGoogleAuth"
            text="signin_with" logo_alignment="center" size='medium'></asl-google-signin-button>
          <!--  -->
        </div>
      </div>

    </div>

  </div>
  <div class="flex-auto flex justify-center {{isModal?'m-10 mt-5':'h-screen'}}" *ngIf="isResult">
    <div
      class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-6/12 h-4/6'}} overflow-auto rounded-3xl">
      <div class="m-5 h-4/5 overflow-auto">
        <div class="px-4 py-6">
          <div class="mt-3">
            <span class="tracking-wider text-lg font-semibold">Calculations</span>
            <button
              class="w-auto float-right text-sm px-1.5 rounded my-1 font-normal border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white text-center border"
              (click)="openCalculatorModal()">Edit</button>
            <button
              class="w-auto sm:float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-primary text-primary hover:bg-primary hover:text-white text-center border"
              (click)="sideDownload()">Download</button>
          </div>

          <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
            <div class="overflow-x-auto">
              <table class="min-w-full indent-0 border-inherit border-collapse ">
                <tbody>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      1</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Plot Area</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.plotArea}} {{selectedItem.plotAreaUnit}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      2</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Building Height From Ground</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.heightFromGround}} {{selectedItem.heightFromGroundUnit}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      3</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Height Excluding Parking floors</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.heightExcludingParking}} {{selectedItem.heightExcludingParkingUnit}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      4</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Use of Building</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.useOfBuilding}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      5</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Type of Building</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.typeOfBuilding}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      6</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Locality</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.locality}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      7</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Maximum Road Width</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.maximumRoadWidth}} Meter</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      8</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">Side Margins
                      to be left</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Side</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Adj Plot</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Description</td>
                    <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Side Margin
                    </td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Side 1</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.side1.adjPlot}}</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{(selectedItem.side1.adjPlot == 'Road' || selectedItem.side1.adjPlot == 'Reservation
                      Land')?selectedItem.side1.subType:''}} {{(selectedItem.side1.adjPlot ==
                      'Road')?selectedItem.side1.roadWidth+ ' Meter':''}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                      {{selectedItem.side1.sideMargin}} Meter</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Side 2</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.side2.adjPlot}}</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{(selectedItem.side2.adjPlot == 'Road' || selectedItem.side2.adjPlot == 'Reservation
                      Land')?selectedItem.side2.subType:''}} {{(selectedItem.side2.adjPlot ==
                      'Road')?selectedItem.side2.roadWidth+ ' Meter':''}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                      {{selectedItem.side2.sideMargin}} Meter</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Side 3</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.side3.adjPlot}}</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{(selectedItem.side3.adjPlot == 'Road' || selectedItem.side3.adjPlot == 'Reservation
                      Land')?selectedItem.side3.subType:''}} {{(selectedItem.side3.adjPlot ==
                      'Road')?selectedItem.side3.roadWidth+ ' Meter':''}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                      {{selectedItem.side3.sideMargin}} Meter</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Side 4</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.side4.adjPlot}}</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{(selectedItem.side4.adjPlot == 'Road' || selectedItem.side4.adjPlot == 'Reservation
                      Land')?selectedItem.side4.subType:''}} {{(selectedItem.side4.adjPlot ==
                      'Road')?selectedItem.side4.roadWidth+ ' Meter':''}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                      {{selectedItem.side4.sideMargin}} Meter</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="absolute inset-x-0 bottom-0 bg-white">
          <div class="text-center gap-x-2 mx-5 my-2 border-b">

            <button (click)="navigate()"
              class="w-auto py-1.5 px-3 rounded my-1 border-primary hover:bg-primary hover:text-white font-normal text-sm text-primary text-center border">
              Visit {{projectName}}</button>
            <button (click)="sideDownload()"
              class="w-auto py-1.5 px-3 me-2 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
              Download</button>

            <button (click)="openCalculatorModal()"
              class="w-auto py-1.5 px-3 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
              Edit</button>
            <!--  -->
          </div>
          <div class="text-center my-2">
            <p class="text-sm">Other Calculators</p>
            <div class="grid md:flex justify-evenly">
              <a class="text-primary cursor-pointer text-xs" [routerLink]="['/fsi-calculator']">FSI</a>
              <a class="text-primary cursor-pointer text-xs">Developement Charges</a>
              <a class="text-primary cursor-pointer text-xs">Water Requirement</a>
              <a class="text-primary cursor-pointer text-xs">TDR</a>
              <a class="text-primary cursor-pointer text-xs">Parking</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>