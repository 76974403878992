<div class="bg-white">
  <section class="relative container overflow-hidden ">
    <div class="">
      <div class="flex h-full">
        <div class="flex-1 grid">
          <div class="p-5 md:p-10">
            <h3 class="text-2xl text-[#0f0f0f] font-semibold">Get Everything you need for Construction</h3>

              <h3 class="text-lg text-[#545454] font-bold">What are you looking for?</h3>
              <div class="mt-2">
                <div class="relative flex">
                    <input type="text" (keyup)="onInputChange($event)"  [(ngModel)]="searchTerm"
                        class="items-stretch grow tracking-normal lg:tracking-wide md:tracking-wide rounded-lg border-0 py-2 ps-2 lg:py-2 lg:ps-4 md:py-2 md:ps-4 text-gray-900 ring-1 text-sm lg:text-md md:text-md ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                        placeholder="Search for Construction Services">
                    <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                  <div *ngIf="displayResult"
                    class="absolute bg-white w-full mt-8 md:mt-10 shadow-lg rounded-b-md p-2 max-h-60 overflow-auto">
                    <ul class="w-full">
                      <li class="my-1 cursor-pointer" *ngFor="let item of servicesData"
                        (click)="appC.openServiceRequestModal(item.url);displayResult = false;searchTerm = ''">
                        <span class="text-sm text-slate-400 ">{{item.category}}</span><br>
                        <span class="text-slate-600 font-semibold">{{item.title}}</span>
                        <hr class="my-1 bg-amber-200">
                      </li>
                      <li
                      (click)="appC.openaddserviceRequestModal();displayResult = false;searchTerm = ''">
                      <span class="text-sm text-slate-400 ">Your Service not listed? Click the button below to submit a new service request!</span><br>
                      <span class="text-slate-600 font-semibold" style="font-size: 20px;">Submit service request</span>
                      <hr class="my-1 bg-amber-200">
                    </li>
                    </ul>
                  </div>
                </div>
              </div>

            <div class="border p-5 rounded-xl mt-5"> 
              <div class="grid grid-cols-2 md:grid-cols-2 gap-y-5 gap-4 mt-4">
                <div class=" cursor-pointer border rounded-lg shadow-md" (click)="appC.openServiceRequestModal('')">
                  <div class="bg-[#f5f5f5] rounded-lg p-2">
                    <img src="assets/icons/businessman.png" alt="Hire Professional" width="65px" class="block" style="margin: 0 auto;">
                  </div>
                  <p class=" font-light text-sm my-1 mx-2"><b>Hire Professional</b><br><span class="text-xs font-light">
                    Architect, Contractor, Fire Noc & More</span></p>
                </div>
                <div class=" cursor-pointer border rounded-lg shadow-md" (click)="appC.openCalculatorModal();">
                  <div class="bg-[#f5f5f5] rounded-lg p-2">
                    <img src="assets/icons/calculator.png" alt="Foot2Feet Calculator" width="65px" class="block" style="margin: 0 auto;">
                  </div>
                  <p class=" font-light text-sm my-1 mx-2"><b>Calculators</b><br><span class="text-xs font-light">
                    FSI, Side Margin, Parking & more </span></p>
                </div>
                <div class=" cursor-pointer border rounded-lg shadow-md" [routerLink]="['/questions']">
                  <div class="bg-[#f5f5f5] rounded-lg p-2">
                    <img src="assets/images/question.png" alt="Ask a Question" width="65px" class="block" style="margin: 0 auto;">
                  </div>
                  <p class=" font-light text-sm my-1 mx-2"><b>Questions & Answers</b><br><span class="text-xs font-light">How
                      to remove material from a height? & more</span></p>
                </div>
                <div class=" cursor-pointer border rounded-lg shadow-md" (click)="appC.openQuicklinksmodal();">
                  <div class="bg-[#f5f5f5] rounded-lg p-2">
                    <img src="assets/icons/link.png" alt="Quick Links" width="65px" class="block" style="margin: 0 auto;">
                  </div>
                  <p class=" font-light text-sm my-1 mx-2"><b>Quick Links</b><br><span class="text-xs font-light">Online
                      7/12, Ready Reckoner Rates</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 hidden md:grid lg:grid place-content-center justify-items-center">
          <img alt="Hero Image"
            [src]="'assets/images/architectVector.webp'" width="100%" height="100%">
        </div>
      </div>
    </div>


  </section>

  <section class="py-10 px-2 md:px-10 container">

    <div *ngFor="let categoryGroup of groupedServices;let groupIndex = index">

      <h3 class="text-xl md:text-3xl font-semibold tracking-wider text-[#0f0f0f]">{{ categoryGroup.key }}</h3>

      <div class="relative">
        <div class="overflow-hidden scroll-smooth relative">
          <div #categoryCardContainer
            class="categoryCardContainer{{groupIndex}} flex gap-3 md:gap-2 lg:gap-2 xl:gap-2 mx-1 lg:mx-0 pt-2 md:pt-5">
            <div class="border shadow relative mb-5 rounded-lg w-5/12 md:w-1/3 lg:w-1/4 xl:w-1/5 cursor-pointer flex-shrink-0 categoryCard{{groupIndex}}"
              *ngFor="let service of categoryGroup.value" (click)="appC.openServiceRequestModal(service.url)">
              <img [alt]="service.title" [defaultImage]="defaultImage"
                [lazyLoad]="baseUrl+'/file/retrieve/'+service.largeIcon"
                class="h-20 md:h-24 lg:h-28 object-center rounded-lg border-b " width="100%">
              <div class="px-1 py-1">
                <div class="min-h-36 md:min-h-[7rem]">
                  <h4 class="text-base font-semibold tracking-wide">{{service.title}}</h4>
                  <p class="text-sm tracking-wider font-light leading-normal text-slate-700">{{service.expertCount}} Professional</p>
                </div>
              </div>
              <div class=" absolute bottom-0 w-full px-2">
                <button routerLinkActive="router-link-active" 
                  class="w-full p-1.5 rounded my-1 border-primary hover:bg-primary hover:text-white font-normal text-sm text-primary text-center border">
                Get Quotes</button>
              </div>
            </div>

          </div>
        </div>
        <button [ariaLabel]="'scroll-back-2'+groupIndex"
          class="absolute top-1/3 -left-2 md:-left-6 transform border rounded-full bg-white -translate-y-1/2  p-2 text-gray-700 disabled:text-gray-200"
          *ngIf="this.groupedServices[groupIndex].currentCategoryCardIndex != 0"
          (click)="scrollCategoryToPreviousCard(groupIndex)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <button [ariaLabel]="'scroll-next-2'+groupIndex"
          class="absolute top-1/3 -right-2 md:-right-6 border rounded-full bg-white transform -translate-y-1/2  p-2 text-gray-700  disabled:text-gray-200"
          *ngIf="this.groupedServices[groupIndex].currentCategoryCardIndex < (categoryGroup.value.length - 1)"
          (click)="scrollCategoryToNextCard(groupIndex)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
    <p class="text-center mt-4 tracking-normal lg:tracking-wider md:tracking-wider">Your Service not listed? Click the button below to submit a new service request!</p>
    <div class="flex justify-center">
      <button (click)="appC.openaddserviceRequestModal()"
      class="flex auto mt-3 py-2 px-8 text-sm font-bold tracking-wide rounded-lg bg-primary hover:bg-blue-700 text-white text-center border border-transparent focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-300">Submit Service Request</button>
    </div>
  </section>

  <section class="py-2 container">
    <div class="">
      <h3 class="text-2xl text-center font-bold leading-normal">Ask anything related to Land and Construction.</h3>
      <p class="text-center mt-4 tracking-normal lg:tracking-wider md:tracking-wider">Foot2Feet will help you to solve it.</p>
    </div>

    <div class="mt-5 grid lg:grid lg:grid-cols-4 md:grid-cols-2 md:gap-5 lg:gap-5 xl:gap-5 mx-1 pt-4 md:pt-14 gap-y-3">
      <div *ngFor="let item of questionData" (click)="openQuestion(item.title)">
        <div class="bg-[#f5f5f5] rounded-xl h-full cursor-pointer relative">
          
            <div class="p-5">
              <h5 class="font-semibold">{{item.title}}</h5>
              <p class="text-sm truncate-five-lines">{{item.description}}</p>
              <span class="font-semibold text-sm text-primary">Read More..</span>
            </div>
          
        </div>
      </div>
    </div>
  </section>
  

  
  <div class="flex justify-center">
    <button [routerLink]="['/questions']" routerLinkActive="router-link-active" [ariaLabel]="'Ask a Question'" 
      class="flex auto mt-6 py-4 font-semibold px-7 tracking-wider rounded bg-primary hover:bg-blue-700 bg-primary text-white text-center border">Ask 
      a Question</button>
  </div>


  <section class="container py-10">
    <div class="mt-10">
      <div class="relative mt-10">
        <div class="flex flex-col md:flex-row lg:flex-row xl-flex-row h-full">
          <div class="flex-1 py-6 sm:py-10 bg-primary">
            <div class="p-6 sm:p-10">
              <h3 class="text-lg sm:text-2xl text-white font-bold text-center">Foot2Feet Calculators </h3>
              <p class="text-center mt-2 text-white tracking-normal lg:tracking-wider md:tracking-wider">More accuracy
                with minimum inputs.
              </p>
              <ul role="list" class="divide-y divide-gray-100 mx-10 mt-8">
                <li class="flex justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg cursor-pointer"
                  [routerLink]="['/fsi-calculator']">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">FSI Calculators</p>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p class="text-sm leading-6 text-gray-900 px-3"><i class="fa-solid fa-chevron-right"></i></p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg cursor-pointer"
                  [routerLink]="['/side-margin-calculator']">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">Side Margin Calculator</p>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p class="text-sm leading-6 text-gray-900 px-3"><i class="fa-solid fa-chevron-right"></i></p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg cursor-pointer">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">Development Charges Calculator</p>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p class="text-sm leading-6 text-gray-900 px-3"><i class="fa-solid fa-chevron-right"></i></p>
                  </div>
                </li>
                <li class="flex justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg cursor-pointer">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">Parking Requirement Calculator</p>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p class="text-sm leading-6 text-gray-900 px-3"><i class="fa-solid fa-chevron-right"></i></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex-1 py-6 sm:py-10 bg-[#F5F5F5]">
            <div class="p-6 sm:p-10">
              <h3 class="text-lg sm:text-2xl text-primary font-bold text-center">Useful Links</h3>
              <p class="text-center mt-2 text-primary tracking-normal lg:tracking-wider md:tracking-wider">Useful
                information at one click</p>

              <ul role="list" class="divide-y divide-gray-100 mx-10 mt-8">
                <li class="flex cursor-pointer justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg"
                  (click)="openLink(' https://bhulekh.mahabhumi.gov.in/')">
                  <div>
                    <div class="flex gap-x-1">

                      <div class="flex-shrink-0 w-full">
                        <p class="text-sm leading-6 text-gray-900 px-3">Online 7/12</p>
                      </div>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p
                      class="text-sm leading-6 text-gray-900 px-3"><i
                        class="fa-solid fa-arrow-up-right-from-square"></i>
                    </p>
                  </div>
                </li>
                <li class="flex cursor-pointer justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg"
                  (click)="openLink('https://easr.igrmaharashtra.gov.in/')">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">Ready Reckoner Rates</p>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p
                      class="text-sm leading-6 text-gray-900 px-3"><i
                        class="fa-solid fa-arrow-up-right-from-square"></i></p>
                  </div>
                </li>
                <li class="flex  cursor-pointer justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg"
                  (click)="openLink('https://pmc.gov.in/en/development-plan-merged-23-villages-0')">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">Development Plans - PMC</p>
                    </div>
                  </div>
                  <div class="grid content-center lg:flex lg:flex-col lg:items-end">
                    <p
                      class="text-sm leading-6 text-gray-900 px-3"><i
                        class="fa-solid fa-arrow-up-right-from-square"></i></p>
                  </div>
                </li>
                <li class="flex cursor-pointer justify-between gap-x-6 py-2 sm:py-3 my-2 sm:my-3 bg-white rounded-lg"
                  (click)="openLink(' https://www.pcmcindia.gov.in/TP_info.php')">
                  <div class="flex gap-x-1">
                    <div class="flex-shrink-0 w-full">
                      <p class="text-sm leading-6 text-gray-900 px-3">Development Plans - PCMC</p>
                    </div>
                  </div>
                  <div class=" grid content-center lg:flex lg:flex-col lg:items-end">
                    <p
                      class="text-sm leading-6 text-gray-900 px-3"><i
                        class="fa-solid fa-arrow-up-right-from-square"></i></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-10 container">
    <div class="mt-10">
      <h3 class="text-2xl text-center font-bold leading-normal">WE'VE GOT IT BLOGGED</h3>
      <p class="text-center mt-4 tracking-normal lg:tracking-wider md:tracking-wider">Your Best Practices Guide for all your service needs</p>
    </div>
    <div class="mt-5 grid lg:grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-5 lg:gap-5 xl:gap-5 mx-1 pt-4 md:pt-14 gap-y-3">
      <div *ngFor="let item of blogsData" (click)="openBlog(item)">
        <div class="flex flex-col h-[24rem] bg-[#f5f5f5] rounded-xl cursor-pointer border shadow">
          <img alt="Blog 1" onerror="this.src='assets/logo.png'" [src]="baseUrl+'/file/retrieve/'+item.thumbnail" class="h-32 m-5">
          <div class="m-5 flex flex-col justify-between">
            <div>
              <h5 class="text-sm font-semibold truncate-two-lines">{{item.title}}</h5>
              <div class="text-sm leading-normal truncate-five-lines" [innerHTML]="item.content"></div>
              <span class="font-semibold text-sm text-primary">Read More..</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="flex justify-center">
    <button [routerLink]="['/blog']" routerLinkActive="router-link-active" [ariaLabel]="'View All Blogs'" class="flex auto  py-4 font-semibold px-7 tracking-wider rounded bg-primary hover:bg-blue-700 bg-primary text-white text-center border">View all Blogs</button>
  </div>
  



  <section class="py-10  container">
    <div class="mt-10">
      <h3 class="text-2xl text-center font-bold leading-normal">Customer Review</h3>
      <p class="text-center mt-4 tracking-normal lg:tracking-wider md:tracking-wider"></p>
    </div>
    <div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
        <div class="bg-[#F5F5F5] flex-shrink-0 rounded-xl m-5 ">
          <div class="m-5">
            <h5 class="font-bold text-center text-lg">Time Saver !</h5>
            <p class="text-sm justify-center mt-1"> Every construction site is unique needs specific set of NOC's from various departments. & different. 
              That's why we rely on foot2feet as domain expert in this field. This enables us to remain focused on our core job. </p>
            <div class="flex">
              <div class="mt-4">
                <div class="w-auto rounded-lg">
                  <img src="/assets/images/teacher2.jpg" class="rounded-full" alt="" width="40px" />
                </div>
              </div>
              <div class="flex-1 m-2">
                <h3 class="text-base font-bold m-1">Jignesh Shah!
                  <p class="text-xs m-0">Builder & Developer</p>
                </h3>

              </div>
            </div>
          </div>
        </div>
        <div class="bg-[#F5F5F5] flex-shrink-0 rounded-xl m-5 ">
          <div class="m-5">
            <h5 class="font-bold text-center text-lg">Great Services !</h5>
            <p class="text-sm justify-center mt-1"> I had ancestral plot of land on outskirts of Pune. After my father's death I wanted to transfer it in my name.
               Foot2feet helped me to get it on my name. I had to visit Pune only once, rest the job was done by them.</p>
            <div class="flex">
              <div class="mt-4">
                <div class="w-auto rounded">
                  <img src="/assets/images/teacther1.png" class="rounded-full" alt="" width="40px" />
                </div>
              </div>
              <div class="flex-1 m-2">
                <h3 class="text-base font-bold m-1">Menna Joshi
                  <p class="text-xs m-0">Mumbai</p>
                </h3>

              </div>
            </div>
          </div>
        </div>
        <div class="bg-[#F5F5F5] flex-shrink-0 rounded-xl m-5 ">
          <div class="m-5">
            <h5 class="font-bold text-center text-lg">Cost Effective !</h5>
            <p class="text-sm justify-center mt-1"> To arrive a best costing we routinely hire contractors on One Place Platform in Construction - Foot2Feet.com  on project to project basis. 
              It is more cost effective & efficient than hire individual specialist. We recommend it to every builder & developer.<p>
            <div class="flex">
              <div class="mt-4">
                <div class="w-auto">
                  <img src="/assets/images/avv-patil.png" alt="" width="40px" />
                </div>
              </div>
              <div class="flex-1 m-2">
                <h3 class="text-base font-bold m-1">Ajay Patil!
                  <p class="text-xs">Architect</p>
                </h3>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- FAQ Sections -->
  <section class="py-10  container">
    <div class="mt-5">
      <h3 class="text-2xl text-center font-bold leading-normal">More about Foot2Feet Module</h3>
      <p class="text-center mt-4 tracking-normal lg:tracking-wider md:tracking-wider"></p>
    </div>
    <div id="accordion-collapse" data-accordion="collapse" class="w-full md:w-1/2 " style="margin:0 auto;">
      <h2 id="accordion-collapse-heading-1">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
          aria-controls="accordion-collapse-body-1">
          <span>How foot2feet works</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <h2 class="mb-2 text-gray-500 dark:text-gray-400 font-bold">Foot2Feet services are devide in 2 categories.
          </h2>
          <ul>
            <li class="m-2">
              <h2 class=" text-lg text-gray-500">1. Assured Services by foot2Feet team </h2>
              <p class="">Services where we can maintain speed & quality at affordable rates, we take full
                responsibility. Our own in house team will coordinate with you from start to end. </p>
            </li>
            <li class="m-2">
              <h2 class=" text-lg text-gray-500">2. Services provided by Foot2Feet registered professionals –</h2>
              <p>Maintaining speed & quality for some locations is a challenge. In such case we send your enquiry to our
                verified professional. They will coordinate with you. <br>
                Here our support team will be with you but the concerned service provider will be responsible for
                service delivery.</p>
            </li>
          </ul>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-2">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
          aria-controls="accordion-collapse-body-2">
          <span>Steps to hire professional on foot2feet ?</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <h2 class="mb-2 text-gray-500 dark:text-gray-400 font-bold">Here are steps to find expert professionals for
            construction related services.</h2>
          <ul>
            <li class="m-2">
              <h2 class=" text-lg text-gray-500">Step 1. Select appropriate service</h2>
              <p class="">Open out list of all services & Select the service matching to your requirement.</p>
            </li>
            <li class="m-2">
              <h2 class=" text-lg text-gray-500">Step 2. Fill the enquiry form</h2>
              <p>Fill maximum details in form so it’s easy for us to understand your requirement.</p>
            </li>
            <li class="m-2">
              <h2 class=" text-lg text-gray-500">Step 3. Get verification call</h2>
              <p class="">Get a call from our executive to verify your requirement.</p>
            </li>
            <li class="m-2">
              <h2 class=" text-lg text-gray-500">Step 4. Get quotation</h2>
              <p>
                After verification we will send you quotation based on your requirement. <br>
                If we are not able to deliver for that location, we will forward your enquiry to our registered
                professionals.</p>
            </li>

          </ul>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-3">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
          aria-controls="accordion-collapse-body-3">
          <span>Why sometimes you send my enquiry to other registered professionals ?</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <h2 class="mb-2 text-gray-500 dark:text-gray-400 font-bold">Foot2Feet services are devide in 2 categories.
          </h2>
          <ul>
            <li class="m-2">
              <p class="">As explained in first question, services & location for which we can not maintain quality with
                speed by our own team, we send send it to our registered professionals. </p>
            </li>
          </ul>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-4">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-4" aria-expanded="false"
          aria-controls="accordion-collapse-body-3">
          <span>Which all services are provided by foot2feet ?</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <h2 class="mb-2 text-gray-500 dark:text-gray-400 font-bold">At foot2feet you will get free quotation for land
            & construction related services.
          </h2>
          <ul>
            <li class="m-2">
              <p class="text-gray-500">Eg – Name change on 7-12, Ferfar, remove or add name from Property card, 7-12
                name change, non
                agriculture NOC, NA order, Private land survey, Government Mojani, Zone Certificate, Tax NOC, Building
                Permission, Change of Use, Water NOC, Drainage NOC, Fire NOC, Garden NOC, Estimation , Valuation,
                Aviation NOC, aviation NOC, Tree Cutting, Forest NOC, Heritage NOC, Vermiculture NOC, Road Handover,
                Generate TDR, TDR utilization, Structural Designer, RCC consultant in pune, Solar NOC, Liaisoning
                architect, Designing architect, Sanctioning architect, Title & search report, tabeyadi, Advocate,
                Plumbing consultant, MEP Consultant, Contractor, Environment NOC, RERA Consultant, Redevelopment
                consultant, construction Feasibility report, PreDcr drawings, home drawing plan services, 3d view &
                walkthrough consultant </p>
            </li>
          </ul>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-5">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-5" aria-expanded="false"
          aria-controls="accordion-collapse-body-5">
          <span>What is smart Calculator ?</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <ul>
            <li class="m-2">
              <p class="text-gray-500">Smart calculators on foot2feet automatically Calculates various information based
                on thumb rules. Like as building rules, construction cost, water tank capacity erc.
                <br>These calculators are free to use for anyone.</p>
            </li>
          </ul>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-6">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-6" aria-expanded="false"
          aria-controls="accordion-collapse-body-6">
          <span>Which all calculators you provide ?</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <ul>
            <li class="m-2">
              <p class="text-gray-500">By this feature you can calculate FSI, floor space index for any plot in Pune,
                FSI calculation for building in pune, mumbai, thane, nasik, Maharashtra, PCMC, PMRDA, built up area
                calculation formula for construction pune, side margin, open space, Amenity space, MHADA area, TDR, paid
                fsi, FUNGIBLE FSI, development charges, PMRDA upkar charges, security deposit, labour cess, balcony
                charges, terrace challan in PCMC, required car, scooter parking, cycle parking size in Pune municipal
                corporation, Parking requirement calculator, permissible building height, planning authority of any
                location, etc….</p>
            </li>
          </ul>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-7">
        <button type="button"
          class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-7" aria-expanded="false"
          aria-controls="accordion-collapse-body-7">
          <span>Quick links & its use</span>
          <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <ul>
            <li class="m-2">
              <p class="text-gray-500">You can find various useful websites like government land rates, City development
                plans, online 7/12, etc..</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-primary py-10 px-1 md:px-10 lg:px-10 xl:px-10">
    <div class="block md:flex lg:flex xl:flex container">
      <div class="flex-none">
        <div class="grid justify-items-center">
          <img alt="Partner App Download" [defaultImage]="defaultImage" lazyLoad="assets/images/handshake.webp"
            width="125px" height="96px">
        </div>
      </div>
      <div class="flex-auto">
        <div class="px-5 pt-3">
          <h3 class="text-white font-bold tracking-wider text-lg">Join Foot2Feet & Start Earning Right Away</h3>
          <p class="text-sm text-white tracking-wider">NO REGISTRATION CHARGES. GET 300 CREDITS FREE</p>
        </div>
      </div>
      <div class="flex-auto ">
        <div class="grid justify-items-center pt-3">
          <button [ariaLabel]="'Download Partner App'" (click)="appC.openpartnerappModal()"
            class="mt-4 px-10 py-2 rounded border-white tracking-wider text-white text-center border">Download Partner
            App</button>
        </div>
      </div>
    </div>
  </section>
</div>



<app-fab-button></app-fab-button>
