import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { Services } from '../core/models/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from '../core/services/api.service';
import { SeoService } from '../core/services/seo.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { FabButtonComponent } from '../shared/fab-button/fab-button.component';
import { ServiceRequestComponent } from '../shared/service-request/service-request.component';
import { CommonModule, DatePipe, isPlatformBrowser } from '@angular/common';
 
import { Modal, initModals } from 'flowbite';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-standalone-services',
  standalone: true,
  imports:[SharedModule, ServiceRequestComponent, RouterModule, DatePipe, CommonModule],
  templateUrl: './standalone-services.component.html',
  styleUrls: ['./standalone-services.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
      ])
    ]),
    trigger('scrollAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class StandaloneServicesComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  isModalOpen = false;
  directSendProposal = false;
  vendorId = "";
  service:any = ""
  serviceDetails:any = [];
  questions: any = []; 
  form!: FormGroup;
  totalQuestionCount = 0;
  stepForm = 0;
  currentStep = 0;

  page = 0;
  totalPage = 0;
  totalItems = 0;
  limit = 10;
  
  vendorData:any = [];
  blogs:any = [];
  todayDate = Date.now();
  baseUrl = environment.baseURL;
  defaultImage = 'assets/images/user.webp';

  popupModal:any;
 targetpopupModal:any;
 selectedPortfolio:any = "";

 servicesData:any = [];
  constructor(private renderer: Renderer2, private formBuilder: FormBuilder, private route: ActivatedRoute,private router: Router, private api: ApiService, private seoService: SeoService, private titleService: Title, private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object) {

  }

  async ngOnInit() {
    this.route.params.subscribe((res:any)=>{
      
      this.service = res.serviceTitle;
    });

    if (isPlatformBrowser(this.platformId)) {
          const messagingElement = document.querySelector('.messaging');
        if (messagingElement) {
          messagingElement.classList.remove('hidden');
        }
      initModals();
      this.targetpopupModal =  document.getElementById('popup-modal');
      this.popupModal = new Modal(this.targetpopupModal);
    }
    let s = await this.api.getService({url:this.service},1,100,"").toPromise();
    
    if(s.data.length == 0){
      this.router.navigate(['/404']);
      return;
    }
    this.serviceDetails = s.data[0];
    this.questions = s.data[0].questions;
    this.totalQuestionCount = this.questions.length;
    this.form = this.formBuilder.group({});
    this.generateForm();
    
    // SEO Details
    // this.titleService.setTitle(this.seoService.getServicesTitle(this.serviceDetails?.id));
    // this.metaService.addTags(this.seoService.getServicesSEOMetaData(this.serviceDetails?.id));
    // SEO Details

    this._fetchData();
  }

  _fetchData(){
    this.api.getUser({role:'vendor',"services.id":this.serviceDetails._id},this.page,this.limit,"").subscribe(res=>{
      this.vendorData = res.data;
      this.page = res.page;
      this.totalPage = (res.totalPages == 0)?1:res.totalPages;
      this.totalItems = res.totalItems;
      this.vendorData.map(async (d:any)=>{
        d.hiredCount = await this.getHiredCount(d._id);
      })
    })
    this.api.getBlog({},1,1000,"").subscribe(res => {
      this.blogs = this.getRandomObjectsFromArray(res.data, 5);
    })
  }

  viewMore(){
    this.limit += 10;
    this._fetchData();
  }

  // Function to shuffle an array using Fisher-Yates algorithm
  shuffleArray(array:any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Function to get random 5 objects from the array
  getRandomObjectsFromArray(array:any, count:any) {
    const shuffledArray = this.shuffleArray(array);
    return shuffledArray.slice(0, count);
  }

  openBlog(item:any){
    var convertedStr = item.title.replace(/\s/g, "-");

    this.router.navigate(['/blog/'+convertedStr]);
  }

  async getHiredCount(partnerId:any){
    const response = await this.api.getProposal({ partner: partnerId, isHired: true }, 1, 50, "").toPromise();
    const count = response.totalItems;
    return count;
  }

  generateForm() {
    this.questions.forEach((_question: any, index: any) => {
      const control = this.formBuilder.control('');
      
      this.form.addControl(`question${index}`, control);
    });
  }

  onSubmit() {
    
  }

  readmore(event: MouseEvent, index: number) {
    const target = event.target as HTMLElement;
    const textContainer = document.querySelector(`.textContainer${index}`) as HTMLElement;
  
    if (textContainer.classList.contains('truncate-two-lines')) {
      textContainer.classList.remove('truncate-two-lines');
      target.textContent = 'Read less';
    } else {
      textContainer.classList.add('truncate-two-lines');
      target.textContent = 'Read more';
    }
  }

  openpopupModal(item:any){
    this.selectedPortfolio=item;
    this.popupModal.show();
  }

  closepopupModal(){
    this.popupModal.hide();
  }
  
  nextStep() {
    if (this.currentStep < this.questions.length) {
      this.currentStep++;
    } else {
      // Handle form submission or completion
      this.onSubmit();
    }
    
  }

  backStep() {
    this.currentStep--;
  }

  scrollToTop(id:any) {
    this.vendorId = id;
    this.directSendProposal = true;
    const scrollOptions: ScrollToOptions = { top: 0, behavior: 'smooth' };
  this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
  this.renderer.setProperty(document.body, 'scrollTop', 0);
  window.scrollTo(scrollOptions);
  }
  
}
 