import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ApiService
} from '../core/services/api.service';
import {
  AuthService
} from '../core/services/auth.service';
import {
  Services
} from '../core/models/services';
import {
  Router,
  RouterModule
} from '@angular/router';
import {
  Meta,
  Title
} from '@angular/platform-browser';
import {
  SeoService
} from '../core/services/seo.service';
import {
  AppComponent
} from '../app.component';

import {
  initDropdowns,
  initAccordions
} from 'flowbite';
import {
  CommonModule,
  DOCUMENT,
  isPlatformBrowser
} from '@angular/common';
import {
  environment
} from '../../environments/environment';
//  
import { DataService } from '../core/services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  @ViewChild('heroCardContainer') heroCardContainer!: ElementRef;
  @ViewChild('categoryCardContainer') categoryCardContainer!: ElementRef;
  private currentHeroCardIndex = 0;
  private currentCategoryCardIndex = 0;
  defaultImage = 'assets/logo.png';

  servicesData: any = [];
  Element: any;
  searchTerm = "";
  displayResult = false;
  // isAppDownloadModal = false;
  // isModalOpen = false;
  // newServiceRequest = "";
  smallScreen = false;


  expertCount: any;
  questionData: any = [];
  blogsData: any = [];
  groupedServices: any = [];
  public currentIndex = 0;
  slides: any | HTMLElement;
  totalSlide: any | HTMLElement;
  totalSlides: any = 0;
  public intervalTime = 3000;
  slideInterval: any;
  baseUrl = environment.baseURL;

  constructor(private api: ApiService, public auth: AuthService, private router: Router, private metaService: Meta, private seoService: SeoService, private titleService: Title, public appC: AppComponent,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object, public dataService: DataService) {
    this.titleService.setTitle(seoService.getTitle("home"));
    this.metaService.addTags(seoService.getSEOMetaData("home"));

  }

  async ngOnInit(): Promise<void> {

    if (isPlatformBrowser(this.platformId)) {
      const messagingElement = document.querySelector('.messaging');
      if (messagingElement) {
        messagingElement.classList.remove('hidden');
      }
      // Initialize Flowbite components here
      initDropdowns();
      initAccordions();
    }


    await this.dataService.getServicesByCategory()
    this.groupedServices = this.dataService.servicesByCategory;
    // this.groupedServices = await this.groupServicesByCategory();
    // this.groupedServices.sort((a:any, b:any) => a.key.localeCompare(b.key));


    this.api.getBlog({}, 1, 4, "").subscribe(res => {
      this.blogsData = res.data;
    });

    this.api.getQuestion({}, 1, 4, "", {}).subscribe(res => {
      this.questionData = res.data;
    })
  }


  async onInputChange(event: any) {
    this.displayResult = !!this.searchTerm; // Set displayResult to true if searchTerm is not null or empty
    if (!!this.searchTerm) {
      await this.dataService.getServices();
      this.servicesData = this.dataService.services.data;
      this.servicesData = this.servicesData.filter((res: any) => { return res.title.toLowerCase().includes(event.target.value) });
    } else {

    }
  }





  async groupServicesByCategory(): Promise<any[]> {
    const grouped: any = {};
    for (const service of this.servicesData) {
      const category = service.category;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(service);
      this.api.getVendorServicesCount(service._id).subscribe((cdata: any) => {
        service.expertCount = cdata.data.totalCount;
      })
    }
    return Object.entries(grouped).map(([key, value]: any) => ({
      key,
      "currentCategoryCardIndex": 0,
      value: value.sort((a: any, b: any) => a.title.localeCompare(b.title))
    }));
  }

  // openPostService(serviceName:string){
  //   // this.isModalOpen = true;
  //   this.newServiceRequest = serviceName;
  // }


  scrollHeroToNextCard(): void {
    const cards: HTMLCollection = this.document.getElementsByClassName('heroCard');
    if (this.currentHeroCardIndex < cards.length - 1) {
      this.currentHeroCardIndex++;
      cards[this.currentHeroCardIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }

  scrollHeroToPreviousCard(): void {
    const cards: HTMLCollection = this.document.getElementsByClassName('heroCard');
    if (this.currentHeroCardIndex > 0) {
      this.currentHeroCardIndex--;
      cards[this.currentHeroCardIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }

  scrollCategoryToNextCard(groupIndex: number): void {
    const cards: HTMLCollection = this.document.getElementsByClassName('categoryCard' + groupIndex);

    if (this.groupedServices[groupIndex].currentCategoryCardIndex < cards.length - 1) {
      this.groupedServices[groupIndex].currentCategoryCardIndex++;
      cards[this.groupedServices[groupIndex].currentCategoryCardIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }

  scrollCategoryToPreviousCard(groupIndex: number): void {
    const cards: HTMLCollection = this.document.getElementsByClassName('categoryCard' + groupIndex);
    if (this.groupedServices[groupIndex].currentCategoryCardIndex > 0) {
      this.groupedServices[groupIndex].currentCategoryCardIndex--;
      cards[this.groupedServices[groupIndex].currentCategoryCardIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }

  openBlog(item: any) {
    var convertedStr = item.title.replace(/\s/g, "-");

    this.router.navigate(['/blog/' + convertedStr]);
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  openQuestion(title: any) {
    var convertedStr = title.replace(/\s/g, "-");

    this.router.navigate(['/questions/' + convertedStr]);
  }


}
