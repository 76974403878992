<div class="bg-[#F1F2F2]">
  <main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="flex items-center pb-6 pt-3">
      <h1 class="text-3xl font-bold tracking-tight text-gray-700">{{projectTitle}}</h1>

    </div>

    <section aria-label="Services" class="my-2">
      <div class="flex items-center pb-3 pt-1">
        <h1 class="text-xl font-semibold tracking-wide text-gray-600">Services</h1>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        <div class="relative inline-block bg-white p-3 rounded-xl shadow-md"
          *ngFor="let item of services;let i = index">
          <div class="flex w-full">
            <div class="flex-none cursor-pointer" (click)="openHire(item)">
              <img *ngIf="item.status == 'Open'" class="rounded w-16 h-16"
                src="{{baseURL}}/file/retrieve/{{item.service.smallIcon}}">
              <img *ngIf="item.status != 'Open' && item.isHired" class="rounded w-16 h-16"
                onerror="this.src='assets/images/user.webp'"
                src="{{baseURL}}/file/retrieve/{{item.hireProposalPartner[0]?.photo}}">
            </div>
            <div class="flex-auto ms-2 cursor-pointer" (click)="openHire(item)">
              <h3 class="leading-5 tracking-wider font-semibold text-gray-600">{{item.displayServiceText}}</h3>
              <p class="text-xs text-slate-500">{{item.createdAt | date:'dd MMM yyyy'}}</p>
              <span class=" text-center items-end rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset"
                [class]="(item.status == 'Pending')?'bg-yellow-50 text-yellow-700 ring-yellow-600/20':(item.status == 'Open')?'bg-amber-50 text-amber-700 ring-amber-600/20':((item.status == 'In Progress')?'bg-blue-50 text-primary ring-blue-600/20':'bg-green-50 text-green-700 ring-green-600/20')">{{(item.status == 'Pending')?'Approval Pending':item.status}}</span>
            </div>
            <div class="flex-auto w-20 h-16 grid items-end justify-end content-end">
              <button (click)="openHire(item)"
                class="w-auto relative font-normal text-sm px-1.5 rounded-md my-1 bg-yellow-50 text-yellow-700 ring-1 ring-inset ring-yellow-600/20 text-center"
                *ngIf="!item.isHired">{{item.proposals.length}} Proposals

              </button>
              <button (click)="openHire(item)"
                class="w-auto relative text-sm px-1.5 rounded my-1 font-normal border-red-500 text-red-500 hover:bg-red-500 hover:text-white text-center border">Details
                <!-- <div *ngIf="item.newProposal"
                  class="absolute animate-ripple inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-2 dark:border-gray-900">
                </div>
                 -->
                <span *ngIf="item.newProposal"
                  class="absolute -top-1 -end-1 bg-red-500 text-white text-[10px] leading-[12px]  px-1.5 py-0.5 rounded-full">
                  {{ newProposalsCount > 0 ? newProposalsCount + ' New Proposals' : item.proposals.length  }}
                </span>
              </button>
              <button [disabled]="createConv"
                class="w-auto relative text-sm px-1.5 rounded my-1 font-normal border-primary text-primary hover:bg-primary hover:text-white text-center border"
                (click)="createConversation(item.hireProposals[0].partner,item)"
                *ngIf="item.isHired && item.status != 'Completed'">Chat
              </button>

            </div>


          </div>

          <div class="flex mt-4 items-center justify-center cursor-pointer" *ngIf="item.status == 'Open'"
            (click)="openHire(item)">
            <div class="flex-1">
              <p class="text-sm text-slate-600">Location</p>
              <p class="text-sm font-semibold text-amber-500">{{item.serviceLocation}}</p>
            </div>
            <div class="flex-1">
              <p class="text-sm text-slate-600">Category</p>
              <p class="text-sm font-semibold text-blue-500">{{item.category}}</p>
            </div>
          </div>

          <div class="relative mt-4" *ngIf="item.status != 'Open' && item.isHired">
            <div class="flex mb-2 items-center justify-between">
              <div>
                <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase  bg-blue-50 text-primary ring-blue-600/20">
                  {{(item.hireProposals[0]?.projectCompletionRate == 0) ? 'Work Started': (item.hireProposals[0]?.projectCompletionRate == 25) ? 'Stage 1 Completed':(item.hireProposals[0]?.projectCompletionRate == 50) ? 'Stage 2 Completed' : (item.hireProposals[0]?.projectCompletionRate == 75) ? 'Stage 3 Completed' : (item.hireProposals[0]?.projectCompletionRate == 100) ? 'Completed':''}}
                </span>
              </div>
              <div class="text-right">
                <span class="text-xs font-semibold inline-block text-primary">
                  {{item.hireProposals[0]?.projectCompletionRate}}%
                </span>
              </div>
            </div>
            <div class="overflow-hidden h-1 mb-4 text-xs flex rounded bg-blue-50">
              <div [ngStyle]="{'width':item.hireProposals[0]?.projectCompletionRate+'%'}"
                class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-blue-700"></div>
            </div>
          </div>
          <div class="mt-5">

          </div>
        </div>

        <div class="relative inline-block border border-slate-800 border-dashed p-3 rounded-xl cursor-pointer">
          <div class="grid h-32 justify-items-center content-center" (click)="appC.openServiceRequestModal('')">
            <img src="assets/icons/serviceadd.png" class="w-24">
            <h3 class="font-mono">Add New Service</h3>
          </div>
        </div>
      </div>
    </section>

    <section aria-label="Calculators" class="my-2">
      <div class="flex items-center pb-3 pt-3">
        <h1 class="text-xl font-semibold tracking-wide text-gray-600">Calculators</h1>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        <div class="relative inline-block bg-white p-3 rounded-xl shadow-md"
          *ngFor="let item of calculators;let i =index">
          <div class="flex w-full">
            <div class="flex-none w-16 h-16 rounded">
              <img class="rounded w-16 h-16" src="assets/servicesIcon/Fsi.png">
            </div>
            <div class="flex-auto ms-2">
              <h3 class="tracking-wider font-semibold text-gray-600">FSI Calculator</h3>
              <p class="text-xs text-slate-500">{{item.createdAt | date:'dd MMM, yyyy'}}</p>
            </div>
            <div class="flex-auto w-20 h-16 grid items-end justify-end content-end">
              <button
                class="w-auto text-sm px-1.5 rounded my-1 font-normal border-red-500 text-red-500 hover:bg-red-500 hover:text-white text-center border"
                (click)="openCalculator(item)">Details</button>

            </div>
          </div>

          <div class="flex mt-2">
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Area Of Plot</p>
              <p class="text-sm font-semibold text-amber-500">{{item.areaOfPlotSqm}}</p>
            </div>
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Net Area Of Plot (3 - 4)</p>
              <p class="text-sm font-semibold text-blue-500">{{item.netAreaPlotSqm}}</p>
            </div>

          </div>
          <div class="flex mt-2 items-center">
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Total FSI</p>
              <p class="text-sm font-semibold text-cyan-500">{{item.totalFsiIncludeAnciliaryFsiSqm}}</p>
            </div>

          </div>

          <div class="mt-5">
          </div>
        </div>

        <div class="relative inline-block bg-white p-3 rounded-xl shadow-md"
          *ngFor="let item of sidecalculators;let i =index">
          <div class="flex w-full">
            <div class="flex-none w-16 h-16 rounded">
              <img class="rounded w-16 h-16" src="assets/servicesIcon/Fsi.png">
            </div>
            <div class="flex-auto ms-2">
              <h3 class="tracking-wider font-semibold text-gray-600">Side Margin Calculator</h3>
              <p class="text-xs text-slate-500">{{item.createdAt | date:'dd MMM, yyyy'}}</p>
            </div>
            <div class="flex-auto w-20 h-16 grid items-end justify-end content-end">
              <button
                class="w-auto text-sm px-1.5 rounded my-1 font-normal border-red-500 text-red-500 hover:bg-red-500 hover:text-white text-center border"
                (click)="openSideCalculator(item)">Details</button>

            </div>
          </div>

          <div class="flex mt-2">
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Side Margin (Side 1)</p>
              <p class="text-sm font-semibold text-amber-500">{{item.side1.sideMargin}} Meter</p>
            </div>
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Side Margin (Side 2)</p>
              <p class="text-sm font-semibold text-blue-500">{{item.side2.sideMargin}} Meter</p>
            </div>

          </div>
          <div class="flex mt-2 items-center">
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Side Margin (Side 3)</p>
              <p class="text-sm font-semibold text-blue-500">{{item.side3.sideMargin}} Meter</p>
            </div>
            <div class="flex-auto">
              <p class="text-sm text-slate-500">Side Margin (Side 4)</p>
              <p class="text-sm font-semibold text-amber-500">{{item.side4.sideMargin}} Meter</p>
            </div>

          </div>



          <div class="mt-5">

          </div>

        </div>

        <div class="relative inline-block border border-slate-800 border-dashed p-3 rounded-xl cursor-pointer"
          (click)="appC.openCalculatorModal()">
          <div class="grid h-32 justify-items-center content-center">
            <img src="assets/icons/calAdd.png" class="w-24">
            <h3 class="font-mono">Add New Calculator</h3>

          </div>

        </div>
      </div>
    </section>

    <section aria-label="Blogs" class="my-2">
      <div class="flex items-center pb-3 pt-1">
        <h1 class="text-xl font-semibold tracking-wide text-gray-600">Blogs</h1>
      </div>
      <div class="bg-white p-3 rounded-xl shadow-md">
        <div *ngFor="let item of blogs;let i = index" class="cursor-pointer" (click)="openBlog(item)">
          <div class="flex">
            <h3 class="flex-1 tracking-wider mb-0 font-semibold text-base text-gray-700">{{item.title}}</h3>
            <p class="text-red-500 text-sm font-bold font-mono">{{item.createdAt | date:'MMM dd, yyyy'}}</p>
          </div>

          <p class="text-sm mt-2 mb-2 tracking-wide text-gray-500" [innerHTML]="item.content | slice :0:200 "></p>
          <hr class="mb-1" *ngIf="i != (blogs.length -1)">
        </div>
      </div>
      <div class="flex justify-center">
        <button [routerLink]="['/blog']" routerLinkActive="router-link-active"
          class="flex auto text-base mt-4 py-2 font-semibold px-7 tracking-wider rounded bg-primary hover:bg-blue-700 bg-primary text-white text-center border">View
          all Blogs</button>
      </div>
    </section>

    <section aria-label="Questions" class="my-2">
      <div class="flex items-center pb-3 pt-1">
        <h1 class="text-xl font-semibold tracking-wide text-gray-600">Questions</h1>
      </div>
      <div class="bg-white p-3 rounded-xl shadow-md">
        <div *ngFor="let item of questions;let i = index" class="cursor-pointer" (click)="openQuestion(item.title)">
          <div class="flex">
            <h3 class="flex-1 tracking-wider mb-0 font-semibold text-base text-gray-700">{{item.title}}</h3>
            <p class="text-red-500 text-sm font-bold font-mono">{{item.createdAt | date:'MMM dd, yyyy'}}</p>
          </div>

          <p class="text-sm mt-2 mb-2 tracking-wide text-gray-500" [innerHTML]="item.description"></p>
          <hr class="mb-1" *ngIf="i != (questions.length -1)">
        </div>
      </div>
      <div class="flex justify-center">
        <button [routerLink]="['/questions']" routerLinkActive="router-link-active"
          class="flex text-base auto mt-4 py-2 font-semibold px-7 tracking-wider rounded bg-primary hover:bg-blue-700 bg-primary text-white text-center border">View
          all Questions</button>
      </div>
    </section>

  </main>
</div>


<div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" (click)="isCalModalOpen = false"
  *ngIf="isCalModalOpen">

  <div (click)="stopCalModalOpenModal($event)"
    class="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full sm:max-w-3xl">
    <div class="from-[#6B8FFA] to-[#ADC1FC]">
      <div>

        <div class="text-center">
          <section class="relative overflow-hidden bg-gradient-to-b ">
            <span class="text-white mt-2.5 me-2.5 float-right cursor-pointer"
              (click)="isCalModalOpen = !isCalModalOpen;getData()">Close</span>
            <app-fsi-calculator [isModal]="true" [edit]="true" [calId]="calculatorId"
              (closeModal)="isCalModalOpen = !isCalModalOpen;getData()">
            </app-fsi-calculator>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  (click)="isSideCalModalOpen = false" *ngIf="isSideCalModalOpen">

      <div (click)="stopCalModalOpenModal($event)"
        class="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full sm:max-w-3xl">
        <div class="from-[#6B8FFA] to-[#ADC1FC]">
          <div>

            <div class="text-center">
              <section class="relative overflow-hidden bg-gradient-to-b ">
                <span class="text-white mt-2.5 me-2.5 float-right cursor-pointer"
                  (click)="isSideCalModalOpen = !isSideCalModalOpen;getData()">Close</span>
                <app-side-margin [isModal]="true" [edit]="true" [calId]="calculatorId"
                  (closeModal)="isSideCalModalOpen = !isSideCalModalOpen;getData()">
                </app-side-margin>
              </section>
            </div>
          </div>
        </div>
      </div>
</div>

<div *ngIf="isHireOpen" class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50"
  (click)="closeHireModal()">
  <!-- <div class="relative inset-0 " (click)="stopHireModal($event)" > -->
  <div (click)="stopHireModal($event)"
    class="min-h-full translate-x-0 w-full md:w-[min(25rem,calc(100vw-theme(spacing.10)))] bg-white shadow-2xl ring-1 ring-black/10 transition">
    <button (click)="isHireOpen = false" type="button"
      class="absolute right-6 top-3 flex h-8 w-8 items-center justify-center">
      <svg class="h-3.5 w-3.5 overflow-visible stroke-white" fill="none" stroke-width="1.5" stroke-linecap="round"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L14 14M14 0L0 14"></path>
      </svg>
    </button>
    <nav class="divide-y divide-slate-900/10 text-base bg-primary leading-7 text-white">
      <div class="px-4 py-3">
        <h5 class="mb-0 font-semibold">Hire Professional</h5>
      </div>
    </nav>
    <div class="px-4 py-6">
      <div class="relative w-full inline-block mb-1 bg-white p-3 rounded-xl border">
        <div class="flex w-full">
          <div class="flex-none w-16 h-16 rounded">
            <img class="rounded w-16 h-16" src="{{baseURL}}/file/retrieve/{{selectedItem.service.smallIcon}}">
          </div>
          <div class="flex-auto ms-2">
            <h3 class="tracking-wider font-semibold text-gray-600">{{selectedItem.displayServiceText}}</h3>
            <p class="text-xs text-slate-500">{{selectedItem.createdAt | date:'dd MMM yyyy'}}</p>
            <span class=" text-center items-end rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset"
              [class]="(selectedItem.status == 'Pending')?'bg-yellow-50 text-yellow-700 ring-yellow-600/20':(selectedItem.status == 'Open')?'bg-amber-50 text-amber-700 ring-amber-600/20':((selectedItem.status == 'In Progress')?'bg-blue-50 text-primary ring-blue-600/20':'bg-green-50 text-green-700 ring-green-600/20')">{{(selectedItem.status == 'Pending')?'Approval Pending':selectedItem.status}}</span>
          </div>

          <div class="flex items-center justify-center">
            <dl class="divide-y divide-slate-100 rounded-xl">
              <div class="group py-1 ">
                <div (click)="toggleRequirements()"
                  class="flex w-full cursor-pointer select-none justify-between text-left text-sm font-semibold leading-7 text-slate-900 group-open:text-blue-600  items-center">
                  <span class="flex items-center">
                    My Requirement
                    <i class="fa-solid fa-angle-down self-center ms-2" *ngIf="!showRequirements"></i>
                    <i class="fa-solid fa-angle-up self-center ms-2" *ngIf="showRequirements"></i>
                  </span>
                </div>
              </div>
            </dl>
          </div>
        </div>

        <div class="py-1 divide-y divide-slate-100 rounded-xl border p-2 mt-3 " *ngIf="showRequirements">
          <div *ngFor="let item of selectedItem.questions">
            <p class="text-sm mx-1 pt-1 font-medium">{{item.title}}</p>
            <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
              <div class="py-1 px-2 rounded-lg w-full">
                <li class="flex text-sm">
                  <div class="flex-none">
                    <i class="fa-solid fa-check text-green-700"></i>
                  </div>
                  <p class="ml-2">
                    {{item.answer}}
                  </p>
                </li>
              </div>
            </div>
          </div>
        </div>


        <div class="flex mt-4 items-center justify-center"
          *ngIf="hiredData?.isHired && hiredData?.hireProposals[0]?.status == 'Review Pending'">
          <!-- <div class="flex-1">
              <p class="text-sm text-slate-600">Work Status</p>
              <p class="text-sm font-semibold text-amber-500">{{hiredData?.hireProposals[0]?.projectCompletionRate}}%
              </p>
            </div> -->
          <div class="flex-1" *ngIf="hiredData?.hireProposals[0]?.status == 'Review Pending'">
            <button (click)="markCompleted(hiredData?.hireProposals[0])"
              class="flex-1 mx-2 px-1.5 py-2 rounded my-1 w-full font-medium border-amber-500 bg-amber-500 text-white hover:bg-amber-500 hover:text-white text-center border">Mark
              Completed</button>
          </div>
        </div>

      </div>


      <div class="mt-3" *ngIf="hiredData?.isHired">
        <h3 class="tracking-wider font-semibold">Hired Partner</h3>
      </div>
      <div class="relative w-full inline-block mb-1 bg-white p-3 mt-1 rounded-xl border" *ngIf="hiredData?.isHired">

        <div class="flex w-full">
          <div class="flex-none w-16 h-16 rounded">
            <img class="rounded w-16 h-16" onerror="this.src='assets/images/user.webp'"
              src="{{baseURL}}/file/retrieve/{{hiredData.hireProposals[0]?.hiredpartner?.photo}}">
          </div>
          <div class="flex-auto ms-2">
            <p class="text-base tracking-wider font-semibold text-gray-600">
              {{hiredData?.hireProposals[0]?.hiredpartner?.name}}</p>
            <div class=" mt-0.5">
              <span class="text-sm text-slate-500 me-2"><i class="text-blue-500 me-0.5 fa-solid fa-location-dot"></i>
                {{hiredData?.hireProposals[0]?.hiredpartner?.city}},
                {{hiredData?.hireProposals[0]?.hiredpartner?.state}}</span>
            </div>

          </div>
        </div>
        <div class="">
          <p class="text-sm text-slate-600 leading-4 tracking-wide mt-1">
            {{hiredData?.hireProposals[0]?.hiredpartner?.overview}}</p>
        </div>

        <div class="relative mt-2">
          <div class="flex mb-2 items-center justify-between">
            <div>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase  bg-blue-50 text-primary ring-blue-600/20">
                {{(hiredData?.hireProposals[0]?.projectCompletionRate == 0) ? 'Work Started': (hiredData?.hireProposals[0]?.projectCompletionRate == 25) ? 'Stage 1 Completed':(hiredData?.hireProposals[0]?.projectCompletionRate == 50) ? 'Stage 2 Completed' : (hiredData?.hireProposals[0]?.projectCompletionRate == 75) ? 'Stage 3 Completed' : (hiredData?.hireProposals[0]?.projectCompletionRate == 100) ? 'Completed':''}}
              </span>
            </div>
            <div class="text-right">
              <span class="text-xs font-semibold inline-block text-primary">
                {{hiredData?.hireProposals[0]?.projectCompletionRate}}%
              </span>
            </div>
          </div>
          <div class="overflow-hidden h-1 mb-2 text-xs flex rounded bg-blue-50">
            <div [ngStyle]="{'width':hiredData?.hireProposals[0]?.projectCompletionRate+'%'}"
              class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-blue-700"></div>
          </div>
        </div>

        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm font-semibold text-slate-600 leading-8 ">Ratings </div>
          <div class="leading-8 text-center">
            <i class="fa-solid fa-star   text-gray-300"
              [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 1 }"></i>
            <i class="fa-solid fa-star  text-gray-300"
              [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 2 }"></i>
            <i class="fa-solid fa-star   text-gray-300"
              [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 3 }"></i>
            <i class="fa-solid fa-star  text-gray-300"
              [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 4}"></i>
            <i class="fa-solid fa-star  text-gray-300"
              [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 5}"></i>
          </div>
          <div class="leading-8 text-right"><button (click)="toggleRatings()"
              class="text-sm font-semibold text-slate-600  cursor-pointer ">All
              Ratings <i class="fa-solid fa-angle-down self-center ms-1" *ngIf="!showRatings"></i>
              <i class="fa-solid fa-angle-up self-center ms-1" *ngIf="showRatings"></i></button></div>


          <div *ngIf="showRatings" class="text-sm font-semibold text-slate-600">Customer Rating </div>
          <div class="col-span-1 text-center" *ngIf="showRatings">
            <div class="">
              <i class="fa-solid fa-star   text-gray-300"
                [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 1 }"></i>
              <i class="fa-solid fa-star  text-gray-300"
                [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 2 }"></i>
              <i class="fa-solid fa-star   text-gray-300"
                [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 3 }"></i>
              <i class="fa-solid fa-star  text-gray-300"
                [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 4}"></i>
              <i class="fa-solid fa-star  text-gray-300"
                [ngClass]="{'text-yellow-300': hiredData.hireProposals[0]?.hiredpartner?.customerRating >= 5}"></i>
            </div>
          </div>
          <div class="col-span-1" *ngIf="showRatings"></div>
          <div *ngIf="showRatings" class="text-sm font-semibold text-slate-600">Knowledge Sharing</div>
          <div class="col-span-1 text-center" *ngIf="showRatings">
            <span class="text-yellow-300 fa-solid fa-star "></span>
            <span class="text-yellow-300 fa-solid fa-star "></span>
            <span class="text-yellow-300 fa-solid fa-star "></span>
            <span class="text-yellow-300 fa-solid fa-star "></span>
            <span class="text-yellow-300 fa-solid fa-star "></span>
          </div>
          <div class="col-span-1" *ngIf="showRatings"></div>
        </div>

        <section class="mt-2">
          <div>
            <p>I will charge <span class="font-medium text-primary"> ₹ {{hiredData?.hireProposals[0]?.cost}}</span>,
              <span class="font-medium text-primary">{{hiredData?.hireProposals[0]?.costType}}</span> per sqft, <span
                class="font-medium text-primary">{{(hiredData?.hireProposals[0]?.isNegotiable)?'Negotiable':'Non Negotiable'}}</span>.
              I may finish work in <span
                class="font-semibold text-primary">{{hiredData?.hireProposals[0]?.timeline}}</span> days
            </p>
          </div>
        </section>

        <div class="flex mt-2 gap-3">
          <button *ngIf="hiredData?.hireProposals[0]?.projectCompletionRate == 100 && !hasRated"
            class="flex-1 rounded my-1 font-medium border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white text-center border"
            (click)="openratingModal()">Rate</button>
          <button [disabled]="createConv"
            class="flex-1 rounded my-1 font-semibold border-primary text-primary hover:text-white hover:bg-primary text-center border"
            (click)="isHireOpen = false;createConversation(hiredData?.hireProposals[0]?.hiredpartner?._id,selectedItem)">Chat</button>
          <a href="tel:+91{{hiredData?.hireProposals[0]?.hiredpartner?.mobile}}"><button
              class="flex-none px-6 rounded my-1 border-slate-600 text-slate-600 hover:text-white hover:bg-slate-600 text-center border"><i
                class="fa-solid fa-phone"></i></button></a>

        </div>

      </div>

      <div class="flex justify-between items-center mt-3">
        <div>
          <h3 class="tracking-wider font-semibold">{{proposals.length}} Proposals</h3>
        </div>
        <div>
          <button (click)="toggleProposals()" class="text-xs font-semibold text-slate-900 flex items-center">View
            Proposals
            <i class="fa-solid fa-angle-down self-center ms-1" *ngIf="!showProposals"></i>
            <i class="fa-solid fa-angle-up self-center ms-1" *ngIf="showProposals"></i>
          </button>
        </div>
      </div>



      <div *ngIf="proposals.length == 0" class="flex m-auto justify-center h-48 w-48">
        <img src="assets/icons/waiting.gif" alt="man">
      </div>
      <div class=" relative w-full inline-block mb-1 bg-white p-3 mt-1 rounded-xl border" *ngIf="proposals.length == 0">
        <p class="text-base text-center tracking-wider font-semibold text-gray-600">No Proposals Yet.</p>
      </div>
      <div *ngIf="showProposals">
        <div class="relative w-full inline-block mb-1 bg-white p-3 mt-1 rounded-xl border"
          *ngFor="let item of proposals">

          <div class="flex w-full">
            <div class="flex-none w-16 h-16 rounded">
              <img class="rounded w-16 h-16" onerror="this.src='assets/images/user.webp'"
                src="{{baseURL}}/file/retrieve/{{item.partner?.photo}}">
            </div>
            <div class="flex-auto ms-2">
              <p class="text-base tracking-wider font-semibold text-gray-600">{{item.partner?.name}}</p>
              <div class=" mt-0.5">
                <span class="text-sm text-slate-500 me-2"><i class="text-blue-500 me-0.5 fa-solid fa-location-dot"></i>
                  {{item.partner?.city}},
                  {{item.partner?.state}}</span>

              </div>

            </div>
          </div>
          <div class="">
            <p class="text-sm text-slate-600 leading-4 tracking-wide mt-1">{{item.partner?.overview}}</p>
          </div>


          <div class="grid grid-cols-3 ">
            <div class="text-sm font-semibold text-slate-600 leading-8 ">Ratings </div>
            <div class="leading-8 text-center">
              <i class="fa-solid fa-star   text-gray-300"
                [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 1 }"></i>
              <i class="fa-solid fa-star  text-gray-300"
                [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 2 }"></i>
              <i class="fa-solid fa-star   text-gray-300"
                [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 3 }"></i>
              <i class="fa-solid fa-star  text-gray-300"
                [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 4}"></i>
              <i class="fa-solid fa-star  text-gray-300"
                [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 5}"></i>
            </div>
            <div class="leading-8 text-right"><button (click)="item.showRatings = !item.showRatings"
                class="text-sm font-semibold text-slate-600  cursor-pointer ">All
                Ratings <i class="fa-solid fa-angle-down self-center ms-1" *ngIf="!item.showRatings"></i>
                <i class="fa-solid fa-angle-up self-center ms-1" *ngIf="item.showRatings"></i></button></div>


            <!-- <div *ngIf="showRatings" class="text-sm font-semibold text-slate-600"><span>Got Hired</span> </div>
              <div class="col-span-2" *ngIf="showRatings">
                <span class="text-yellow-200 me-0.5 fa-solid fa-star "></span>
                <span class="text-yellow-200 me-0.5 fa-solid fa-star "></span>
                <span class="text-yellow-200 me-0.5 fa-solid fa-star "></span>
                <span class="text-yellow-200 me-0.5 fa-solid fa-star "></span>
              </div> -->
            <div *ngIf="item.showRatings" class="text-sm font-semibold text-slate-600">Customer Rating </div>
            <div class="col-span-1 text-center" *ngIf="item.showRatings">
              <div class="">
                <i class="fa-solid fa-star   text-gray-300"
                  [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 1 }"></i>
                <i class="fa-solid fa-star  text-gray-300"
                  [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 2 }"></i>
                <i class="fa-solid fa-star   text-gray-300"
                  [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 3 }"></i>
                <i class="fa-solid fa-star  text-gray-300"
                  [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 4}"></i>
                <i class="fa-solid fa-star  text-gray-300"
                  [ngClass]="{'text-yellow-300': item.partner?.customerRating >= 5}"></i>
              </div>
            </div>
            <div class="col-span-1" *ngIf="item.showRatings"></div>
            <div *ngIf="item.showRatings" class="text-sm font-semibold text-slate-600">Knowledge Sharing</div>
            <div class="col-span-1 text-center" *ngIf="item.showRatings">
              <i class="fa-solid fa-star-half-stroke  text-gray-300" *ngIf="item.partner.knowledgeSharingRating == 0.5"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating == 0.5}"></i>
              <i class="fa-solid fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating >= 1"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 1}"></i>
              <i class="fa-regular fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating <= 0.4"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 1}"></i>
              <i class="fa-solid fa-star-half-stroke  text-gray-300" *ngIf="item.partner.knowledgeSharingRating == 1.5"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating == 1.5}"></i>
              <i class="fa-solid fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating >= 2"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 2}"></i>
              <i class="fa-regular fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating <= 1.4"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 2}"></i>
              <i class="fa-solid fa-star-half-stroke  text-gray-300" *ngIf="item.partner.knowledgeSharingRating == 2.5"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating == 2.5}"></i>
              <i class="fa-solid fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating >= 3"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 3}"></i>
              <i class="fa-regular fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating <= 2.4"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 3}"></i>
              <i class="fa-solid fa-star-half-stroke  text-gray-300" *ngIf="item.partner.knowledgeSharingRating == 3.5"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating == 3.5}"></i>
              <i class="fa-solid fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating >= 4"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 4}"></i>
              <i class="fa-regular fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating <= 3.4"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 4}"></i>
              <i class="fa-solid fa-star-half-stroke  text-gray-300" *ngIf="item.partner.knowledgeSharingRating == 4.5"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating == 4.5}"></i>
              <i class="fa-solid fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating >= 5"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 5}"></i>
              <i class="fa-regular fa-star  text-gray-300" *ngIf="item.partner.knowledgeSharingRating <= 4.4"
                [ngClass]="{'text-yellow-400': item.partner?.knowledgeSharingRating >= 5}"></i>

            </div>
            <div class="col-span-1" *ngIf="item.showRatings"></div>
          </div>

          <section class="mt-2">
            <div>
              <p>I will charge <span class="font-medium text-primary"> ₹ {{item.cost}} </span>,<span
                  class="font-medium text-primary">
                  {{item.costType}} </span> per sqft, <span
                  class="font-medium text-primary">{{(item.isNegotiable)?'Negotiable':'Non Negotiable'}}</span>. I may
                finish work
                in
                <span class="font-semibold text-primary">{{item.timeline}}</span> days </p>
            </div>
          </section>


          <div class="flex mt-2" *ngIf="!hiredData?.isHired">
            <button (click)="hireProposal(item)"
              class="flex-1 mx-0.5 rounded my-1 font-medium border-[#D61919] bg-[#EE4848] text-white hover:bg-[#c02f2f] text-center border">Hire</button>
            <button [disabled]="createConv"
              class="flex-1 mx-2 px-1.5 rounded my-1 font-semibold border-primary text-primary hover:text-white hover:bg-primary text-center border"
              (click)="isHireOpen = false;createConversation(item.partner?._id,selectedItem)">Chat</button>
            <a href="tel:+91{{item.partner?.mobile}}"><button
                class="flex-none mx-2 px-6 rounded my-1 border-slate-600 text-slate-600 hover:text-white hover:bg-slate-600 text-center border"><i
                  class="fa-solid fa-phone"></i></button></a>

          </div>
          <div class="mt-2" *ngIf="!hiredData?.isHired">
            <button (click)="openprofileModal(item)"
              class="w-full border bg-primary rounded text-white p-1 px-2 font-semibold ">View
              Profile</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- </div> -->
</div>

<div *ngIf="isCalOpen" class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50"
  (click)="isCalOpen = false">
  <div class="min-h-full w-full md:w-4/5 lg:w-3/5 xl:w-1/2 bg-white shadow-2xl ring-1 ring-black/10 transition"
    (click)="stopCalOpenModal($event)">
    <button (click)="isCalOpen = false" type="button"
      class="absolute right-6 top-3 flex h-8 w-8 items-center justify-center">
      <svg class="h-3.5 w-3.5 overflow-visible stroke-white" fill="none" stroke-width="1.5" stroke-linecap="round"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L14 14M14 0L0 14"></path>
      </svg>
    </button>
    <nav class="divide-y divide-slate-900/10 text-base bg-primary leading-7 text-white">
      <div class="px-4 py-3">
        <h5 class="mb-0 font-semibold">Download FSI Calculation</h5>
      </div>
    </nav>
    <div class="px-4 py-6">
      <div class="relative w-full inline-block mb-1 bg-white p-3 rounded-xl border">
        <div class="flex w-full">
          <div class="flex-none w-16 h-16 rounded">
            <img class="rounded w-16 h-16" src="assets/servicesIcon/Fsi.png">
          </div>

          <div class="flex-auto ms-2">
            <h3 class="tracking-wider font-semibold text-gray-600">FSI Calculator</h3>
            <p class="text-xs text-slate-500">{{selectedItem.createdAt | date:'dd MMM, yyyy'}}</p>
          </div>
        </div>

        <div class="flex mt-2">
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Area Of Plot</p>
            <p class="text-sm font-semibold text-amber-500">{{selectedItem.areaOfPlotSqm}} Sq. M.</p>
          </div>
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Net Area Of Plot (3 - 4)</p>
            <p class="text-sm font-semibold text-blue-500">{{selectedItem.netAreaPlotSqm}} Sq. M.</p>
          </div>
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Total FSI</p>
            <p class="text-sm font-semibold text-cyan-500">{{selectedItem.totalFsiIncludeAnciliaryFsiSqm}} Sq. M.</p>
          </div>
        </div>
      </div>
      <dl class="divide-y divide-slate-100 mb-1 rounded-xl border px-3 py-1 mt-3">
        <details class="group py-2 marker:content-['']">
          <summary
            class="flex w-full cursor-pointer select-none justify-between text-left text-sm font-semibold leading-7 text-slate-900 group-open:text-blue-600 [&amp;::-webkit-details-marker]:hidden">
            Requirement
            <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-blue-500" fill="none"
              xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M18 12H6"></path>
              <path class="group-open:hidden" d="M12 6v12"></path>
            </svg></summary>
          <div class="py-1">
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Rules Applicability</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.rulesApplicability}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">What is your plot area?</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.plotArea}} {{selectedItem.plotAreaUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Total Area of deduction</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.deductionArea}} {{selectedItem.deductionAreaUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">What is Width oF Access Road?</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.widthRoad}} {{selectedItem.widthRoadUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Do you have gunthewari certificate of plot?</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.gunthewariCertificate}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">My plot is from sanctioned Layout</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.sanctionedLayout}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">I will leave 10% open space (min 200 SqM)</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.openSpace}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">I will pay 10% of my land cost as per government rate</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.myLandCost}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Is it redevelopment Proposal?</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.redevelopmentProposal}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Use of Building</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.useBuilding}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
          </div>
        </details>
      </dl>

      <div class="mt-3">
        <span class="tracking-wider text-lg font-semibold">Calculations</span>
        <button
          class="w-auto float-right text-sm px-1.5 rounded my-1 font-normal border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white text-center border"
          (click)="openCalculatorModal()">Edit</button>
        <button
          class="w-auto float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-primary text-primary hover:bg-primary hover:text-white text-center border"
          (click)="download()">Download</button>
          <button (click)="appC.openaddcalculatorComplainModal(); isCalOpen = false"
          class="w-auto float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-cyan-600 text-cyan-600 hover:bg-cyan-600 hover:text-white text-center border"
          >Raise Complaint</button>
      </div>

      <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
        <div class="overflow-x-auto">
          <table class="min-w-full indent-0 border-inherit border-collapse ">
            <thead>
              <tr class="border-b-gray-300 border-b">
                <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 border-r-gray-300 border-r"></th>
                <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 border-r-gray-300 border-r">AREA
                  STATEMENT</th>
                <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 border-r-gray-300 border-r">Sq.
                  M.</th>
                <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900">Sq. Ft.</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Access Road Width in Meter</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.accessRoadWidthInMeter}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  1</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  <b>Area Of Plot</b></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.areaOfPlotSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.areaOfPlotSqft}}</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  2</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Deduction for road / reservation</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.deductionRoadSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.deductionRoadSqft}}</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  3</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Balance Area of Plot (Gross Plot)</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.balanceAreaPlotSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.balanceAreaPlotSqft}}</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  4</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Amenity Space Required</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.amenitySpaceRequired}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Required Amenity Space</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.requiredAmenitySpaceSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.requiredAmenitySpaceSqft}}</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  5</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  <b>Net Area Of Plot (3 - 4)</b></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.netAreaPlotSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.netAreaPlotSqft}}</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  6</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Open Space Required</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.openSpaceRequired}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Required Open Space / Play Ground</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.requiredOpenSpaceSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.requiredOpenSpaceSqft}}</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  7</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Internal Road area as per proposed plan</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  -</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">-</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  8</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Applicable Rules {{selectedItem.rulesApplicability}}</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  -</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">-</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  9</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Basic FSI as per front road width</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.netAreaPlotSqm}} x {{selectedItem.basicFsi}} x {{selectedItem.basicFsiFactor}}%</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.basicFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.basicFsiSqft}}</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  10</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Addition of FSI on payment of premium</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.areaOfPlotSqm}} x {{selectedItem.premiumFsi}} x {{selectedItem.premiumFsiFactor}}%
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.additionFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.additionFsiSqft}}</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  11</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  TDR FSI (including TDR to buy & in-situ FSI generated on site)</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.areaOfPlotSqm}} x {{selectedItem.tdrFsi}} x {{selectedItem.tdrFactor}}%</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.tdrFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.tdrFsiSqft}}</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  12</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Additional FSI under chapter no 7</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  a. Redevelopment inentive FSI {{selectedItem.areaOfPlotSqm}} x
                  {{selectedItem.redevelopementPercent}}%</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.redevelopementFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.redevelopementFsiSqft}}</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.additionalFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.additionalFsiSqft}}</td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  13</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Ancillary FSI</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  a. Total FSI (9+10+11 or 12 whichever is applicable)</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.totalFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.totalFsiSqft}}</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  b. Ancillary FSI ({{selectedItem.useBuilding}} {{selectedItem.anciliaryFsiPercent}}%)</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.anciliaryFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  14</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Total FSI Including Ancillary FSI </td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.totalFsiIncludeAnciliaryFsiSqm}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.totalFsiIncludeAnciliaryFsiSqft}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>

    <div class="text-center my-2 text-red-500 font-semibold cursor-pointer"
      (click)="openCalculatorDeleteConfirmation();isCalOpen= false">DELETE</div>

  </div>
</div>

<div *ngIf="isSideCalOpen"
  class="fixed inset-0 z-50 overflow-y-auto flex items-start justify-end bg-black bg-opacity-50"
  (click)="isSideCalOpen = false">
  <div class="min-h-full w-full md:w-4/5 lg:w-3/5 xl:w-1/2 bg-white shadow-2xl ring-1 ring-black/10 transition"
    (click)="stopSideCalOpenModal($event)">
    <button (click)="isSideCalOpen = false" type="button"
      class="absolute right-6 top-3 flex h-8 w-8 items-center justify-center">
      <svg class="h-3.5 w-3.5 overflow-visible stroke-white" fill="none" stroke-width="1.5" stroke-linecap="round"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L14 14M14 0L0 14"></path>
      </svg>
    </button>
    <nav class="divide-y divide-slate-900/10 text-base bg-primary leading-7 text-white">
      <div class="px-4 py-3">
        <h5 class="mb-0 font-semibold">Download Side Margin Calculation</h5>
      </div>
    </nav>
    <div class="px-4 py-6">
      <div class="relative w-full inline-block mb-1 bg-white p-3 rounded-xl border">
        <div class="flex w-full">
          <div class="flex-none w-16 h-16 rounded">
            <img class="rounded w-16 h-16" src="assets/servicesIcon/Fsi.png">
          </div>
          <div class="flex-auto ms-2">
            <h3 class="tracking-wider font-semibold text-gray-600">Side Margin Calculator</h3>
            <p class="text-xs text-slate-500">{{selectedItem.createdAt | date:'dd MMM, yyyy'}}</p>
          </div>
        </div>

        <div class="flex mt-2">
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Side Margin (Side 1)</p>
            <p class="text-sm font-semibold text-amber-500">{{selectedItem.side1.sideMargin}} Meter</p>
          </div>
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Side Margin (Side 2)</p>
            <p class="text-sm font-semibold text-blue-500">{{selectedItem.side2.sideMargin}} Meter</p>
          </div>

        </div>
        <div class="flex mt-2 items-center">
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Side Margin (Side 3)</p>
            <p class="text-sm font-semibold text-blue-500">{{selectedItem.side3.sideMargin}} Meter</p>
          </div>
          <div class="flex-auto">
            <p class="text-sm text-slate-500">Side Margin (Side 4)</p>
            <p class="text-sm font-semibold text-amber-500">{{selectedItem.side4.sideMargin}} Meter</p>
          </div>

        </div>
      </div>
      <dl class="divide-y divide-slate-100 mb-1 rounded-xl border px-3 py-1 mt-3">
        <details class="group py-2 marker:content-['']">
          <summary
            class="flex w-full cursor-pointer select-none justify-between text-left text-sm font-semibold leading-7 text-slate-900 group-open:text-blue-600 [&amp;::-webkit-details-marker]:hidden">
            Requirement
            <svg class="ml-4 mt-0.5 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-blue-500" fill="none"
              xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M18 12H6"></path>
              <path class="group-open:hidden" d="M12 6v12"></path>
            </svg></summary>
          <div class="py-1">
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">What is your plot area?</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.plotArea}} {{selectedItem.plotAreaUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Locality</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.locality}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Planning Authority</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.planningAuthority}}
                    </p>
                  </li>

                </div>
              </div>
            </div>

            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Use of Building</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.useOfBuilding}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Height From Ground</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.heightFromGround}} {{selectedItem.heightFromGroundUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Height of Exclusive Parking Floors</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.heightExclusiveParking}} {{selectedItem.heightExclusiveParkingUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Height Excluding Parking Floors</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.heightExcludingParking}} {{selectedItem.heightExcludingParkingUnit}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Maximum Area of Single Floor</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      {{selectedItem.maxAreaSingleFloor}} {{selectedItem.maxAreaSingleFloorUnit}}
                    </p>
                  </li>




                  
                </div>
              </div>
            </div>
            <p class="text-sm mx-1 pt-1 font-normal">Along Plot Boundary</p>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Side 1</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      Adj Plot: {{selectedItem.side1.adjPlot}}
                      {{(selectedItem.side1.adjPlot == 'Road' || selectedItem.side1.adjPlot == 'Reservation Land')?", Sub Type: "+selectedItem.side1.subType:''}}
                      {{(selectedItem.side1.adjPlot == 'Road')?', Road Width: '+selectedItem.side1.roadWidth+' Meter':''}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Side 2</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      Adj Plot: {{selectedItem.side2.adjPlot}}
                      {{(selectedItem.side2.adjPlot == 'Road' || selectedItem.side2.adjPlot == 'Reservation Land')?", Sub Type: "+selectedItem.side2.subType:''}}
                      {{(selectedItem.side2.adjPlot == 'Road')?', Road Width: '+selectedItem.side2.roadWidth+' Meter':''}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Side 3</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      Adj Plot: {{selectedItem.side3.adjPlot}}
                      {{(selectedItem.side3.adjPlot == 'Road' || selectedItem.side3.adjPlot == 'Reservation Land')?", Sub Type: "+selectedItem.side3.subType:''}}
                      {{(selectedItem.side3.adjPlot == 'Road')?', Road Width: '+selectedItem.side3.roadWidth+' Meter':''}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
            <div>
              <p class="text-sm mx-1 pt-1 font-medium">Side 4</p>
              <div class="mx-1 grid grid-cols-1 gap-2 mb-2">
                <div class="py-1 px-2 rounded-lg w-full">
                  <li class="flex text-sm">
                    <div class="flex-none">
                      <i class="fa-solid fa-check text-green-700"></i>
                    </div>
                    <p class="ml-2">
                      Adj Plot: {{selectedItem.side4.adjPlot}}
                      {{(selectedItem.side4.adjPlot == 'Road' || selectedItem.side4.adjPlot == 'Reservation Land')?", Sub Type: "+selectedItem.side4.subType:''}}
                      {{(selectedItem.side4.adjPlot == 'Road')?', Road Width: '+selectedItem.side4.roadWidth+' Meter':''}}
                    </p>
                  </li>

                </div>
              </div>
            </div>
          </div>
        </details>
      </dl>

      <div class="mt-3">
        <span class="tracking-wider text-lg font-semibold">Calculations</span>
        <button
          class="w-auto float-right text-sm px-1.5 rounded my-1 font-normal border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white text-center border"
          (click)="openSideCalculatorModal()">Edit</button>
        <button
          class="w-auto float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-primary text-primary hover:bg-primary hover:text-white text-center border"
          (click)="sideDownload()">Download</button>
        <button (click)="appC.openaddcalculatorComplainModal(); isSideCalOpen = false"
          class="w-auto float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-cyan-600 text-cyan-600 hover:bg-cyan-600 hover:text-white text-center border"
          >Raise Complaint</button>
      </div>

      <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
        <div class="overflow-x-auto">
          <table class="min-w-full indent-0 border-inherit border-collapse ">
            <tbody>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  1</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Plot Area</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.plotArea}} {{selectedItem.plotAreaUnit}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  2</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Building Height From Ground</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.heightFromGround}} {{selectedItem.heightFromGroundUnit}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  3</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Height Excluding Parking floors</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.heightExcludingParking}} {{selectedItem.heightExcludingParkingUnit}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  4</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Use of Building</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.useOfBuilding}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  5</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Type of Building</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.typeOfBuilding}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  6</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Locality</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.locality}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  7</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Maximum Road Width</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{selectedItem.maximumRoadWidth}} Meter</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  8</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">Side Margins to
                  be left</td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Side</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Adj Plot</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Description</td>
                <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Side Margin
                </td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Side 1</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.side1.adjPlot}}</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{(selectedItem.side1.adjPlot == 'Road' || selectedItem.side1.adjPlot == 'Reservation Land')?selectedItem.side1.subType:''}}
                  {{(selectedItem.side1.adjPlot == 'Road')?selectedItem.side1.roadWidth+ ' Meter':''}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                  {{selectedItem.side1.sideMargin}} Meter</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Side 2</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.side2.adjPlot}}</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{(selectedItem.side2.adjPlot == 'Road' || selectedItem.side2.adjPlot == 'Reservation Land')?selectedItem.side2.subType:''}}
                  {{(selectedItem.side2.adjPlot == 'Road')?selectedItem.side2.roadWidth+ ' Meter':''}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                  {{selectedItem.side2.sideMargin}} Meter</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Side 3</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.side3.adjPlot}}</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{(selectedItem.side3.adjPlot == 'Road' || selectedItem.side3.adjPlot == 'Reservation Land')?selectedItem.side3.subType:''}}
                  {{(selectedItem.side3.adjPlot == 'Road')?selectedItem.side3.roadWidth+ ' Meter':''}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                  {{selectedItem.side3.sideMargin}} Meter</td>
              </tr>
              <tr class="border-b-gray-300 border-b">
                <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap "></td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  Side 4</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{selectedItem.side4.adjPlot}}</td>
                <td
                  class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                  {{(selectedItem.side4.adjPlot == 'Road' || selectedItem.side4.adjPlot == 'Reservation Land')?selectedItem.side4.subType:''}}
                  {{(selectedItem.side4.adjPlot == 'Road')?selectedItem.side4.roadWidth+ ' Meter':''}}</td>
                <td class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                  {{selectedItem.side4.sideMargin}} Meter</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>

    <div class="text-center my-2 text-red-500 font-semibold cursor-pointer"
      (click)="openCalculatorDeleteConfirmation();isSideCalOpen= false">DELETE</div>
  </div>
</div>

<div id="calculator-delete-confimation-modal" tabindex="-1"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <button type="button" (click)="closeCalculatorDeleteConfirmation()"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
      <div class="p-4 md:p-5 text-center">
        <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this
          calculator?</h3>
        <button type="button"
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
          (click)="deleteCalculator()">
          Yes, I'm sure
        </button>
        <button type="button"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          (click)="closeCalculatorDeleteConfirmation()">No, cancel</button>
      </div>
    </div>
  </div>
</div>


<!-- Main modal -->
<div id="rating-Modal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Rate Professional
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeratingModal()">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="flex items-center text-xl p-5 ">
        <i class="fa-solid fa-star mx-1 text-2xl text-gray-300"
          [ngClass]="{'text-yellow-300': rating >= 1 || ratingHover >= 1}" (mouseover)="ratingHover = 1"
          (click)="rating = 1"></i>
        <i class="fa-solid fa-star mx-1 text-2xl text-gray-300"
          [ngClass]="{'text-yellow-300': rating >= 2 || ratingHover >= 2}" (mouseover)="ratingHover = 2"
          (click)="rating = 2"></i>
        <i class="fa-solid fa-star mx-1 text-2xl text-gray-300"
          [ngClass]="{'text-yellow-300': rating >= 3 || ratingHover >= 3}" (mouseover)="ratingHover = 3"
          (click)="rating = 3"></i>
        <i class="fa-solid fa-star mx-1 text-2xl text-gray-300"
          [ngClass]="{'text-yellow-300': rating >= 4 || ratingHover >= 4}" (mouseover)="ratingHover = 4"
          (click)="rating = 4"></i>
        <i class="fa-solid fa-star mx-1 text-2xl text-gray-300"
          [ngClass]="{'text-yellow-300': rating >= 5 || ratingHover >= 5}" (mouseover)="ratingHover = 5"
          (click)="rating = 5"></i>
      </div>
      <form class="p-4 md:p-5">



        <div class="grid gap-4 mb-4 grid-cols-2">
          <div class="col-span-2">
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Add
              Comment</label>
            <textarea id="description" rows="4"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write Comment here" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <button (click)="submitRating()"
          class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Submit Review
        </button>
      </form>
    </div>
  </div>
</div>



<!-- Modal toggle -->
<!-- <button (click)="openprofileModal()"
  class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  type="button">
  Toggle modal
</button> -->

<!-- Main modal -->
<div id="profile-modal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative p-4 w-full max-w-2xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          Partner Profile
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeprofileModal()">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="bg-[#F1F2F2] py-0 overflow-auto">
        <div class="container">
          <div class="col-span-4 lg:col-span-3">
            <div class="bg-white relative">
              <img src="assets/images/partnerBackground.webp" class="-mt-3" width="100%" />
              <div class="bg-white shadow-md p-1 absolute bottom-24 lg:bottom-2 left-3 rounded-full">
                <img src="{{ baseUrl }}/file/retrieve/{{ vendorProfile?.partner?.photo }}"
                  onerror="this.src='assets/images/user.webp'" width="80px" class="rounded-full" />
              </div>
              <div class="ms-28 pb-4">
                <div class="flex">
                  <div class="flex-1">
                    <h3 class="text-lg font-semibold tracking-wide text-slate-700">
                      {{ vendorProfile?.partner?.name }}
                    </h3>
                    <p class="text-base font-light tracking-wide text-slate-700">
                      {{ vendorProfile?.partner?.city ? vendorProfile?.partner?.city : "-" }}
                      {{ vendorProfile?.partner?.state ? ", " + vendorProfile?.partner?.state : "" }}
                      {{ vendorProfile?.partner?.pincode ? " - " + vendorProfile?.partner?.pincode : "" }}
                    </p>
                  </div>
                  <div class="flex-none hidden md:flex">
                    <div class="flex flex-col mt-3 me-5">
                      <button class="text-2xl font-light tracking-wider">
                        <i class="text-yellow-400 fa-solid fa-star"></i>
                        {{ ((vendorProfile?.partner?.customerRating)?(vendorProfile?.partner?.customerRating):0) }} / 5
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white mt-2 px-5 pt-2" id="about">
              <span class="mt-1 tracking-wide cursor-pointer text-lg font-semibold">Overview</span>
              <p class="font-light mt-1 text-base pb-4 tracking-wide border-b-2 border-b-gray-300">
                {{ vendorProfile?.partner?.overview ? vendorProfile?.partner?.overview : "-" }}
              </p>
              <div class="flex mt-1">
                <div class="flex-1 mr-2">
                  <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Specialized In</span>
                  <p class="font-light text-base pb-4 tracking-wide">
                    <span *ngFor="let item of vendorProfile?.partner?.services; let index = index">{{ item.displayText
                      }}<span *ngIf="vendorProfile?.partner?.services.length - 1 != index">,
                      </span></span>
                  </p>
                </div>
                <div class="flex-1">
                  <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Partner Type</span>
                  <p class="font-light text-base pb-4 tracking-wide">
                    {{ vendorProfile?.partner?.userType }}
                  </p>
                </div>
              </div>
              <div class="flex mt-1">
                <div class="flex-1">
                  <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Work History</span>
                  <p class="font-light text-base pb-4 tracking-wide">
                    {{ vendorProfile?.partner?.workHistory ? vendorProfile?.partner?.workHistory : "-" }}
                  </p>
                </div>
                <div class="flex-1">
                  <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Social Links</span>
                  <div class="flex gap-3 mt-1 justify-start">
                    <a class="me-2" *ngIf="vendorProfile?.partner?.website != '' && vendorProfile?.partner?.website != undefined" href="{{vendorProfile?.partner?.website}}"
                    target="_blank"><i
                      class="fa-solid fa-globe text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="vendorProfile?.partner?.youtube != '' && vendorProfile?.partner?.youtube != undefined" href="{{vendorProfile?.partner?.youtube}}"
                    target="_blank"><i
                      class="fa-brands fa-youtube text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="vendorProfile?.partner?.linkedin != '' && vendorProfile?.partner?.linkedin != undefined" href="{{vendorProfile?.partner?.linkedin}}"
                    target="_blank"><i
                      class="fa-brands fa-linkedin text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="vendorProfile?.partner?.instagram != '' && vendorProfile?.partner?.instagram != undefined" href="{{vendorProfile?.partner?.instagram}}"
                    target="_blank"><i
                      class="fa-brands fa-instagram text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a *ngIf="vendorProfile?.partner?.facebook != '' && vendorProfile?.partner?.facebook != undefined" href="{{vendorProfile?.partner?.facebook}}"
                    target="_blank"><i
                      class="fa-brands fa-facebook text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white mt-2 px-5 py-2" id="portfolio">
              <span class="mt-1 tracking-wide cursor-pointer text-lg font-semibold">Badges</span>
                <div class="flex">
                  <div class="mr-5">Knowledge Sharing</div>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating == 0.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating == 0.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating >= 1"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 1}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating <= 0.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 1}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating == 1.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating == 1.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating >= 2"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 2}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating <= 1.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 2}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating == 2.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating == 2.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating >= 3"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 3}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating <= 2.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 3}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating == 3.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating == 3.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating >= 4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 4}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating <= 3.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 4}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating == 4.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating == 4.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating >= 5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 5}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.knowledgeSharingRating <= 4.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.knowledgeSharingRating >= 5}"></i>
                </div>

                <div class="flex mt-2">
                  <div class="mr-9">Customer Rating</div>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating == 0.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating == 0.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating >= 1"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 1}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating <= 0.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 1}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating == 1.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating == 1.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating >= 2"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 2}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating <= 1.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 2}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating == 2.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating == 2.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating >= 3"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 3}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating <= 2.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 3}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating == 3.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating == 3.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating >= 4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 4}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating <= 3.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 4}"></i>
                  <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating == 4.5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating == 4.5}"></i>
                  <i class="fa-solid fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating >= 5"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 5}"></i>
                  <i class="fa-regular fa-star ms-1 text-xl text-gray-300"
                    *ngIf="vendorProfile?.partner?.customerRating <= 4.4"
                    [ngClass]="{'text-yellow-400': vendorProfile?.partner?.customerRating >= 5}"></i>
                </div>
            </div>

            <div class="bg-white mt-2 px-5 py-2" id="portfolio">
              <span class="mt-1 tracking-wide cursor-pointer text-lg font-semibold">Portfolio</span>
              @if(vendorProfile?.partner?.portfolio?.length > 0){
              <div class="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                <a *ngFor="let item of vendorProfile?.partner?.portfolio;let index = index"
                  class="p-2 flex gap-2 flex-row items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <img (click)="openpopupModal(item)" class="rounded-t-lg h-20 w-20 md:rounded-none md:rounded-s-lg cursor-pointer" target="_blank"
                    src="{{baseUrl}}/file/retrieve/{{item.photo}}" onerror="this.src='assets/images/image-gallery.png'">
                  <div class="flex flex-col justify-between leading-normal">
                    <h5 class="mb-0 text-lg font-bold tracking-tight text-slate-900 dark:text-white">{{item.title}}</h5>
                    <p class="text-sm text-slate-500">For {{item.clientName}}</p>
                    <p
                      class="mb-2 font-normal text-sm text-gray-700 dark:text-gray-400 break-all truncate-two-lines textContainer{{index}}">
                      {{item.description}}</p>
                      <button (click)="readmore($event, index)" *ngIf="item.description.length > 20" class="read-more-button text-right text-xs me-2">Read more</button>
              
                  </div>
                </a>
              </div>
              
              
              }@else {
              <div class="mt-1 gap-2">No Portfolio Yet</div>
              }
            </div>
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 bg-white">
              <button type="button"
                class="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-600 dark:focus:ring-red-700"
                (click)="hireProposal(vendorProfile)">Hire</button>
              <button type="button"
                class="ms-3 text-gray-500 bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                (click)="closeprofileModal()">Decline</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

  
  <div id="popup-modal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span class="sr-only">Close modal</span>
              </button>
              <div class="bg-gray-100 border border-black rounded-lg shadow">
              <img class="rounded-t-lg  md:rounded-none md:rounded-s-lg  cursor-pointer" target="_blank"
              src="{{baseUrl}}/file/retrieve/{{selectedPortfolio.photo}}" onerror="this.src='assets/images/image-gallery.png'">
            </div>
          </div>
      </div>
  </div>
  

<app-fab-button></app-fab-button>
