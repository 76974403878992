import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Services } from '../core/models/services';
import { AppComponent } from '../app.component';
import { environment } from '../../environments/environment';
import { CommonModule, DOCUMENT, DatePipe, Location, isPlatformBrowser } from '@angular/common';
import { FabButtonComponent } from '../shared/fab-button/fab-button.component';
import { ServiceRequestComponent } from '../shared/service-request/service-request.component';
import { TimePipe } from '../core/pipes/time.pipe';
 
import { Modal, initModals } from 'flowbite';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-partner-profile',
  standalone: true,
  imports:[CommonModule, PartnerProfileComponent, SharedModule, ServiceRequestComponent, TimePipe ],
  providers:[DatePipe],
  templateUrl: './partner-profile.component.html',
  styleUrls: ['./partner-profile.component.scss']
})
export class PartnerProfileComponent implements OnInit, AfterViewInit{

  userCode: any = "";
  user: any = {};
  services: any = [];
  baseUrl = environment.baseURL;
  selectedId: any = "about";

  newServiceRequest:any = '';

  badges:any = [];



    showReadMoreButton: boolean = false;
    isExpanded: boolean = false;

    popupModal:any;
 targetpopupModal:any;

 selectedPortfolio:any = "";
  constructor(public apiS:ApiService, public route:ActivatedRoute,private elementRef: ElementRef, private renderer: Renderer2 ,public appC : AppComponent,
    public locationS:Location, @Inject(DOCUMENT) public document:Document,@Inject(PLATFORM_ID) private platformId: Object){
     
  }
  
  ngAfterViewInit() {
  }

  toggleText() {
    this.isExpanded = !this.isExpanded;
  }

  readmore(event: MouseEvent, index: number) {
    const target = event.target as HTMLElement;
    const textContainer = document.querySelector(`.textContainer${index}`) as HTMLElement;
  
    if (textContainer.classList.contains('truncate-two-lines')) {
      textContainer.classList.remove('truncate-two-lines');
      target.textContent = 'Read less';
    } else {
      textContainer.classList.add('truncate-two-lines');
      target.textContent = 'Read more';
    }
  }
  
  openpopupModal(item:any){
    this.selectedPortfolio=item;
    this.popupModal.show();
  }

  closepopupModal(){
    this.popupModal.hide();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const messagingElement = document.querySelector('.messaging');
      if (messagingElement) {
        messagingElement.classList.remove('hidden');
      }
      initModals();
      this.targetpopupModal =  document.getElementById('popup-modal');
      this.popupModal = new Modal(this.targetpopupModal);
    }
    this.route.params.subscribe(async (data:any) => {
      this.userCode = data.userCode;
      this._fetchData();
      let sData = await this.apiS.getService({},1,100,"").toPromise();
    let services = sData.data;
      this.services = this.getRandomObjectsFromArray(services, 5);
      this.services.forEach((service:any) => {
        this.apiS.getVendorServicesCount(service._id).subscribe((cdata:any)=>{
          service.expertCount=cdata.data.totalCount;
        })
      })
    })
    
  }

  // Function to shuffle an array using Fisher-Yates algorithm
  shuffleArray(array:any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Function to get random 5 objects from the array
  getRandomObjectsFromArray(array:any, count:any) {
    const shuffledArray = this.shuffleArray(array);
    return shuffledArray.slice(0, count);
  }

  _fetchData(){
    this.apiS.getUser({userCode:parseInt(this.userCode)},1,1,"").subscribe(user => {
      this.user = user.data[0];
    })
  }

  scroll(id:any){
    this.selectedId = id;
    const element = this.elementRef.nativeElement.querySelector('#'+id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}

