const UseOfBuilding = [
  { type: 'Row House', fsipercent: 60, openspace: true },
  { type: 'Bungalow', fsipercent: 60, openspace: true },
  { type: 'Bungalow- Semi Detached', fsipercent: 60, openspace: true },
  { type: 'Residential Building (upto G+2 or P+3)', fsipercent: 60, openspace: true },
  { type: 'Residential with Shops / office on Ground', fsipercent: 60, openspace: true },
  { type: 'Any Residential Building', fsipercent: 60, openspace: true },
  { type: 'Hostel', fsipercent: 60, openspace: true },
  { type: 'Residential with Shops / Mix Use', fsipercent: 80, openspace: true },
  { type: 'Residential with offices / Mix Use', fsipercent: 80, openspace: true },
  { type: 'Any Commercial Building', fsipercent: 80, openspace: true },
  { type: 'Office Building with shop', fsipercent: 80, openspace: true },
  { type: 'Office Building without shop', fsipercent: 80, openspace: true },
  { type: 'Hotel', fsipercent: 80, openspace: true },
  { type: 'Restaurant', fsipercent: 80, openspace: true },
  { type: 'Resort', fsipercent: 80, openspace: true },
  { type: 'Public Building', fsipercent: 80, openspace: true },
  { type: 'Semi Public Building', fsipercent: 80, openspace: true },
  { type: 'Institutional', fsipercent: 80, openspace: false },
  { type: 'Preprimary School', fsipercent: 80, openspace: false },
  { type: 'Primary School', fsipercent: 80, openspace: false },
  { type: 'Secondary School', fsipercent: 80, openspace: false },
  { type: 'College Building', fsipercent: 80, openspace: false },
  { type: 'Any other Educational building', fsipercent: 80, openspace: false },
  { type: 'Health Club or clinic', fsipercent: 80, openspace: true },
  { type: 'Maternatiy homes', fsipercent: 80, openspace: true },
  { type: 'Hospital', fsipercent: 80, openspace: true },
  { type: 'Any other Medical building', fsipercent: 80, openspace: true },
  { type: 'Mangal Karyalaya', fsipercent: 80, openspace: true },
  { type: 'Assembly Hall', fsipercent: 80, openspace: true },
  { type: 'Shopping mall', fsipercent: 80, openspace: true },
  { type: 'Multiplex', fsipercent: 80, openspace: true },
  { type: 'Cinema Theatre', fsipercent: 80, openspace: true },
  { type: 'Industrial Building', fsipercent: 80, openspace: true },
];

const Fsi = [
    {
        "title": "Municipal Corporation - Non Conjested",
        "table": [
            {
                "roadWidthStart": 0,
                "roadWidthEnd": 8.99,
                "basicFsi": 1.1,
                "premiumFsi": 0,
                "tdr": 0,
                "total": 1.1
            },
            {
                "roadWidthStart": 9.00,
                "roadWidthEnd": 11.99,
                "basicFsi": 1.1,
                "premiumFsi": 0.5,
                "tdr": 0.4,
                "total": 2.0
            },
            {
                "roadWidthStart": 12.00,
                "roadWidthEnd": 14.99,
                "basicFsi": 1.1,
                "premiumFsi": 0.5,
                "tdr": 0.65,
                "total": 2.25
            },
            {
                "roadWidthStart": 15.00,
                "roadWidthEnd": 23.99,
                "basicFsi": 1.1,
                "premiumFsi": 0.5,
                "tdr": 0.9,
                "total": 2.5
            },
            {
                "roadWidthStart": 24.00,
                "roadWidthEnd": 29.99,
                "basicFsi": 1.1,
                "premiumFsi": 0.5,
                "tdr": 1.15,
                "total": 2.75
            },
            {
                "roadWidthStart": 30.00,
                "roadWidthEnd": 2000,
                "basicFsi": 1.1,
                "premiumFsi": 0.5,
                "tdr": 1.4,
                "total": 3.0
            }
        ]
    },
    {
        "title": "Municipal Corporation - Conjested",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 1.50,
              premiumFsi: 0,
              tdr: 0.00,
              total: 1.50
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 17.99,
              basicFsi: 2.00,
              premiumFsi: 0.30,
              tdr: 0.30,
              total: 2.60
            },
            {
              roadWidthStart: 18.00,
              roadWidthEnd: 29.99,
              basicFsi: 2.00,
              premiumFsi: 0.30,
              tdr: 0.50,
              total: 2.80
            },
            {
              roadWidthStart: 30.00,
              roadWidthEnd: 2000,
              basicFsi: 2.00,
              premiumFsi: 0.30,
              tdr: 0.70,
              total: 3.00
            }
        ]
    },
    {
        "title": "Other - Non Conjested",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 1.1,
              premiumFsi: 0,
              tdr: 0.00,
              total: 1.10
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 11.99,
              basicFsi: 1.1,
              premiumFsi: 0.30,
              tdr: 0.30,
              total: 1.70
            },
            {
              roadWidthStart: 12.00,
              roadWidthEnd: 14.99,
              basicFsi: 1.1,
              premiumFsi: 0.30,
              tdr: 0.60,
              total: 2.00
            },
            {
              roadWidthStart: 15.00,
              roadWidthEnd: 23.99,
              basicFsi: 1.1,
              premiumFsi: 0.30,
              tdr: 0.70,
              total: 2.10
            },
            {
              roadWidthStart: 24.00,
              roadWidthEnd: 29.99,
              basicFsi: 1.1,
              premiumFsi: 0.30,
              tdr: 0.90,
              total: 2.30
            },
            {
              roadWidthStart: 30.00,
              roadWidthEnd: 2000,
              basicFsi: 1.1,
              premiumFsi: 0.30,
              tdr: 1.10,
              total: 2.50
            }
        ]
    },
    {
        "title": "Other - Conjested",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 1.50,
              premiumFsi: 0,
              tdr: 0.00,
              total: 1.50
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 17.99,
              basicFsi: 2.00,
              premiumFsi: 0.30,
              tdr: 0.10,
              total: 2.40
            },
            {
              roadWidthStart: 18.00,
              roadWidthEnd: 29.99,
              basicFsi: 2.00,
              premiumFsi: 0.30,
              tdr: 0.20,
              total: 2.50
            },
            {
              roadWidthStart: 30.00,
              roadWidthEnd: 2000,
              basicFsi: 2.00,
              premiumFsi: 0.30,
              tdr: 0.20,
              total: 2.50
            }
        ]
    },
    {
        "title": "Pune-PCMC Metro",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 1.1,
              premiumFsi: 0,
              tdr: 0.00,
              total: 1.10
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 11.99,
              basicFsi: 1.1,
              premiumFsi: 1.050,
              tdr: 0.350,
              total: 2.50
            },
            {
              roadWidthStart: 12.01,
              roadWidthEnd: 14.99,
              basicFsi: 1.1,
              premiumFsi: 1.425,
              tdr: 0.475,
              total: 3.00
            },
            {
              roadWidthStart: 15.00,
              roadWidthEnd: 23.99,
              basicFsi: 1.1,
              premiumFsi: 1.800,
              tdr: 0.600,
              total: 3.50
            },
            {
              roadWidthStart: 24.00,
              roadWidthEnd: 2000,
              basicFsi: 1.1,
              premiumFsi: 2.175,
              tdr: 0.725,
              total: 4.00
            }
        ]
    },
    {
        "title": "Nagpur Metro Rail Corridor - TOD",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 1.1,
              premiumFsi: 0,
              tdr: 0.00,
              total: 1.10
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 11.99,
              basicFsi: 1.1,
              premiumFsi: 1.050,
              tdr: 0.350,
              total: 2.50
            },
            {
              roadWidthStart: 12.00,
              roadWidthEnd: 14.99,
              basicFsi: 1.1,
              premiumFsi: 1.425,
              tdr: 0.475,
              total: 3.00
            },
            {
              roadWidthStart: 15.00,
              roadWidthEnd: 23.99,
              basicFsi: 1.1,
              premiumFsi: 1.800,
              tdr: 0.600,
              total: 3.50
            },
            {
              roadWidthStart: 24.00,
              roadWidthEnd: 2000,
              basicFsi: 1.1,
              premiumFsi: 2.175,
              tdr: 0.725,
              total: 4.00
            }
        ]
    },
    {
        "title": "Industrial building in industrial zone",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 0.0,
              premiumFsi: 0.00,
              tdr: 0.00,
              total: 0.00
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 2000,
              basicFsi: 1.0,
              premiumFsi: 0.40,
              tdr: 0.000,
              total: 1.40
            }
        ]
    },
    {
        "title": "PCMC BRT Corridor",
        "table": [
            {
              roadWidthStart: 0,
              roadWidthEnd: 8.99,
              basicFsi: 1.0,
              premiumFsi: 0,
              tdr: 0.00,
              total: 1.00
            },
            {
              roadWidthStart: 9.00,
              roadWidthEnd: 11.99,
              basicFsi: 1.0,
              premiumFsi: 0.50,
              tdr: 0.75,
              total: 2.25
            },
            {
              roadWidthStart: 12.00,
              roadWidthEnd: 14.99,
              basicFsi: 1.0,
              premiumFsi: 0.50,
              tdr: 1.00,
              total: 2.50
            },
            {
              roadWidthStart: 15.00,
              roadWidthEnd: 23.99,
              basicFsi: 1.0,
              premiumFsi: 0.50,
              tdr: 1.25,
              total: 2.75
            },
            {
              roadWidthStart: 24.00,
              roadWidthEnd: 29.99,
              basicFsi: 1.0,
              premiumFsi: 0.50,
              tdr: 1.50,
              total: 3.00
            },
            {
              roadWidthStart: 30.00,
              roadWidthEnd: 2000,
              basicFsi: 1.0,
              premiumFsi: 0.50,
              tdr: 1.75,
              total: 3.25
            }
        ]
    }
];

export {
  UseOfBuilding,
    Fsi
};