<div class="">
  <div class="flex flex-col {{isModal?'mt-10 ':'pt-10'}}">
    <div class="flex-auto flex justify-center mb-2">
      <h3 class="flex-none text-xl tracking-wider text-white font-semibold">
        {{(!isResult)?'FSI Calculator':'FSI Calculator Result'}}</h3><br>

    </div>

  </div>
  <div class=" flex-auto flex justify-center {{isModal?'m-10 mt-5':'h-screen'}}" *ngIf="!isResult">
    <div
      class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-6/12 h-4/6'}} overflow-auto rounded-3xl">
      <div class="m-5 h-4/5 overflow-auto">


        <div [class]="(currentStep !== 1)?'hidden':''">
          <div>
            <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm mb-1 text-left tracking-wide text-slate-700">Use of Building <span
                    class="text-red-600">*</span></p>
                <div class="border border-gray-300 rounded">
                  <select [(ngModel)]="useBuilding"
                    class="block w-full h-[40px] rounded border-0 p-2 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                    <option *ngFor="let item of useOfBuildings" value="{{item.type}}">{{item.type}}</option>
                  </select>
                </div>

              </div>

              <div class="relative rounded-md  mb-3">
                <p class="text-sm mb-1 text-left tracking-wide text-slate-700">Rules Applicability <span
                    class="text-red-600">*</span></p>
                <div class="border border-gray-300 rounded">
                  <select [(ngModel)]="rulesApplicability"
                    class="block w-full h-[40px] rounded border-0 p-2 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                    <option value="Pune-PCMC Metro">Pune-PCMC Metro</option>
                    <option value="Municipal Corporation - Non Conjested">Municipal Corporation - Non Conjested</option>
                    <option value="Municipal Corporation - Conjested">Municipal Corporation - Conjested</option>
                    <option value="Other - Non Conjested">Other - Non Conjested</option>
                    <option value="Other - Conjested">Other - Conjested</option>
                    <option value="Industrial building in industrial zone">Industrial building in industrial zone
                    </option>
                    <option value="PCMC BRT Corridor">PCMC BRT Corridor</option>
                    <option value="Nagpur Metro Rail Corridor - TOD">Nagpur Metro Rail Corridor - TOD</option>
                  </select>
                </div>
              </div>
            </div>
            <hr class="my-3">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">What is your plot area? <span
                    class="text-red-600">*</span></p>
                <div class="flex border rounded" [class]="(isNext && !plotArea)?'border-red-500':'border-gray-300'">
                  <div class="flex-1">
                    <input [(ngModel)]="plotArea" type="number" name="price" id="price"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="plotAreaUnit"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Sq. M.">Sq. M.</option>
                      <option value="Sq. Ft.">Sq. Ft.</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Total Area of deduction</p>
                <div class="flex border rounded">
                  <div class="flex-1">
                    <input [(ngModel)]="deductionArea" type="number" name="price" id="price"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="deductionAreaUnit"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Sq. M.">Sq. M.</option>
                      <option value="Sq. Ft.">Sq. Ft.</option>
                    </select>
                  </div>
                </div>
                <p class="text-xs mt-0.5 text-yellow-400">Note: (eg- area under Road widening, reservation, Nala
                  etc
                  )</p>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">What is Width of Access Road? <span
                    class="text-red-600">*</span></p>
                <div class="flex border rounded" [class]="(isNext && !widthRoad)?'border-red-500':'border-gray-300'">
                  <div class="flex-1">
                    <input [(ngModel)]="widthRoad" type="number" name="price" id="price"
                      class="block w-full rounded border-0 py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  </div>
                  <div>
                    <select [(ngModel)]="widthRoadUnit"
                      class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-5 text-gray-600 focus:outline-none sm:text-sm">
                      <option value="Meter">Meter</option>
                      <option value="Feet">Feet</option>
                    </select>
                  </div>
                </div>
                <p class="text-xs mt-0.5 text-yellow-400">Note: Width of road in DP Plan or RP plan to be
                  mentioned
                  here</p>
              </div>


            </div>
          </div>
        </div>

        <div [class]="(currentStep !== 2)?'hidden':''">
          <legend class="text-base mb-2 font-bold tracking-wide text-slate-700">To get Full FSI you need one of
            following</legend>
          <div>
            <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm mb-1 text-left tracking-wide text-slate-700">My plot is from sanctioned Layout</p>
                <select [(ngModel)]="sanctionedLayout"
                  class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">I will leave 10% open space (min 200 SqM)
                </p>
                <select [(ngModel)]="openSpace"
                  class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">I will pay 10% of my land cost as per
                  government rate</p>
                <select [(ngModel)]="myLandCost"
                  class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

            </div>
            <hr class="my-3">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Is it redevelopment Proposal?</p>
                <select [(ngModel)]="redevelopementProposal"
                  class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div class="relative rounded-md  mb-3 ">
                <p class="text-sm text-left mb-1 tracking-wide text-slate-700">Do you have gunthewari certificate of
                  plot?</p>
                <div class="border border-gray-300 rounded">
                  <select [(ngModel)]="gunthewariCertificate"
                    class="block w-full h-[40px] rounded border-0 py-2 pl-2 pr-20 text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div *ngIf="currentStep == 3  && !isProject  && !isGoogleAuth">
          <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free quotes from
            partners</legend>
          <div class="w-full mt-3">
            <div class="grid w-full">
              <div class="relative rounded-md  mb-3">
                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheck($event)"
                  (input)="onMobileInput($event)" maxlength="10" pattern="[0-9]{10}" [(ngModel)]="mobile" name="mobile"
                  focuse="true" id="mobile"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
                <p class="text-slate-500 text-xs flex items-center mt-1">
                  <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                  <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy"
                      target="_blank">Privacy Policy</a></span>
                </p>
              </div>

              <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                <p class="text-sm text-left tracking-wide text-slate-700">Name</p>
                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="name" name="name" focuse="true" id="name"
                  [class]="(isNext && name == '')?'ring-red-500':''"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
              </div>

              <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                <p class="text-sm text-left tracking-wide text-slate-700">Email</p>
                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="email" name="name" focuse="true" id="name"
                  [class]="(isNext && email == '')?'ring-red-500':''"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
              </div>

              <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTP">
                <legend class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">Enter OTP
                </legend>
                <p class="text-slate-500 text-xs text-center mt-0">
                  <span class="ml-1">OTP sent on text sms</span>
                </p>
                <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}">
                </ng-otp-input>
              </div>
            </div>
            <div aria-label="Toast" class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
              *ngIf="error">
              <div class="p-4 rounded-md">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                      class="nu rw ayv">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="ms-3">
                    <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with
                      your submission</h3>
                    <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessage}}</p>
                  </div>
                </div>
              </div>
              <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearError()"
                *ngIf="errorCancelAble"><i class="fa-solid fa-xmark text-orange-600"></i></div>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep == 3 && !isProject && isGoogleAuth">
          <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free quotes from
            partners</legend>
          <div class="w-full mt-3">
            <div class="grid w-full">
              <div class="relative rounded-md  mb-3">
                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheckModal($event)"
                  (input)="onMobileInputModal($event)" maxlength="10" pattern="[0-9]{10}" [(ngModel)]="mobile"
                  name="mobile" focuse="true" id="mobile"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="">
                <p class="text-slate-500 text-xs flex items-center mt-1">
                  <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                  <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy"
                      target="_blank">Privacy Policy</a></span>
                </p>

              </div>

              <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTPModal">
                <legend class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">Enter OTP
                </legend>
                <p class="text-slate-500 text-xs text-center mt-0">
                  <span class="ml-1">OTP sent on text sms</span>
                </p>
                <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}">
                </ng-otp-input>
              </div>
            </div>
            <div aria-label="Toast" class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
              *ngIf="errorModal">
              <div class="p-4 rounded-md">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                      class="nu rw ayv">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div class="ms-3">
                    <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with
                      your submission</h3>
                    <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessageModal}}</p>
                  </div>
                </div>
              </div>
              <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearErrorModal()"
                *ngIf="errorCancelAbleModal"><i class="fa-solid fa-xmark text-orange-600"></i></div>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep == 3  && isProject">
          <legend class="text-sm text-left font-bold tracking-wide text-slate-700">Select Project</legend>
          <div class="mt-2 space-y-6 mb-2">
            <ng-container>
              <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-3">
                @if(projects.length > 0){
                <div *ngFor="let option of projects; let i = index" class="bg-[#F5F5F5] border rounded"
                  [class]="(isNext && !projectId)? 'border-red-500':'border-[#aaa]'"
                  [ngClass]="{'py-1 px-2': (option.editProjects), 'p-2': (!option.editProjects) }">
                  <div class="flex items-center gap-x-3" *ngIf="!option.editProjects">
                    <input [id]="i" type="radio" [checked]="option.isDefault" (change)="getProjectName($event,false)"
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      [(ngModel)]="projectId" [value]="option._id" [name]="'question' + i">

                    <label [for]="i" class="cursor-pointer block w-full text-sm font-medium leading-6 text-gray-900">{{
                      option.title }}</label>
                    <i class="fa-regular fa-pen-to-square" (click)="editProject(option,i)"></i>
                  </div>
                  <div class="flex" *ngIf="option.editProjects">
                    <input type="text" id="input{{i}}"
                      class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      [(ngModel)]="option.title" placeholder="Enter Project Name">
                    <span (click)="editProjectName(option)"
                      class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <i class="fa-solid fa-check" style="margin: auto; color: #4972ef; font-size: larger;"></i>
                    </span>
                  </div>
                </div>
                }
                <div>
                  <div class="flex items-center gap-x-3" *ngIf="!Projects">
                    <label
                      class="cursor-pointer block  text-sm font-medium leading-6 text-gray-900 bg-[#F5F5F5] rounded p-2 w-full text-center"
                      style="border: 1px solid blue;" (click)="AddProject()"><i class="fa-solid fa-plus me-2"></i>Add
                      Project</label>
                  </div>

                  <div class="grid grid-row-2 items-center gap-x-3 bg-[#F5F5F5] border rounded-lg"
                    [ngClass]="{'py-1 px-2': (Projects), 'p-2': (!Projects) }" *ngIf="Projects"
                    style="border: 1px solid gray;">
                    <div class="flex" *ngIf="Projects">
                      <input type="text"
                        class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        [(ngModel)]="newProjectName" placeholder="Enter Project Name">
                      <span (click)="createdNewProject()" *ngIf="!newProjectStatus"
                        class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <i class="fa-solid fa-check" style="margin: auto; color: #4972ef; font-size: larger;"></i>
                      </span>
                      <span *ngIf="newProjectStatus"
                        class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <div role="status">
                          <svg aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor" />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <p class="text-red-600 text-xs">You can also rename of projects from “All projects” tab in Menu bar</p>
          </div>
        </div>




      </div>


      <div class="absolute inset-x-0 bottom-0 bg-white">
        <div class="flex gap-x-2 mx-5 my-2">
          <button [disabled]="isLoading" *ngIf="currentStep != 1" (click)="backStep()"
            class="flex-none  w-1/6 p-1.5 rounded border-slate-400 bg-slate-200 hover:bg-slate-300 text-slate-700 text-center border"><i
              class="fa-solid fa-chevron-left"></i></button>
          <!-- <button [disabled]="isLoading" [class]="(currentStep==0) ? 'w-full':'w-full'"
            (click)="currentStep == 1 ? nextStep() : (!isOTP && !userLoggedIn && currentStep == 3) ? sendOTP() : onSubmit()"
            class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
            <ng-container *ngIf="currentStep == 1">Next</ng-container>
            <ng-container *ngIf="!isOTP && !userLoggedIn && currentStep == 3">Send OTP</ng-container>
            <ng-container *ngIf="currentStep == 2">{{(!edit)?'Submit':'Update'}}</ng-container>
            <ng-container *ngIf="isOTP && currentStep == 3">Submit</ng-container>
          </button> -->
          <ng-container *ngIf="projectId">
            <button [disabled]="isLoading"
              (click)="currentStep == 1 ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3) ? sendOTP() : onSubmit()"
              [class]="(currentStep==0) ? 'w-full':'w-full'"
              class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
              <!-- <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
            <ng-container *ngIf="!isOTP && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP</ng-container>
              <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP))">Submit</ng-container> -->

              <ng-container *ngIf="currentStep == 1">Next</ng-container>
              <ng-container *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3">Send
                OTP</ng-container>
              <ng-container
                *ngIf="!isOTP && !isOTPModal && userLoggedIn && !isProject && currentStep == 3">Submit</ng-container>
              <ng-container *ngIf="currentStep == 2">{{(!edit)?'Submit':'Update'}}</ng-container>
              <ng-container
                *ngIf="(isOTP || isOTPModal) && userLoggedIn && isProject && currentStep == 3">Submit</ng-container>
              <ng-container
                *ngIf="!isOTP && !isOTPModal && userLoggedIn && isProject &&  currentStep == 3">Submit</ng-container>
            </button>
          </ng-container>
          <ng-container *ngIf="!projectId">
            <button [disabled]="isLoading"
              (click)="currentStep == 1 ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3) ? sendOTP() : ((isOTP || isOTPModal || userLoggedIn) && !isProject && currentStep == 3) ? nextSlide() :onSubmit()"
              [class]="(currentStep==0) ? 'w-full':'w-full'"
              class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
              <!-- <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
            <ng-container *ngIf="!isOTP && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP</ng-container>
              <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP) && isProject)">Submit</ng-container>
            <ng-container *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP) && !isProject)">Next</ng-container> -->

              <ng-container *ngIf="currentStep == 1">Next</ng-container>
              <ng-container *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == 3">Send
                OTP</ng-container>
              <ng-container *ngIf="currentStep == 2 && ((userLoggedIn || isOTP || isOTPModal) && isProject)">
                {{(!edit)?'Submit':'Update'}}</ng-container>
              <ng-container
                *ngIf="currentStep == 2 && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next</ng-container>
              <ng-container
                *ngIf="currentStep == 2 && ((!userLoggedIn && !isOTP && !isOTPModal) && !isProject)">Next</ng-container>
              <ng-container
                *ngIf="currentStep == 3 && ((userLoggedIn || isOTP || isOTPModal) && isProject)">Submit</ng-container>
              <ng-container
                *ngIf="currentStep == 3 && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next</ng-container>
            </button>
          </ng-container>
          <h3 class="text-center my-3" *ngIf="!userLoggedIn && currentStep == 3 && !isGoogleAuth">OR</h3>
          <asl-google-signin-button type="standard" *ngIf="!userLoggedIn && currentStep == 3 && !isGoogleAuth"
            text="signin_with" logo_alignment="center" size='large'></asl-google-signin-button>
          <!--  -->
        </div>
      </div>

    </div>

  </div>
  <div class="flex-auto flex justify-center {{isModal?'m-10 mt-5':'h-screen'}}" *ngIf="isResult">
    <div
      class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-6/12 h-4/6'}} overflow-auto rounded-3xl">
      <div class="m-5 h-4/5 overflow-auto">
        <div class="px-4 py-6">
          <div class="mt-3">
            <span class="tracking-wider text-lg font-semibold">Calculations</span>
            <button
              class="w-auto float-right text-sm px-1.5 rounded my-1 font-normal border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white text-center border"
              (click)="openCalculatorModal()">Edit</button>
            <button
              class="w-auto sm:float-right text-sm px-1.5 rounded my-1 me-2 font-normal border-primary text-primary hover:bg-primary hover:text-white text-center border"
              (click)="download()">Download</button>
          </div>

          <div class="relative w-full inline-block mb-1 mt-3 bg-white rounded-xl border">
            <div class="overflow-x-auto">
              <table class="min-w-full indent-0 border-inherit border-collapse ">
                <thead>
                  <tr class="border-b-gray-300 border-b">
                    <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 border-r-gray-300 border-r">
                    </th>
                    <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 border-r-gray-300 border-r">
                      AREA STATEMENT</th>
                    <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900 border-r-gray-300 border-r">Sq.
                      M.</th>
                    <th class="py-2.5 px-2 text-left text-sm font-semibold text-gray-900">Sq. Ft.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Access Road Width in Meter</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.accessRoadWidthInMeter}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      1</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      <b>Area Of Plot</b>
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.areaOfPlotSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.areaOfPlotSqft}}</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      2</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Deduction for road / reservation</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.deductionRoadSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.deductionRoadSqft}}</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      3</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Balance Area of Plot (Gross Plot)</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.balanceAreaPlotSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.balanceAreaPlotSqft}}</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      4</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Amenity Space Required</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.amenitySpaceRequired}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Required Amenity Space</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.requiredAmenitySpaceSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.requiredAmenitySpaceSqft}}</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      5</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      <b>Net Area Of Plot (3 - 4)</b>
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.netAreaPlotSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.netAreaPlotSqft}}</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      6</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Open Space Required</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.openSpaceRequired}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Required Open Space / Play Ground</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.requiredOpenSpaceSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.requiredOpenSpaceSqft}}</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      7</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Internal Road area as per proposed plan</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      -</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">-</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      8</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Applicable Rules {{selectedItem.rulesApplicability}}</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      -</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">-</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      9</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Basic FSI as per front road width</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.netAreaPlotSqm}} x {{selectedItem.basicFsi}} x {{selectedItem.basicFsiFactor}}%
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.basicFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.basicFsiSqft}}</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      10</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Addition of FSI on payment of premium</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.areaOfPlotSqm}} x {{selectedItem.premiumFsi}} x {{selectedItem.premiumFsiFactor}}%
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.additionFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.additionFsiSqft}}</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      11</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      TDR FSI (including TDR to buy & in-situ FSI generated on site)</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.areaOfPlotSqm}} x {{selectedItem.tdrFsi}} x {{selectedItem.tdrFactor}}%</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.tdrFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.tdrFsiSqft}}</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      12</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Additional FSI under chapter no 7</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      a. Redevelopment inentive FSI {{selectedItem.areaOfPlotSqm}} x
                      {{selectedItem.redevelopementPercent}}%</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.redevelopementFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.redevelopementFsiSqft}}</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.additionalFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.additionalFsiSqft}}</td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      13</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Ancillary FSI</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      a. Total FSI (9+10+11 or 12 whichever is applicable)</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.totalFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.totalFsiSqft}}</td>
                  </tr>
                  <tr class="border-b-gray-300 border-b">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                    </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      b. Ancillary FSI ({{selectedItem.useBuilding}} {{selectedItem.anciliaryFsiPercent}}%)</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.anciliaryFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap"></td>
                  </tr>
                  <tr class="">
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      14</td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      Total FSI Including Ancillary FSI </td>
                    <td
                      class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap border-r-gray-300 border-r">
                      {{selectedItem.totalFsiIncludeAnciliaryFsiSqm}}</td>
                    <td class="py-2.5 px-2 text-left text-sm font-medium text-gray-900 whitespace-nowrap">
                      {{selectedItem.totalFsiIncludeAnciliaryFsiSqft}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>
        <div class="absolute inset-x-0 bottom-0 bg-white">
          <div class="text-center gap-x-2 mx-5 my-2 border-b">
            <!-- <button (click)="navigate()"
              class="flex-initial p-1.5 disabled:text-blue-200 disabled:cursor-not-allowed rounded text-primary hover:text-blue-500 text-center">
              Visit {{projectName}}
            </button> -->
            <button (click)="navigate()"
              class="w-auto py-1.5 px-3 me-2 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
              Visit {{projectName}}</button>

            <button (click)="download()"
              class="w-auto py-1.5 px-3 me-2 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
              Download</button>

            <button (click)="openCalculatorModal()"
              class="w-auto py-1.5 px-3 rounded my-1 border-[#6B8FFA] hover:bg-[#4d77f7] hover:text-white font-normal text-sm text-[#6B8FFA] text-center border">
              Edit</button>

            <!--  -->
          </div>
          <div class="text-center my-2">
            <p class="text-sm">Other Calculators</p>
            <div class="grid md:flex justify-evenly">
              <a class="text-primary cursor-pointer text-xs" [routerLink]="['/side-margin-calculator']">Side Margin</a>
              <a class="text-primary cursor-pointer text-xs">Developement Charges</a>
              <a class="text-primary cursor-pointer text-xs">Water Requirement</a>
              <a class="text-primary cursor-pointer text-xs">TDR</a>
              <a class="text-primary cursor-pointer text-xs">Parking</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>