<div class="bg-[#F1F2F2] py-0">
  <div class="container">
    <div class="grid grid-cols-4 gap-2">
      <div class="col-span-4 lg:col-span-3">
        <div class="bg-white relative">
          <!-- <div class="absolute top-5 left-3 cursor-pointer" (click)="locationS.back()">
            <i class="fa-regular fa-circle-left me-1"></i>
            <span>Back</span>
          </div> -->
          <img src="assets/images/partnerBackground.webp" class="-mt-3" width="100%" />
          <div class="bg-white shadow-md p-1 absolute bottom-24 lg:bottom-2 left-3 rounded-full">
            <img src="{{ baseUrl }}/file/retrieve/{{ user?.photo }}" onerror="this.src='assets/images/user.webp'"
              width="80px" class="rounded-full" />
          </div>
          <div class="ms-28 pb-4">
            <div class="flex">
              <div class="flex-1">
                <h3 class="text-lg font-semibold tracking-wide text-slate-700">
                  {{ user?.name }}
                </h3>
                <p class="text-base font-light tracking-wide text-slate-700">
                  {{ user?.city ? user?.city : "-" }}
                  {{ user?.state ? ", " + user?.state : "" }}
                  {{ user?.pincode ? " - " + user?.pincode : "" }}
                </p>
              </div>
              <div class="flex-none hidden md:flex">
                <div class="flex flex-col mt-3 me-5">
                  <button class="text-2xl font-light tracking-wider">
                    <i class="text-yellow-400 fa-solid fa-star"></i>
                    {{ user?.customerRating }} / 5
                  </button>
                  <!-- <span class="text-xs font-light">Based on 10 Reviews</span> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex mt-8 pb-2">
            <button class="px-3 py-1 bg-[#DBE4FF] hover:bg-[#becbf3] font-light rounded mx-1 ms-3"><i
                class="fa-regular fa-message me-1"></i> Write Review</button>
            <button class="px-3 py-1 bg-[#DBE4FF] hover:bg-[#becbf3] font-light rounded mx-1"><i
                class="fa-solid fa-share-nodes me-1"></i> Share</button>
          </div> -->
          <div class="p-4 lg:hidden">
            <button class="py-2 w-full bg-red-500 hover:bg-red-600 rounded text-white font-semibold text-sm"
            (click)="appC.openServiceRequestModal('');">ASK ME QUOTE</button>
          </div>
        </div>

        <div class="bg-white mt-2 px-5 pt-2" id="about">
          <span class="mt-1 tracking-wide cursor-pointer text-lg font-semibold">Overview</span>
          <p class="font-light mt-1 text-base pb-4 tracking-wide border-b-2 border-b-gray-300">
            {{ user?.overview ? user?.overview : "-" }}
          </p>
          <!-- <div class="flex mt-3">
            <div class="flex-1">
              <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Email</span>
              <p class="font-light text-base pb-4 tracking-wide">{{user?.email}}</p>
            </div>
            <div class="flex-1">
              <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Mobile</span>
              <p class="font-light text-base pb-4 tracking-wide">{{user?.mobile}}</p>
            </div>
          </div> -->
          <div class="flex mt-1">
            <div class="flex-1">
              <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Specialized In</span>
              <p class="font-light text-base pb-4 tracking-wide">
                <span *ngFor="let item of user?.services; let index = index">{{ item.displayText
                  }}<span *ngIf="user?.services.length - 1 != index">,
                  </span></span>
              </p>
            </div>
            <div class="flex-1">
              <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Partner Type</span>
              <p class="font-light text-base pb-4 tracking-wide">
                {{ user?.userType }}
              </p>
            </div>
          </div>
          <div class="flex mt-1">
            <div class="flex-1">
              <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Work History</span>
              <p class="font-light text-base pb-4 tracking-wide">
                {{ user?.workHistory ? user?.workHistory : "-" }}
              </p>
            </div>
            <div class="flex-1">
              <span class="mt-1 tracking-wide cursor-pointer text-base font-semibold">Social Links</span>
              <div class="flex gap-3 mt-1 justify-start">

                    <a class="me-2" *ngIf="user?.website != '' && user?.website != undefined" href="{{user?.website}}"
                    target="_blank"><i
                      class="fa-solid fa-globe text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="user?.youtube != '' && user?.youtube != undefined" href="{{user?.youtube}}"
                    target="_blank"><i
                      class="fa-brands fa-youtube text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="user?.linkedin != '' && user?.linkedin != undefined" href="{{user?.linkedin}}"
                    target="_blank"><i
                      class="fa-brands fa-linkedin text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a class="me-2" *ngIf="user?.instagram != '' && user?.instagram != undefined" href="{{user?.instagram}}"
                    target="_blank"><i
                      class="fa-brands fa-instagram text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
                  <a *ngIf="user?.facebook != '' && user?.facebook != undefined" href="{{user?.facebook}}"
                    target="_blank"><i
                      class="fa-brands fa-facebook text-2xl cursor-pointer text-gray-400 hover:text-[#4d77f7]"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white mt-2 px-5 py-2" id="portfolio">
          <span class="mt-1 tracking-wide cursor-pointer text-lg font-semibold">Badges</span>
          <div class="flex">
          <div class="mr-5">Knowledge Sharing</div>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating == 0.5" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating == 0.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating >= 1" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 1}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating <= 0.4" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 1}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating == 1.5" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating == 1.5}"></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating >= 2" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 2}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating <= 1.4" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 2}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"*ngIf="user.knowledgeSharingRating == 2.5" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating == 2.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating >= 3" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 3}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating <= 2.4" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 3}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"  *ngIf="user.knowledgeSharingRating == 3.5" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating == 3.5}"></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating >= 4" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 4}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating <= 3.4" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 4}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating == 4.5" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating == 4.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating >= 5" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 5}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.knowledgeSharingRating <= 4.4" [ngClass]="{'text-yellow-400': user?.knowledgeSharingRating >= 5}"></i>
        </div>

        <div class="flex mt-2">
          <div class="mr-9">Customer Rating</div>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.customerRating == 0.5" [ngClass]="{'text-yellow-400': user?.customerRating == 0.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating >= 1" [ngClass]="{'text-yellow-400': user?.customerRating >= 1}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating <= 0.4" [ngClass]="{'text-yellow-400': user?.customerRating >= 1}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.customerRating == 1.5" [ngClass]="{'text-yellow-400': user?.customerRating == 1.5}"></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating >= 2" [ngClass]="{'text-yellow-400': user?.customerRating >= 2}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating <= 1.4" [ngClass]="{'text-yellow-400': user?.customerRating >= 2}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"*ngIf="user.customerRating == 2.5" [ngClass]="{'text-yellow-400': user?.customerRating == 2.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating >= 3" [ngClass]="{'text-yellow-400': user?.customerRating >= 3}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating <= 2.4" [ngClass]="{'text-yellow-400': user?.customerRating >= 3}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"  *ngIf="user.customerRating == 3.5" [ngClass]="{'text-yellow-400': user?.customerRating == 3.5}"></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating >= 4" [ngClass]="{'text-yellow-400': user?.customerRating >= 4}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating <= 3.4" [ngClass]="{'text-yellow-400': user?.customerRating >= 4}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.customerRating == 4.5" [ngClass]="{'text-yellow-400': user?.customerRating == 4.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating >= 5" [ngClass]="{'text-yellow-400': user?.customerRating >= 5}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.customerRating <= 4.4" [ngClass]="{'text-yellow-400': user?.customerRating >= 5}"></i>
        </div>
        <div class="flex mt-2">
          <div class="mr-9">Response Rating</div>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.responseRating == 0.5" [ngClass]="{'text-yellow-400': user?.responseRating == 0.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating >= 1" [ngClass]="{'text-yellow-400': user?.responseRating >= 1}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating <= 0.4" [ngClass]="{'text-yellow-400': user?.responseRating >= 1}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.responseRating == 1.5" [ngClass]="{'text-yellow-400': user?.responseRating == 1.5}"></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating >= 2" [ngClass]="{'text-yellow-400': user?.responseRating >= 2}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating <= 1.4" [ngClass]="{'text-yellow-400': user?.responseRating >= 2}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"*ngIf="user.responseRating == 2.5" [ngClass]="{'text-yellow-400': user?.responseRating == 2.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating >= 3" [ngClass]="{'text-yellow-400': user?.responseRating >= 3}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating <= 2.4" [ngClass]="{'text-yellow-400': user?.responseRating >= 3}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300"  *ngIf="user.responseRating == 3.5" [ngClass]="{'text-yellow-400': user?.responseRating == 3.5}"></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating >= 4" [ngClass]="{'text-yellow-400': user?.responseRating >= 4}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating <= 3.4" [ngClass]="{'text-yellow-400': user?.responseRating >= 4}"></i>
          <i class="fa-solid fa-star-half-stroke ms-1 text-xl text-gray-300" *ngIf="user.responseRating == 4.5" [ngClass]="{'text-yellow-400': user?.responseRating == 4.5}" ></i>
          <i class="fa-solid fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating >= 5" [ngClass]="{'text-yellow-400': user?.responseRating >= 5}"></i>
          <i class="fa-regular fa-star ms-1 text-xl text-gray-300" *ngIf="user.responseRating <= 4.4" [ngClass]="{'text-yellow-400': user?.responseRating >= 5}"></i>
        </div>
        </div>
        

        <div class="bg-white mt-2 px-5 py-2" id="portfolio">
          <span class="mt-1 tracking-wide cursor-pointer text-lg font-semibold">Portfolio</span>
          @if(user?.portfolio?.length > 0){
            
            <div class="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3 ">
              @for( data of user?.portfolio;track data._id;let  i = $index){
                  <a class="p-2 flex gap-2 flex-row items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <img (click)="openpopupModal(data)" class="rounded-t-lg h-20 w-20 md:rounded-none md:rounded-s-lg cursor-pointer" target="_blank"
                      src="{{baseUrl}}/file/retrieve/{{data.photo}}" onerror="this.src='assets/images/image-gallery.png'">
                    <div class="flex flex-col justify-between leading-normal">
                      <h5 class="mb-0 text-lg font-bold tracking-tight text-slate-900 dark:text-white">{{data.title}}</h5>
                      <p class="text-sm text-slate-500">For {{data.clientName}}</p>
                      <p
                        class="mb-2 font-normal text-sm text-gray-700 dark:text-gray-400 break-all truncate-two-lines textContainer{{i}}">
                        {{data.description}}</p>
                        <button (click)="readmore($event, i)" *ngIf="data.description.length > 20" class="read-more-button text-right text-xs me-2">Read more</button>
                
                    </div>
                  </a>
              }
            
          </div>
          }@else {
          <div class="mt-1 gap-2">No Portfolio Yet</div>
          }
        </div>
      </div>
      <div class="hidden lg:block m-2">
        <div class="bg-white p-2 mt-3 pb-5">
          <h3 class="font-semibold text-slate-800 tracking-wide text-lg">
            Request a Quote
          </h3>
          <p class="text-base tracking-wider font-light">Send Enquire</p>

          <div class="flex mt-3">
            <!-- <div class="flex-1">
                            <p class="font-light text-sm">Response Time</p>
                            <p class="text-green-600 font-normal text-base">Within 15 mins</p>
                        </div> -->
            <div class="flex-1">
              <p class="font-light text-sm">Working Hours</p>
              <p class="text-green-600 font-normal text-base">
                {{ user?.fromTime | time }} to {{ user?.toTime | time }}
              </p>
            </div>
          </div>

          <div class="mt-6">
            <button class="py-2 w-full bg-red-500 hover:bg-red-600 rounded text-white font-semibold text-sm"
            (click)="appC.openServiceRequestModal('');">ASK ME QUOTE</button>
          </div>
        </div>
        <h3 class="py-2 font-bold text-slate-600">Get a Free Quote</h3>

        <div *ngFor="let service of services" (click)="appC.openServiceRequestModal(service.url)">
          <div class="relative rounded-lg flex-shrink-0">
            <img [alt]="service.title" [src]="baseUrl+'/file/retrieve/' + service.largeIcon"
              class="h-36 md:h-32 lg:h-28 object-center rounded-lg" width="100%" />
          </div>
          <div class="mb-7">
            <div class="px-1 py-1">
              <h4 class="text-base font-semibold tracking-wide">
                {{ service.title }}
              </h4>
              <p class="text-sm tracking-wider font-semibold leading-normal text-amber-500">
                {{ service.expertCount }} Experts
              </p>
            </div>
            <div class="mt-auto">
              <button routerLinkActive="router-link-active"
                class="w-full p-1.5 rounded my-1 border-primary hover:bg-primary hover:text-white font-normal text-sm text-primary text-center border">
                Get Quotes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fab-button></app-fab-button>

<div id="popup-modal" tabindex="-1" aria-hidden="true"
class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="bg-gray-100 border border-black rounded-lg shadow">
            <img class="rounded-t-lg  md:rounded-none md:rounded-s-lg  cursor-pointer" target="_blank"
            src="{{baseUrl}}/file/retrieve/{{selectedPortfolio.photo}}" onerror="this.src='assets/images/image-gallery.png'">
          </div>
        </div>
    </div>
</div>
