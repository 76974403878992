import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Services } from '../core/models/services';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../core/services/seo.service';
import { ApiService } from '../core/services/api.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FabButtonComponent } from '../shared/fab-button/fab-button.component';
import { ServiceRequestComponent } from '../shared/service-request/service-request.component';
import { RouterModule } from '@angular/router';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { FormsModule } from '@angular/forms';

import { environment } from '../../environments/environment';
import { DataService } from '../core/services/data.service';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-all-services',
  standalone: true,
  imports: [CommonModule, AllServicesComponent, SharedModule, ServiceRequestComponent, RouterModule, FilterPipe, FormsModule],
  templateUrl: './all-services.component.html',
  styleUrls: ['./all-services.component.scss']
})
export class AllServicesComponent implements OnInit {

  groupedServices: any = [];
  searchTerms = "";
  newServiceRequest = "";
  servicesData: any = [];
  baseURL = environment.baseURL;

  constructor(private titleService: Title, private seoService: SeoService, private metaService: Meta, private api: ApiService, public dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.titleService.setTitle(this.seoService.getTitle("all-services"));
    this.metaService.addTags(this.seoService.getSEOMetaData("all-services"));
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const messagingElement = document.querySelector('.messaging');
      if (messagingElement) {
        messagingElement.classList.remove('hidden');
      }
    }
    await this.dataService.getServicesByCategory()
    this.groupedServices = this.dataService.servicesByCategory;
  }

  async groupServicesByCategory(): Promise<any[]> {
    const grouped: any = {};
    for (let service of this.servicesData) {
      const category = service.category;
      if (!grouped[category]) {
        grouped[category] = [];
      }
      this.api.getVendorServicesCount(service._id).subscribe((cdata: any) => {
        service.expertCount = cdata.data.totalCount;

      })

      grouped[category].push(service);
    }
    return Object.entries(grouped).map(([key, value]: any) => ({ key, value: value.sort((a: any, b: any) => a.title.localeCompare(b.title)) }));
  }
}
